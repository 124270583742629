import React,{useEffect, useState} from "react";
import services from "../../../services/services";
import AddClient from "../../modals/add-client";
import AddProject from "../../modals/add-project";
import AddTask from "../../modals/add-task";
import $ from 'jquery';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import { DeleteConfirmationPopup } from "../../../confirmation-dialogue/delete-dialogue";
import AddUser from "../../modals/add-user";
import { useTable, useSortBy } from 'react-table';

function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
      <div className="popup">
        <div className="popup-content">
          <p>{message}</p>
          <button onClick={onConfirm} class="btn btn-primary">Yes</button>
          <button onClick={onCancel} class="btn btn-outline-dark">No</button>
        </div>
      </div>
    );
  }

const ProjectDetails = () => {
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
    var loggedUserId = sessionStorage.getItem('userIdval');
    //setuseridval(parseInt(loggedUserId));
    const [loggedUser, setLoggedUser] = useState('');
    const [companyid, setCompanyId] = useState('');

    const [projecInfoDetails, setProjectInfoDetails] = useState([]);
    const pagenum = 1;
    const pagesize = 100;

    const query = new URLSearchParams(useLocation().search);
    const projectIdforDetails = query.get('id');

    const [taskListInfo, setTaskListInfo] = useState([]);
    const [taskHoursListInfo, setTaskHoursListInfo] = useState([]);
    const [taskFullDetails, setTaskFullDetails] = useState([]);
    const [mergedData, setMergedData] = useState([]);
    const [projectTeamMembers, setProjectTeamMembers] = useState([]);

    useEffect(() =>
    {
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);



        if(loggedinUserRole == 'Admin' || loggedinUserRole == 'Manager'){
            var tab = getParameterByName('tab');
            //console.log(projectIdforDetails);
            getProjectDetailsWithId(projectIdforDetails);
            getProjectTeam(projectIdforDetails, 1);
            getAllTasksInfo(1, projectIdforDetails);

        } else {
            window.location.href='/login';
        }
    },[1]);

    const handleAddTimeTrigger = (data) => {
        getProjectDetailsWithId(projectIdforDetails);
            getProjectTeam(projectIdforDetails, 1);
            getAllTasksInfo(1, projectIdforDetails);
        // Perform any action needed on the main page
     };

    function getProjectDetailsWithId(projectid) {
        services.getProjectDetailsFullwithProjectId(projectid).then((res) => {
            console.log(res.data);
            if(res.data.type == 'success'){
                setProjectInfoDetails(res.data.data);
            }
        });
    }

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
    }

    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const handleToggleChange = (event) => {
        if(event.target.checked){
            setDisplayStatus(0);
            getAllTasksInfo(0, projectIdforDetails);
            getProjectTeam(projectIdforDetails, 0);
        } else {
            setDisplayStatus(1);
            getAllTasksInfo(1, projectIdforDetails);
            getProjectTeam(projectIdforDetails, 1);
        }
    }

    const [displayStatus, setDisplayStatus] = useState(1);
    const [deailTab, setDetailTab] = useState('task');

    const setDetailTabState = (tabid) => {
        if(tabid == 'task'){
            setDetailTab('task');
            getAllTasksInfo(displayStatus, projectIdforDetails);
        }
        if(tabid == 'team'){
            setDetailTab('team');
            getProjectTeam(projectIdforDetails, displayStatus);
        }
    }

    const getAllTasksInfo = (taskstatus, projectfilterval) => {

        var taskstatusses = [];
        if(taskstatus == 1){
            taskstatusses.push("New");
            taskstatusses.push("In Progress");
            taskstatusses.push("Completed");
            taskstatusses.push("Re-opened");
        }

        if(taskstatus == 0){
            taskstatusses.push("Disabled");
            taskstatusses.push("Deleted");
        }
        const requestObject =
        {
            "status": taskstatusses,
            "billable": '',
            "projectId": [projectfilterval],
            "search": '',
            "pageNumber": pagenum,
            "pageSize": pagesize
        }
        console.log(requestObject); //return false;
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     //'Authorization': accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getAllTasksInfo(requestObject).then((res) => {
            //console.log(res.data.length);

            if(res.type == 'success'){
                var team_ids = [];
                var teamInfo = res.data;
                for (var i = 0; i < teamInfo.length; i++) {
                    team_ids.push(teamInfo[i].id);
                }
                //console.log(team_ids);
                setTaskListInfo(res.data);

                const requestObject = {
                    "userId": "",
                    "projectId": "",
                    "taskStatus": [],
                    "taskName": "",
                    "taskId": team_ids,
                    "pageNumber": pagenum,
                    "pageSize": pagesize
                  };
                  console.log(requestObject); //return false;
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //     method: 'POST',
                //     headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //     },
                //     body: JSON.stringify(requestObject)
                // };
                services.getTaskHours(requestObject).then((res) => {
                    console.log(res.data);
                    if(res.type == 'success'){

                       setTaskHoursListInfo(res.data);
                       var taskhours = res.data

                       // Merge the data
                        var combinedData = [];
                        for (var i = 0; i < teamInfo.length; i++) {
                            for (var j = 0; j < taskhours.length; j++) {
                                if(teamInfo[i].id == taskhours[j].taskId){

                                    combinedData.push({
                                        "id": teamInfo[i].id,
                                        "name": teamInfo[i].name,
                                        "project_id": teamInfo[i].project_id,
                                        "sprint": teamInfo[i].sprint,
                                        "description": teamInfo[i].description,
                                        "duration": teamInfo[i].duration,
                                        "is_billable": teamInfo[i].is_billable,
                                        "task_status": teamInfo[i].task_status,
                                        "task_started_time": teamInfo[i].task_started_time,
                                        "task_ended_time": teamInfo[i].task_ended_time,
                                        "created_time": teamInfo[i].created_time,
                                        "updated_time": teamInfo[i].updated_time,
                                        "project": teamInfo[i].project,

                                        "estimatedHours": taskhours[j].estimatedHours,
                                        "burnedHours": taskhours[j].burnedHours,
                                        "remainingHours": taskhours[j].remainingHours,
                                        "users": taskhours[j].users
                                    });
                                }
                            }
                        }
                        console.log(combinedData);
                        setMergedData(combinedData);

                    }
                });
            }
        });
    }

    const getFirstUserforTask = (taskid) => {
        for (var i = 0; i < mergedData.length; i++) {
            if(mergedData[i].id == taskid){
                var users = mergedData[i].users;
                if(users.length > 0){
                    return users[0].userFirstName+' '+users[0].userLastName;
                } else {
                    return '';
                }
            }
        }
    }

    const getProjectTeam = (projectid, teamstatus) => {
        services.getProjectTeamDetailsFullwithProjectId(projectid, teamstatus).then((res) => {
            console.log(res.data);
            if(res.data.type == 'success'){
                //setDetailTab('team');
                setProjectTeamMembers(res.data.data);
            }
        });
    }

    const [formMode, setFormMode] = useState(null);
    const [projectidfortask, setprojectidfortask] = useState(null);
    const [selectedTaskIdForEdit, setSelectedTaskIdForEdit] = useState(null);
    const [taskIdforUpdate, setTaskIdforUpdate] = useState(null);
    const [taskStatustoUpdate, setTaskStatustoUpdate] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const editTaskWithId = (taskid) => {
        setFormMode('edit');
        setprojectidfortask(null);
        setSelectedTaskIdForEdit(taskid);
    }

    const changeTaskStatus = (taskid, newStatus) => {
        setTaskIdforUpdate(taskid);
        console.log(newStatus);
        setTaskStatustoUpdate(newStatus);
        setShowPopup(true);
    }

    const handleUpdateTask = () => {
        var requestObject = {};
        for(var p=0; p< taskListInfo.length; p++){
            if(taskListInfo[p].id == taskIdforUpdate){
                var requestObject = {
                   "name": taskListInfo[p].name,
                    "project_id":taskListInfo[p].project_id,
                    "sprint": taskListInfo[p].sprint,
                    "description": taskListInfo[p].description,
                    "duration": taskListInfo[p].duration,
                    "estimated_hours": taskListInfo[p].estimated_hours,
                    "is_billable": taskListInfo[p].is_billable,
                    "task_status": taskStatustoUpdate,
                    "task_started_time": taskListInfo[p].task_started_time,
                    "task_ended_time": taskListInfo[p].task_ended_time
                }
            }
        }
        setShowPopup(false);
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        $(".load-wrap").show(); //console.log(requestObject); return false;
        services.updateTaskInfoById(requestObject, taskIdforUpdate).then((res) => {
            //setIsOpen(false);
            $(".load-wrap").show();
            $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#updatesuccessMessage").slideUp(500);
            });
            setTimeout(function() { getAllTasksInfo(1, projectIdforDetails); }, 1000);
        });
    }


    const getProjectTaskDetails = (taskid, projectid) => {
//console.log(taskid+'-'+projectid);
        window.location.href = '/taskDetail?tid='+taskid+'&pid='+projectid+'&tab=project';
    }

    const [userIdforUpdate, setUserIdforUpdate] = useState(null);
    const [userStatustoUpdate, setUserStatustoUpdate] = useState(null);

    const changeUserStatus = (userid, newStatus) => {
        setUserIdforUpdate(userid);
        setUserStatustoUpdate(newStatus);
        setShowPopup(true);
    }

    const handleCancel = () => {
        setShowPopup(false);
     }

     const handleUpdateTeam = () => {
        $('.loadwrapper').show();
            services.getUserById(userIdforUpdate).then((res) =>{
                if(res.data.type === 'success'){
                    var userResponse = res.data.data;

                    const requestObject =
                    {
                        "company_id": userResponse.company_id,
                        "first_name": userResponse.first_name,
                        "last_name": userResponse.last_name,
                        "email": userResponse.email,
                        "role_id": userResponse.role_id,
                        "designation_id": userResponse.designation_id,
                        "employee_id": userResponse.employee_id,
                        "status": userStatustoUpdate
                    }
                    var accessTokenIn=sessionStorage.getItem("accToken");
                    // const requestOptions = {
                    // method: 'PUT',
                    // headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer "+accessTokenIn
                    // },
                    // body: JSON.stringify(requestObject)
                    // };

                    $(".load-wrap").show();
                    services.updateUserInformation(requestObject, userIdforUpdate).then((res) => {
                        console.log(res); //return false;
                        if(res.type=='success'){
                            $('.loadwrapper').hide();
                            getProjectTeam(projectIdforDetails, userStatustoUpdate);
                        }
                    });
                }
            });
            setShowPopup(false);

     }

     const [selectedUserIdForEdit, setSelectedUserIdForEdit] = useState(null);
     const editUser = (useridtoedit) => {
        setSelectedUserIdForEdit(useridtoedit);
    }

    const capitalizeFirstLetter = (status) => {
        if (!status) return '';
        return status.charAt(0).toUpperCase() + status.slice(1);
      };

  return(
  <>
             <main>


<section>
<div class="container">
        <h2 class="mainHeadline">
            Project Management
        </h2>

        <div class="tab-primary">

            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link bg-none" href="/project-management?tab=client">Clients</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active bg-none" href="/project-management?tab=project">Projects </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link bg-none" href="/project-management?tab=task">Tasks </a>
                </li>

            </ul>

            <div class="tab-content ">
                <div id="two" class="container px-0 fade show" >
                    <div class="tab-content-bg-primary">
                        <div class="d-flex justify-content-between">
                            <div class="billnonbill d-flex gap-4 align-items-center me-5">
                                {(!isEmpty(projecInfoDetails.projectBudgetTime) && parseInt(projecInfoDetails.projectBudgetTime) > 0) && (
                                <><div class="item d-grid">
                                    <small> Budget Hours</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.projectBudgetTime}</strong>
                                </div>
                                <span>|</span> </>)}
                                <div class="item d-grid">
                                    <small> Total Hours Spent</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.totalHours}</strong>
                                </div>
                                <span>|</span>
                                <div class="item d-grid">
                                    <small class="d-flex gap-1 align-items-center"> <img src="images/billed.png" width="20" alt=""/> Billable</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.billableHours}</strong>
                                </div>
                                <span>|</span>
                                <div class="item d-grid">
                                    <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg" width="20" alt=""/>
                                    Non-Billable</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.nonBillableHours}</strong>
                                </div>
                            </div>

                            <div class="billnonbill d-flex gap-4 align-items-center ">
                                <div class="item d-grid">
                                    <small> Total Tasks</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.totalTasks}</strong>
                                </div>
                                <span>|</span>
                                <div class="item d-grid">
                                    <small class="d-flex gap-1 align-items-center">  In Progress</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.inProgressTasks}</strong>
                                </div>
                                <span>|</span>
                                <div class="item d-grid">
                                    <small class="d-flex gap-1 align-items-center">
                                        Completed</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.completedTasks}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center titlewrap p-3 mb-3">
                        <div class="d-flex gap-2">
                            <div class="d-grid">
                                <h2>{projecInfoDetails.projectName}</h2>
                                <a href="/project-management?tab=project" class="bluetext"><img src="images/returnArrow.png" style={{"margin-right": "4px", "transform": "rotate(180deg)"}} alt=""/>Back to Project List
                                </a>
                            </div>
                        </div>


                        <div class="d-flex justify-content-end align-items-center ">
                            <span>Status:</span>
                            <div class="d-flex align-items-center gap-2 py-2 ps-2 form-switch">
                                <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Active</small></b></label>
                                <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleToggleChange}/>
                                <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Inactive</small></b></label>
                            </div>
                        </div>
                    </div>

                    <ul class="nav nav-tabs projintab" role="tablist">
                        <li class={`nav-item ${deailTab == 'task' ? 'active' : ''}`}>
                            <a href="#task" onClick={() => setDetailTabState('task')} role="tab" data-bs-toggle="tab" class="nav-link"> Tasks </a>
                        </li>
                        <li class={`nav-item ${deailTab == 'team' ? 'active' : ''}`}>
                            <a href="#team" onClick={() => setDetailTabState('team')} role="tab" data-bs-toggle="tab" class="nav-link"> Team </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class={`tab-pane mt-1 ${deailTab == 'task' ? 'active' : ''}`} role="tabpanel" id="task">

                            <div class="tbl-container bdr">
                                <table class="table table-condensed">
                                    <thead class="bg-lightblue">
                                        <tr>
                                            <th style={{"width":"215px"}}>Task Name</th>
                                            <th style={{"width":"175px"}}>Assigned to</th>
                                            <th class="text-center" style={{"width":"103px"}}>Sprint Details</th>
                                            <th class="text-center" style={{"width":"103px"}}>Estimated Hrs</th>
                                            <th class="text-center" style={{"width":"103px"}}>Burned Hrs</th>
                                            <th class="text-center" style={{"width":"103px"}}>Remaining Hrs</th>
                                            <th class="text-center" style={{"width":"75px"}}>Status</th>
                                            <th style={{"width":"75px"}}>Action</th>
                                        </tr>
                                    </thead>
                                    {mergedData.length > 0 ?
                                    <tbody>
                                        {mergedData.map((taskdata, taskindex) => (
                                        <>
                                        <tr data-bs-toggle="collapse" data-bs-target={`#demo`+taskindex} class="accordion-toggle">

                                            <td style={{"width": "215px"}}>
                                                <div class="projecttask">
                                                    <span><a href="#" onClick={() => getProjectTaskDetails(taskdata.id, taskdata.project_id)}>{taskdata.name}</a></span>
                                                    <span class="badge rounded-pill project-badge text-dark my-2">{taskdata.project.name}</span>

                                                </div>
                                            </td>
                                            <td style={{"width":"175px"}}>
                                                <div class="dualtask d-flex align-items-center"><span class="name">{getFirstUserforTask(taskdata.id)}</span> <span class="count">{parseInt((taskdata.users.length) - 1) > 0 ? '+'+parseInt((taskdata.users.length) - 1) : ' '}</span></div>
                                            </td>
                                            <td class="text-center" style={{"width":"103px"}}>{taskdata.sprint}</td>
                                            <td class="text-center" style={{"width":"103px"}}>{parseInt(taskdata.estimatedHours) > 0 ? (taskdata.estimatedHours).slice(0, -3) : '...'}</td>
                                            <td class="text-center" style={{"width":"103px"}}>
                                            {(parseInt(taskdata.estimatedHours) > 0 && parseInt(taskdata.burnedHours) > parseInt(taskdata.estimatedHours)) ? (
                                                <>
                                                    {parseInt(taskdata.estimatedHours)}
                                                    <span className="extrahr">
                                                        +{parseInt(taskdata.burnedHours) - parseInt(taskdata.estimatedHours)}
                                                    </span>
                                                </>
                                            ) : (
                                                parseInt(taskdata.burnedHours) > 0 ? (taskdata.burnedHours).slice(0, -3) : '...'
                                            )}
                                               {/* {parseInt(taskdata.burnedHours) > 0 ? (taskdata.burnedHours).slice(0, -3) : '...'} */}
                                                </td>
                                            <td class="text-center" style={{"width":"103px"}}>{parseInt(taskdata.remainingHours) > 0 ? (taskdata.remainingHours).slice(0, -3) : '...'}</td>
                                            <td class="text-center" style={{"width":"75px"}}>
                                                <div class="d-grid">
                                                    <img src="images/in-progress.svg" class="m-auto" alt=""/>
                                                    <small>{taskdata.task_status}</small>

                                                </div>
                                            </td>
                                            <td style={{"width": "75px", "text-align": "center"}}>
                                                <div class="dropdown table-action-dd">

                                                    <button class="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="images/dots.svg" alt=""/>
                                                    </button>

                                                    <ul class="dropdown-menu">

                                                        <li>
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(taskdata.id)}> <img src="images/edit.png" alt=""/> Edit</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Deleted')}><img src="images/delete.svg" alt=""/> Delete</a>
                                                        </li>
                                                        {taskdata.task_status == 'Disabled' ?
                                                        <li>
                                                        <a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'New')}><img src="images/delete.svg" alt=""/> Enable </a>
                                                    </li>
                                                        :

                                                        <li>
                                                            <a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Disabled')}><img src="images/delete.svg" alt=""/> Disable </a>
                                                        </li>
}
{taskdata.task_status == 'Completed' ?
    <li>
                                                            <a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Re-opened')}><img src="images/reopen.svg" alt=""/> Re-Opene</a>
                                                        </li>
                                                        :
                                                        <li>
                                                            <a class="dropdown-item" href="#" onClick={() => changeTaskStatus(taskdata.id, 'Completed')}><img src="images/greentick.svg" alt=""/> Complete</a>
                                                        </li>
}
                                                    </ul>
                                                </div>

                                            </td>
                                        </tr>
                                        {parseInt(taskdata.users.length) > 1 ?
                                        <tr>
                                            <td colspan="8" class="hiddenRow">
                                                <div class="accordian-body collapse" id={`demo`+taskindex}>
                                                    <table class="table coltableinn" style={{"background-color": "#e9e9e9"}}>
                                                        <thead>

                                                        </thead>

                                                        <tbody>
                                                        {taskdata.users.map((taskuserdata, taskuserindex) => (
                                                            <tr data-bs-toggle="collapse" class="accordion-toggle" data-bs-target="#demo10">
                                                                <td style={{"width":"215px"}}></td>
                                                                <td class="text-start" style={{"width":"175px"}}>{taskuserdata.userFirstName+' '+taskuserdata.userLastName+' '}{(taskuserdata.userRoleId === 2) && (<img src="images/manager-icon.svg" alt="" width={22} style={{"marginRight":"4px"}}/>)}
                                                                </td>
                                                                <td class="text-center" style={{"width":"103px"}}></td>
                                                                <td class="text-center" style={{"width":"103px"}}>...</td>
                                                                <td class="text-center" style={{"width":"103px"}}>{(taskuserdata.userBurnedHours).slice(0, -3)}</td>
                                                                <td class="text-center" style={{"width":"103px"}}>...</td>
                                                                <td style={{"width":"75px"}}>{capitalizeFirstLetter(taskuserdata.taskUserStatus)}</td>
                                                                <td style={{"width":"75px"}}></td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </td>
                                        </tr>
                                        : ''}
                                        </>
                                         ))}
                                    </tbody>
                                    :
                                    <tbody>No Tasks Available</tbody>
                                    }
                                </table>
                            </div>
                            {showPopup && (
                        <ConfirmationPopup
                        message="Are you sure you want to Update this Task?"
                        onConfirm={handleUpdateTask}
                        onCancel={handleCancel}
                        />
                    )}
                        </div>
                        <div class={`tab-pane mt-1 ${deailTab == 'team' ? 'active' : ''}`} role="tabpanel" id="team">
                            <div class="tbl-container bdr">
                                <table class="table table-condensed">
                                    <thead class="bg-lightblue">
                                        <tr>
                                            <th style={{"width":"235px"}}>User Name</th>

                                            <th class="text-center" style={{"width":"103px"}}>Tasks</th>
                                            <th class="text-center" style={{"width":"103px"}}>In Progress</th>
                                            <th class="text-center" style={{"width":"73px"}}>Completed</th>
                                            <th class="text-center" style={{"width":"103px"}}>Spent Hrs</th>
                                            <th class="text-center" style={{"width":"75px"}}>Billable Hrs</th>
                                            <th class="text-center" style={{"width":"123px"}}>Non-Billable Hrs</th>
                                            <th class="text-center" style={{"width":"103px"}}>Status</th>
                                            <th style={{"width":"75px"}}>Actions</th>
                                        </tr>
                                    </thead>
                                    {projectTeamMembers.length > 0?
                                    <tbody>
                                        {projectTeamMembers.map((projectTeamData, projectTeamIndex) => (
                                            <tr data-bs-toggle="collapse" data-bs-target="#demo3" class="accordion-toggle" aria-expanded="false" aria-controls="collapseTable">
                                            <td style={{"width":"235px"}}>
                                                <div class="d-flex"><span>{projectTeamData.user.first_name+' '+projectTeamData.user.last_name+' '}{(projectTeamData.user.role_id === 2) && (<img src="images/manager-icon.svg" alt="" width={22} style={{"marginRight":"4px"}}/>)}</span></div>
                                            </td>
                                            <td class="text-center" style={{"width":"103px"}}>{projectTeamData.totalTasks}</td>
                                            <td class="text-center" style={{"width":"103px"}}>{projectTeamData.inProgressTasks}</td>
                                            <td class="text-center" style={{"width":"103px"}}>{projectTeamData.completedTasks}</td>
                                            <td class="text-center" style={{"width":"103px"}}>{projectTeamData.totalHours}</td>
                                            <td class="text-center" style={{"width":"103px"}}>{projectTeamData.billableHours}</td>
                                            <td class="text-center" style={{"width":"103px"}}>
                                            {projectTeamData.nonBillableHours}
                                            </td>
                                            <td class="text-center" style={{"width":"103px"}}>{capitalizeFirstLetter(projectTeamData.projectAssignedStatus)}</td>
                                            <td style={{"width": "75px", "text-align": "center"}}>
                                                <div class="dropdown table-action-dd">

                                                    <button class="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="images/dots.svg" alt=""/>
                                                    </button>

                                                    <ul class="dropdown-menu">
{/*
                                                        <li>
                                                            <a class="dropdown-item" onClick={() => editUser(projectTeamData.user.id)} data-bs-toggle="modal" data-bs-target="#createuser"> <img src="images/edit.png" alt=""/> Edit</a>
                                                        </li> */}
                                                        {/* <li>
                                                            <a class="dropdown-item" href="#"><img src="images/delete.svg" alt=""/> Delete</a>
                                                        </li> */}

                                                        <li>
                                                            {projectTeamData.user.status === 1 ?
                                                            <a class="dropdown-item" onClick={() => changeUserStatus(projectTeamData.user.id, 0)}><img src="images/delete.svg" alt=""/> Deactivate </a>
                                                            :
                                                            <a class="dropdown-item" onClick={() => changeUserStatus(projectTeamData.user.id, 1)}><img src="images/greencheck.svg" alt=""/> Activate </a>
                                                            }
                                                        </li>


                                                    </ul>
                                                </div>

                                            </td>
                                        </tr>
                                        ))}

                                    </tbody>
                                    :
                                    <tbody><tr rowspan="7">No Team Data Avaialable</tr></tbody>
}
                                </table>
                            </div>
                            {showPopup && (
                        <ConfirmationPopup
                        message="Are you sure you want to Update this Team Member?"
                        onConfirm={handleUpdateTeam}
                        onCancel={handleCancel}
                        />
                    )}
                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>

    <AddTask formMode={formMode} taskProject={projectidfortask} editTask={selectedTaskIdForEdit} />
    <AddUser onTrigger={handleAddTimeTrigger} editUser={selectedUserIdForEdit}/>
</section>

</main>

  </>
)};

ProjectDetails.propTypes = {};

ProjectDetails.defaultProps = {};

export default ProjectDetails;
