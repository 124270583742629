import React, { useEffect, useState } from "react";
import services from "../../../services/services";
import AddClient from "../../modals/add-client";
import AddProject from "../../modals/add-project";
import AddTask from "../../modals/add-task";
import $ from 'jquery';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import DateObject from "react-date-object";
import { useForm } from "react-hook-form";


function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
        <div className="popup">
            <div className="popup-content">
                <p>{message}</p>
                <button onClick={onConfirm} class="btn btn-primary">Yes</button>
                <button onClick={onCancel} class="btn btn-outline-dark">No</button>
            </div>
        </div>
    );
}

function capitalizeFirstLetter(sentence) {
    if (typeof sentence !== 'string') return '';
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

const TaskDetails = () => {
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
    var loggedUserId = sessionStorage.getItem('userIdval');
    //setuseridval(parseInt(loggedUserId));
    const [loggedUser, setLoggedUser] = useState('');
    const [companyid, setCompanyId] = useState('');

    const [projecInfoDetails, setProjectInfoDetails] = useState([]);
    const pagenum = 1;
    const pagesize = 100;

    const query = new URLSearchParams(useLocation().search);
    const projectIdforDetails = query.get('id');

    const [taskListInfo, setTaskListInfo] = useState([]);
    const [taskHoursListInfo, setTaskHoursListInfo] = useState([]);
    const [taskFullDetails, setTaskFullDetails] = useState([]);
    const [mergedData, setMergedData] = useState([]);
    const [projectTeamMembers, setProjectTeamMembers] = useState([]);

    const [showTaskInfo, setShowTaskInfo] = useState('');
    var detailstaskid = '';
    var detailsprojectid = '';
    var tab = '';
    const [selectedEntryResult, setSelectedEntryResult] = useState([]);
    const [selectedEntryCommentsResult, setSelectedEntryCommentsResult] = useState([]);
    const [cameFromProject, setCameFromProject] = useState('');
    const [cameFromTask, setCameFromTask] = useState('');

    const [showLoader, setShowLoader] = useState(0);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    useEffect(() => {
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);

        if (loggedinUserRole == 'Admin' || loggedinUserRole == 'Manager' || loggedinUserRole == 'Employee' || loggedinUserRole == 'User') {
            detailstaskid = getParameterByName('tid');
            detailsprojectid = getParameterByName('pid');
            tab = getParameterByName('tab');
            if (tab == 'project') {
                setCameFromProject('active');
                setCameFromTask('');
            } else {
                setCameFromProject('');
                setCameFromTask('active');
            }
            //getProjectDetailsWithId(detailsprojectid);
            getTaskInfo(detailstaskid);
            //getProjectTeam(detailsprojectid, 1);
            //getAllTasksInfo(1, detailsprojectid);

        } else {
            window.location.href = '/login';
        }
    }, [1]);

    function getProjectDetailsWithId(projectid) {
        services.getProjectDetailsFullwithProjectId(projectid).then((res) => {
            //console.log(res.data);
            if (res.data.type == 'success') {
                setProjectInfoDetails(res.data.data);
            }
        });
    }

    function getTaskInfo(taskid) {
        //console.log(taskid);
        setShowLoader(1);
        services.getTaskById(taskid).then((res) => {
            //console.log(res.data);
            if (res) {
                if (res.data.data != null) {
                    console.log(res.data.data);
                    setShowTaskInfo(res.data.data);
                    var myloggedinUserRole = sessionStorage.getItem('userRole');
                    if (myloggedinUserRole == 'Admin' || myloggedinUserRole == 'Manager') {
                        getMyTimesheetEntriesWithTaskId('', detailsprojectid, taskid, '', '', '', '', '', '', '');
                        getTaskCommentsByTaskId(getParameterByName('tid'), '');
                    } else {
                        getMyTimesheetEntriesWithTaskId('', detailsprojectid, taskid, '', '', '', useridval, '', '', '');
                        getTaskCommentsByTaskId(taskid, '');
                    }
                }
                setShowLoader(0);
            }
        });
    }

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
    }

    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    const [displayStatus, setDisplayStatus] = useState(1);
    const [deailTab, setDetailTab] = useState('task');

    const setDetailTabState = (tabid) => {
        if (tabid == 'task') {
            getAllTasksInfo(displayStatus, projectIdforDetails);
        }
        if (tabid == 'team') {
            getProjectTeam(projectIdforDetails, displayStatus);
        }
    }

    const getAllTasksInfo = (taskstatus, projectfilterval) => {

        var taskstatusses = [];
        if (taskstatus == 1) {
            taskstatusses.push("New");
            taskstatusses.push("In Progress");
            taskstatusses.push("Completed");
            taskstatusses.push("Re-opened");
        }

        if (taskstatus == 0) {
            taskstatusses.push("Disabled");
            taskstatusses.push("Deleted");
        }
        const requestObject =
        {
            "status": taskstatusses,
            "billable": '',
            "projectId": [projectfilterval],
            "search": '',
            "pageNumber": pagenum,
            "pageSize": pagesize
        }
        //console.log(requestObject); //return false;
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer " + accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getAllTasksInfo(requestObject).then((res) => {
            //console.log(res.data.length);

            if (res.type == 'success') {
                var team_ids = [];
                var teamInfo = res.data;
                for (var i = 0; i < teamInfo.length; i++) {
                    team_ids.push(teamInfo[i].id);
                }
                //console.log(team_ids);
                setTaskListInfo(res.data);

                const requestObject = {
                    "userId": "",
                    "projectId": "",
                    "taskStatus": [],
                    "taskName": "",
                    "taskId": team_ids,
                    "pageNumber": pagenum,
                    "pageSize": pagesize
                };
                //console.log(requestObject); //return false;
                var accessTokenIn = sessionStorage.getItem("accToken");
                // const requestOptions = {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': "Bearer " + accessTokenIn
                //     },
                //     body: JSON.stringify(requestObject)
                // };
                services.getTaskHours(requestObject).then((res) => {
                    //console.log(res.data);
                    if (res.type == 'success') {

                        setTaskHoursListInfo(res.data);
                        var taskhours = res.data

                        // Merge the data
                        var combinedData = [];
                        for (var i = 0; i < teamInfo.length; i++) {
                            for (var j = 0; j < taskhours.length; j++) {
                                if (teamInfo[i].id == taskhours[j].taskId) {

                                    combinedData.push({
                                        "id": teamInfo[i].id,
                                        "name": teamInfo[i].name,
                                        "project_id": teamInfo[i].project_id,
                                        "sprint": teamInfo[i].sprint,
                                        "description": teamInfo[i].description,
                                        "duration": teamInfo[i].duration,
                                        "is_billable": teamInfo[i].is_billable,
                                        "task_status": teamInfo[i].task_status,
                                        "task_started_time": teamInfo[i].task_started_time,
                                        "task_ended_time": teamInfo[i].task_ended_time,
                                        "created_time": teamInfo[i].created_time,
                                        "updated_time": teamInfo[i].updated_time,
                                        "project": teamInfo[i].project,

                                        "estimatedHours": taskhours[j].estimatedHours,
                                        "burnedHours": taskhours[j].burnedHours,
                                        "remainingHours": taskhours[j].remainingHours,
                                        "users": taskhours[j].users
                                    });
                                }
                            }
                        }
                        //console.log(combinedData);
                        setMergedData(combinedData);
                        setDetailTab('task');
                    }
                });
            }
        });
    }

    const getFirstUserforTask = (taskid) => {
        for (var i = 0; i < mergedData.length; i++) {
            if (mergedData[i].id == taskid) {
                var users = mergedData[i].users;
                if (users.length > 0) {
                    return users[0].userFirstName + ' ' + users[0].userLastName;
                } else {
                    return '';
                }
            }
        }
    }

    const getProjectTeam = (projectid, teamstatus) => {
        services.getProjectTeamDetailsFullwithProjectId(projectid, teamstatus).then((res) => {
            //console.log(res.data);
            if (res.data.type == 'success') {
                setDetailTab('team');
                setProjectTeamMembers(res.data.data);
            }
        });
    }

    function getMyTimesheetEntriesWithTaskId(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval) {
        services.getAllTimeSheetEntries(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval).then((res) => {
            if (res.data.type == 'success') {
                setSelectedEntryResult(res.data.data);
            }
        });
    }

    function getTaskCommentsByTaskId(taskId, userid) {
        services.getTaskCommentsByTaskId(taskId, userid).then((res) => {
            //console.log(res.data);
            if (res.data.type == 'success') {
                setSelectedEntryCommentsResult(res.data.data);
            }
        });
    }

    const handleWeekDatesDisplayFormat = (datetoformat) => {
        var displaydate = new DateObject(datetoformat);
        return displaydate.format("DD-MMM-YYYY");
    };

    const handleCommentDatesDisplayFormat = (commenteddate) => {
        var displaycommentdate = new DateObject(commenteddate);
        return displaycommentdate.format("DD, MMM YYYY");
    }

    const handleCommentTimesDisplayFormat = (commenteddate) => {
        var displaycommentdate = new DateObject(commenteddate);
        return displaycommentdate.format("HH:MM");
    }

    const submitValue = () => {
        setShowLoader(1);
        var commentdata = $('#capturecomment').val();
        var taskidonaction = getParameterByName('tid');
        const requestObject = {
            "task_id": taskidonaction,
            "commented_by_user_id": useridval,
            "comment": commentdata
        };
        //console.log(requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer " + accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.createTaskComment(requestObject).then((res) => {
            $("#entryform").trigger("reset");
            $(".load-wrap").hide();
            setTextValue('');
            setTimeout(function () {
                //console.log(taskidonaction);
                getTaskInfo(taskidonaction);
            }, 1000);
            setShowLoader(0);
        });
    }

    const [textvalue, setTextValue] = useState('');
    const maxLength = 500; // Set your character limit here

    const handleChangeTextarea = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= maxLength) {
            setTextValue(newValue);
        }
    };

    const taskIcon = (taskStatusVal) => {
        return taskStatusVal.toLowerCase().replace(/\s+/g, '-');
    }



    return (
        <>
            <main>


                <section>
                    <div class="container">
                        {(loggedUser == 'Admin' || loggedUser == 'Manager') ?
                            <h2 class="mainHeadline">
                                Project Management
                            </h2>
                            :
                            <h2 class="mainHeadline">
                                Projects
                            </h2>
                        }

                        <div class="tab-primary">
                            {(loggedUser == 'Admin' || loggedUser == 'Manager') ?
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link bg-none" href="/project-management?tab=client">Clients</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={`nav-link ${cameFromProject} bg-none`} href="/project-management?tab=project">Projects </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={`nav-link ${cameFromTask} bg-none`} href="/project-management?tab=task">Tasks </a>
                                    </li>

                                </ul>
                                :
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link bg-none " data-bs-toggle="tab" href="/projects?tab=project">Projects</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link active bg-none" data-bs-toggle="tab" href="/projects?tab=task" >Tasks </a>
                                    </li>
                                </ul>
                            }



                            <div class="tab-content ">
                                <div id="two" class="container px-0 fade show" >
                                    {/*<div class="tab-content-bg-primary">
                        <div class="d-flex justify-content-between">
                            <div class="billnonbill d-flex gap-4 align-items-center me-5">
                                <div class="item d-grid">
                                    <small> Total hour</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.totalHours}</strong>
                                </div>
                                <span>|</span>
                                <div class="item d-grid">
                                    <small class="d-flex gap-1 align-items-center"> <img src="images/dollargreen.svg" width="12" alt=""/> Billable</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.billableHours}</strong>
                                </div>
                                <span>|</span>
                                <div class="item d-grid">
                                    <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg" width="20" alt=""/>
                                    Non-billable</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.nonBillableHours}</strong>
                                </div>
                            </div>

                            <div class="billnonbill d-flex gap-4 align-items-center ">
                                <div class="item d-grid">
                                    <small> Total Tasks</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.totalTasks}</strong>
                                </div>
                                <span>|</span>
                                <div class="item d-grid">
                                    <small class="d-flex gap-1 align-items-center">  Inprogress</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.inProgressTasks}</strong>
                                </div>
                                <span>|</span>
                                <div class="item d-grid">
                                    <small class="d-flex gap-1 align-items-center">
                                        Completed</small>
                                    <strong class="mainbluetxt ">{projecInfoDetails.completedTasks}</strong>
                                </div>
                            </div>
                        </div>
                    </div> */}
                                    <div class="d-flex justify-content-between align-items-start titlewrap p-3 mb-3">
                                        <div class="d-flex gap-2" style={{maxWidth:"80%"}}>
                                            {showTaskInfo.is_billable == 1 ?
                                                <img src="images/billed.png" width="24" height="24" alt="" />
                                                :
                                                // <img src="images/nonbill.png" width="24" height="24" alt="" />
                                                <img src="images/dollarred.svg" width="24" height="24" alt="" />
                                            }

                                            {(loggedUser == 'Admin' || loggedUser == 'Manager') ?
                                                <div class="d-grid">
                                                    <h2>{(showTaskInfo.name)}</h2>

                                                    <span class="badge rounded-pill project-badge text-dark my-2">{showTaskInfo.projectName}</span>

                                                    {(getParameterByName('tab') === 'task') ?
                                                        <a href="/project-management?tab=task" class="bluetext"><img src="images/returnArrow.png" style={{ "margin-right": "4px", "transform": "rotate(180deg)" }} alt="" />Back to Task List</a>
                                                        :
                                                        <a href={`/projectDetail?id=${getParameterByName('pid')}`} class="bluetext"><img src="images/returnArrow.png" style={{ "margin-right": "4px", "transform": "rotate(180deg)" }} alt="" />Back Project</a>
                                                    }
                                                </div>
                                                :
                                                <div class="d-grid">
                                                    <h2>{showTaskInfo.name} </h2>
                                                    <span class="badge rounded-pill project-badge text-dark my-2">{showTaskInfo.projectName}</span>

                                                    <a href="/projects?tab=task" class="bluetext"><img src="images/returnArrow.png" style={{ "margin-right": "4px", "transform": "rotate(180deg)" }} alt="" />Back to Task List</a>
                                                </div>
                                            }
                                            {!isEmpty(showTaskInfo.sprint) ?
                                            <div class="lastrongelsprint d-flex align-self-start"> {showTaskInfo.sprint}</div>
                                            : '' }
                                        </div>

                                        <div class="d-flex justify-content-end align-items-center">

                                            {showTaskInfo.task_status == 'Completed' ?
                                                <img src="images/completed.svg" class="m-auto me-1" alt="" width="20" />
                                                :
                                                showTaskInfo.task_status == 'Deleted' ?
                                                    <img src="images/delete-red.svg" class="m-auto me-1" alt="" width="20"/>
                                                    :
                                                    showTaskInfo.task_status == 'Re-opened' ?
                                                        <img src="images/reopen.svg" class="m-auto me-1" alt="" width="20"/>
                                                        :
                                                        showTaskInfo.task_status == 'Disabled' ?
                                                            <img src="images/delete.svg" class="m-auto me-1" alt="" width="20"/>
                                                            :
                                                            showTaskInfo.task_status == 'New' ?
                                                                <img src="images/new.svg" class="m-auto me-1" alt="" width="20"/>
                                                                :
                                                                <img src="images/in-progress.svg" class="m-auto me-1" alt="" width="20"/>
                                            }
                                            <small>{showTaskInfo.task_status}</small>

                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center titlewrap mb-3">
                                        <div class="d-flex gap-2">
                                            <div class="d-grid">
                                                <p class="ps-4">{capitalizeFirstLetter(showTaskInfo.description)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedEntryResult.length > 0 ?
                                        <div>
                                            {selectedEntryResult.map((timeEntryData, timeEntryIndex) => (
                                                <>
                                                    {parseInt((timeEntryData.duration).slice(0, -3)) > 0 ?

                                                        <div class="discriptionwrap my-3 px-1 py-2">
                                                            <div class="d-flex justify-content-between mb-2">
                                                                <div class="d-flex gap-2 p-3">
                                                                    <span class="nametag">{(timeEntryData.user.first_name).slice(0, 1) + '' + (timeEntryData.user.last_name).slice(0, 1)}</span>
                                                                    <div class="titlewrap">
                                                                        <h2>{timeEntryData.user.first_name + ' ' + timeEntryData.user.last_name}</h2>
                                                                        <small>{handleWeekDatesDisplayFormat(timeEntryData.day)}</small>
                                                                    </div>
                                                                </div>

                                                                <div class="hours scale85">
                                                                    <span class="hrtime">{(timeEntryData.duration).slice(0, -3)}</span>
                                                                    <span class="hrlabel">HOURS</span>
                                                                </div>
                                                            </div>
                                                            <p class="ps-4">{capitalizeFirstLetter(timeEntryData.time_entry_description)}</p>
                                                        </div>

                                                        : ''}
                                                </>
                                            ))}
                                        </div>
                                        :
                                        <div>
                                        </div>
                                    }
                                    <form id="entryform" onSubmit={handleSubmit(submitValue)}>
                                        <div class="mb-2">
                                            <label for="exampleFormControlTextarea1" class="form-label">Write Comment</label>
                                            <textarea class="form-control commetformboxshadow" id="capturecomment" rows="3" placeholder={`Write your comment in max ${maxLength} characters`} value={textvalue} onChange={handleChangeTextarea}></textarea>
                                            <small className="textAreaMaxLength">(Max 500 Characters)</small>
                                        </div>


                                        <button type="submit" class="btn btn-primary mt-1 mb-5">Submit</button>

                                    </form>


                                    {selectedEntryCommentsResult.length > 0 ?
                                        <div>
                                            {selectedEntryCommentsResult.map((commentsData, commentsIndex) => (
                                                <div class=" border-bottom pb-1 my-3">
                                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                                        <div class="d-flex gap-2">
                                                            <span class="nametag">{(commentsData.user.first_name).slice(0, 1) + '' + (commentsData.user.last_name).slice(0, 1)}</span>
                                                            <div class="titlewrap">
                                                                <h2>{commentsData.user.first_name + ' ' + commentsData.user.last_name}</h2>

                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>{handleCommentDatesDisplayFormat(commentsData.created_time) + ' @ ' + handleCommentTimesDisplayFormat(commentsData.created_time)}</span>
                                                            {/* <div>
                        <span>{handleCommentTimesDisplayFormat(commentsData.created_time)}</span>
                    </div> */}
                                                        </div>
                                                    </div>
                                                    <p class="ps-4">{capitalizeFirstLetter(commentsData.comment)}</p>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div class=" border-bottom pb-1 my-3"> </div>
                                    }
                                </div>

                            </div>
                            {showLoader === 1 ?
                            <div class="loadwrapper">
                                <div class="clock"><div class="circle"></div></div>
                            </div>
                            : '' }
                        </div>

                    </div>

                    {/*<AddTask formMode={formMode} taskProject={projectidfortask} editTask={selectedTaskIdForEdit} /> */}

                </section>

            </main>

        </>
    )
};

TaskDetails.propTypes = {};

TaskDetails.defaultProps = {};

export default TaskDetails;
