import React from 'react';

const Notification = ({ message, onClose }) => {
   return (

      <div class="rejpop-nitification">
         <div class="d-flex justify-content-start align-items-center gap-4">
            <span>{message}</span>

         </div>
         <a href="#" onClick={onClose} class="close">X</a>
      </div>

      //  <div className="notification d-flex justify-content-start align-items-center gap-4">
      //    <span>{message}</span>
      //    <a href="#" className="review">Click here to review</a>
      //    <a href="#" className="close" onClick={onClose}>X</a>
      //  </div>
   );
};

export default Notification;
