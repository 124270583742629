import React, { useEffect, useState } from "react";
import services from "../../../services/services";
import AddUser from "../../modals/add-user";
import { DeleteConfirmationPopup } from "../../../confirmation-dialogue/delete-dialogue";
import $ from 'jquery';
import Pagination from "../../../utility/Pagination/Pagination";
import { useTable, useSortBy } from 'react-table';

const UserManagement = () => {
    const [loggedUser, setLoggedUser] = useState('');
    const [useridval, setUseridval] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        designation: '',
        role: '',
        company: '',
        employeeId: '',
        projects: [],
        reportingManager: [],
    });
    const [formErrors, setFormErrors] = useState({});
    const [userListwithProject, setUserListWithProject] = useState('');
    const [userListwithStatus, setUserListWithStatus] = useState(1);
    const [userListInfo, setUserListInfo] = useState([]);
    const [selectedUserIdForEdit, setSelectedUserIdForEdit] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [projectListInfo, setProjectListInfo] = useState([]);
    const pagenum = 1;
    const pagesize = 5;

    const ITEMS_PER_PAGE = 5; // Per page show the data pagination
    //const Pagesize=5;
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [managerId, setManagerId] = useState('');
    const [myCompanyId, setMyCompanyId] = useState('');

    const [showLoader, setShowLoader] = useState(0);
    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));

    useEffect(() => {

        const loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);

        const loggedUserId = sessionStorage.getItem('userIdval');
        setUseridval(parseInt(loggedUserId));

        console.log(loggedinUserRole.toLowerCase());
        if (!loggedinUserRole && (loggedinUserRole.toLowerCase() !== 'admin' || loggedinUserRole.toLowerCase() !== 'manager')) {
           window.location.href = '/login';
        }

        const loggedUserCompanyId = sessionStorage.getItem('userCompanyId');
        if(loggedinUserRole.toLowerCase() === 'manager'){
            setManagerId(loggedUserId);
            setMyCompanyId(loggedUserCompanyId);
            console.log(loggedinUserRole+' - '+loggedUserId);
            getUsersList(userListwithProject, userListwithStatus, pagenum, ITEMS_PER_PAGE, loggedUserId, loggedUserCompanyId);
            getProjects('', 1, 'asc', pagenum, ITEMS_PER_PAGE, loggedUserId, '', loggedUserCompanyId);
        } else {
            var adminCompanyFilterVal = sessionStorage.getItem("adminCompany");
            getUsersList(userListwithProject, userListwithStatus, pagenum, ITEMS_PER_PAGE, managerId, adminCompanyFilterVal);
            getProjects('', 1, 'asc', pagenum, ITEMS_PER_PAGE, managerId, '', adminCompanyFilterVal);
        }



    }, []);

    const handlePageChange2 = (offset,triggerevent) => {
        //alert(offset);
        getUsersList(userListwithProject, userListwithStatus, offset, ITEMS_PER_PAGE, managerId, myCompanyId);
    };

    const getUsersList = (byproject, bystat, pagenumval, itemsperpageval, managerIdval, myCompanyIdval) => {
        console.log(managerIdval+' - '+myCompanyIdval);
        setShowLoader(1);
        services.getUsersList(byproject, bystat, pagenumval, itemsperpageval, managerIdval, myCompanyIdval).then((res) => {
            //console.log(typeof(res.data.data));
            console.log(res.data);
            //console.log(JSON.stringify(res.data.data));
            if(res.data.type === 'success'){

                setUserListInfo(res.data.data);

                if(!isEmpty(res.data.totalSize) && res.data.totalSize > 0){
                    setTotalItems(res.data.totalSize);
                    settotalPages(Math.ceil(res.data.totalSize / ITEMS_PER_PAGE));
                    setCurrentPage(pagenumval);
                } else {
                    setTotalItems(0);
                    settotalPages(0);
                    setCurrentPage(1);
                }

                setShowLoader(0);
            }
        });
    }

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
    }

    const getProjects = (cientlistid, projectstatusval, sortingorder,pagenumval, perpageitems, manageridval, clientStat, companyFilter) => {

        services.getProjectsDetailsList(cientlistid, projectstatusval, sortingorder, pagenumval, '', manageridval, clientStat, companyFilter).then((res) => {
            if(res.data.type === 'success'){
                setProjectListInfo(res.data.data);
            }
        });
    }

    const handleUserStatus = (event) => {
        if(event.target.checked){
            setUserListWithStatus(0);
            getUsersList(userListwithProject, 0, 1, ITEMS_PER_PAGE, managerId, myCompanyId);
        } else {
            setUserListWithStatus(1);
            getUsersList(userListwithProject, 1, 1, ITEMS_PER_PAGE, managerId, myCompanyId);
        }
    }

    const setProjectFilter = (event) => {
        var projectid = event.target.value;
        setUserListWithProject(event.target.value);
        getUsersList(projectid, userListwithStatus, 1, ITEMS_PER_PAGE, managerId, myCompanyId);
    }

    /*const  = (byproject, bystat) => {
        services.getUsersList(byproject, bystat).then((res) => {
            console.log(res.data.data); // Check the structure here
            if (res.data.type === 'success') {
                const usersData = res.data.data;
                if (Array.isArray(usersData)) {
                    setUserListInfo(usersData);
                } else {
                    // Handle the case where it's not an array
                    console.error("Expected an array but got: ", typeof usersData);
                }
            }
        });
    }; */



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.firstName) errors.firstName = "First Name is required";
        if (!formData.lastName) errors.lastName = "Last Name is required";
        if (!formData.email) errors.email = "Email Address is required";
        if (!formData.designation) errors.designation = "Designation is required";
        if (!formData.role) errors.role = "User Role is required";
        if (!formData.company) errors.company = "Company is required";
        if (!formData.employeeId) errors.employeeId = "Employee ID is required";
        return errors;
    };

    const [formAction, setFormAction] = useState('add');
    const createNewUser = () => {
        setSelectedUserIdForEdit('');
        setFormAction('add');
    }

    const editUser = (useridtoedit) => {
        setSelectedUserIdForEdit(useridtoedit);
        setFormAction('edit');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        const payload = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            role_id: parseInt(formData.role),
            designation_id: parseInt(formData.designation),
            employee_id: formData.employeeId,
            status: 1, // Assuming status is always active for new users
            company_id: parseInt(formData.company),
        };

        services.createUser(payload).then((res) => {
            if (res.type === "success") {
                //alert("User has been created successfully");
                setSuccessMessage("User has been created successfully");
                setTimeout(() => {
                    setSuccessMessage('');
                    setShowModal(false); // Close the modal

                    //const modalElement = document.getElementById('createuser');
                    //const modal = new bootstrap.Modal(modalElement);
                    //modal.hide(); // Use Bootstrap's API to hide the modal

                }, 5000); // 5 seconds
                // Optionally, you can reset the form or handle success in another way
            } else {
                //alert("Error creating user");
                setSuccessMessage("Error creating user");
            }
        }).catch((err) => {
            //alert("Error creating user");
            setSuccessMessage("Error creating user");
        });
    };

    const [userIdforUpdate, setUserIdforUpdate] = useState(null);
    const [userStatustoUpdate, setUserStatustoUpdate] = useState(null);

    const changeUserStatus = (userid, newStatus) => {
        setUserIdforUpdate(userid);
        setUserStatustoUpdate(newStatus);
        setShowPopup(true);

    }

    const handleCancel = () => {

        setShowPopup(false);
     }

     const handleUpdateUser = () => {
        setShowLoader(1);
            services.getUserById(userIdforUpdate).then((res) =>{
                if(res.data.type === 'success'){
                    var userResponse = res.data.data;

                    const requestObject =
                    {
                        "company_id": userResponse.company_id,
                        "first_name": userResponse.first_name,
                        "last_name": userResponse.last_name,
                        "email": userResponse.email,
                        "role_id": userResponse.role_id,
                        "designation_id": userResponse.designation_id,
                        "employee_id": userResponse.employee_id,
                        "status": userStatustoUpdate
                    }
                    var accessTokenIn=sessionStorage.getItem("accToken");
                    // const requestOptions = {
                    // method: 'PUT',
                    // headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer "+accessTokenIn
                    // },
                    // body: JSON.stringify(requestObject)
                    // };

                    //$(".load-wrap").show();
                    services.updateUserInformation(requestObject, userIdforUpdate).then((res) => {
                        console.log(res); //return false;
                        if(res.type === 'success'){
                           // $('.loadwrapper').hide();
                            getUsersList(userListwithProject, userStatustoUpdate, 1, ITEMS_PER_PAGE, managerId, myCompanyId);
                        }
                    });
                }
            });
            setShowPopup(false);

     }

     const handleAddTimeTrigger = (data) => {
        //alert("test");
        setSelectedUserIdForEdit(null);
        getUsersList(userListwithProject, userListwithStatus, 1, ITEMS_PER_PAGE, managerId, myCompanyId);
        // Perform any action needed on the main page
     };



     const handleCloseModal = () => {
        // This function will be triggered when the modal is closed
        setSelectedUserIdForEdit(null);
      };


     const columns = React.useMemo(
        () => {
            const baseColumns = [
                {
                    Header: 'Emp ID',
                    accessor: 'user.employee_id',
                    width: '110px',
                    sortType: 'basic',
                },
                {
                    Header: 'Name',
                    accessor: 'user',
                    width: 'auto',
                    Cell: ({ value }) => (
                        <div className="d-grid">
                            <span>
                                
                                <b>{`${value.first_name} ${value.last_name} `}</b>{(value.role.id === 2) && (<img src="images/manager-icon.svg" alt="" width={22} style={{"marginRight":"4px"}}/>)}</span>
                            <span><small>{value.designation ? value.designation.designation_title : "N/A"}</small></span>
                            <span>{value.email}</span>
                        </div>
                    ),
                    sortType: 'basic',
                },
                {
                    Header: 'Role',
                    accessor: row => row.user.role ? row.user.role.name : "N/A",
                    width: 'auto',
                    sortType: 'basic',
                },
                {
                    Header: 'Company',
                    accessor: row => row.company ? row.company.company_name : "N/A",
                    width: '200px',
                    Cell: ({ value }) => <span>{value}</span>,
                    sortType: 'basic',
                },
                {
                    Header: 'Project',
                    accessor: 'projects',
                    width: 'auto',
                    Cell:  ({ value }) => (
                        value && value.length > 0 ? (
                          value
                            .filter(projData => projData.projectAssignedStatus === 'assigned') // Filter by assigned status
                            .map((projData, projIndex) => (
                              <span key={projIndex} className="badge rounded-pill project-badge text-dark my-2 ms-1">
                                {projData.project.name}
                              </span>
                            ))
                        ) : (
                          <span>No Projects Assigned</span>
                        )
                      ),
                    sortType: 'basic',
                },
                {
                    Header: 'Reporting Manager',
                    accessor: row => row.reportingManager
                        ? `${row.reportingManager.first_name} ${row.reportingManager.last_name}`
                        : "N/A",
                    width: '200px',
                    sortType: 'basic',
                }
            ];

            if (loggedUser.toLowerCase() === 'admin') {
                baseColumns.push(
                    {
                        Header: 'Actions',
                        accessor: 'actions',
                        width: '70px',
                        Cell: ({ row }) => (
                            <div className="dropdown table-action-dd" style={{ textAlign: "center" }}>
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt="" />
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#createuser" onClick={() => editUser(row.original.user.id)}>
                                            <img src="images/edit.png" alt="" /> Edit User
                                        </a>
                                    </li>
                                    <li>
                                        {row.original.user.status === 1 ? (
                                            <a className="dropdown-item" onClick={() => changeUserStatus(row.original.user.id, 0)}>
                                                <img src="images/delete.svg" alt="" /> Deactivate User
                                            </a>
                                        ) : (
                                            <a className="dropdown-item" onClick={() => changeUserStatus(row.original.user.id, 1)}>
                                                <img src="images/greencheck.svg" alt="" /> Activate User
                                            </a>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        ),
                        disableSortBy: true,
                    }
                );
            }

            return baseColumns;
        },
        [loggedUser] // Add loggedUser as a dependency
    );


    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: userListInfo,
        },
        useSortBy
    );

    return (
        <>
            <main>
                <section>
                    <div className="container">
                        <h2 className="mainHeadline">User Management</h2>
                        <div className="tab-primary">
                            <div className="tab-content">
                                <div className="d-flex justify-content-between pt-2 pb-2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6 px-0">
                                                {(loggedUser.toLowerCase() === 'admin') && (
                                                    <button className="btn btn-primary m-0" data-bs-toggle="modal" data-bs-target="#createuser" onClick={() => createNewUser()}><span className="mx-2" >+</span> Create User</button>
                                                )}
                                            </div>

                                            <div className="col-lg-3">
                                                <div className="d-flex justify-content-end align-items-center ms-auto">
                                                    <span style={{ "min-width": "69px" }}>Projects</span>
                                                    <select class="form-select" id="projectdropdown" style={{"max-width": "200px"}} onChange={setProjectFilter}>
                                                        <option value="" selected>All</option>
                                                        {projectListInfo.map((projectdropdowndata, projectdropdownindex)=>(
                                                            <option value={projectdropdowndata.projectId}>{projectdropdowndata.projectName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="d-flex justify-content-end align-items-center ms-auto">
                                                    <span>Sort by:</span>
                                                    <div className="d-flex align-items-center gap-2 py-2 ps-2 form-switch">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"><b><small>Active</small></b></label>
                                                        <input className="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleUserStatus}/>
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"><b><small>Inactive</small></b></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tbl-container bdr mt-0">

                                <table {...getTableProps()} className="table mb-0">
    <thead className="bg-lightblue">
        {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width }}>
                        {column.render('Header')}
                        {/* Add sort indicator if column is sorted */}
                        <span>
                            {/*{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '↕️'} */}
                            {!column.disableSortBy ? (column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '↕️') : null}
                        </span>
                    </th>
                ))}
            </tr>
        ))}
    </thead>
    <tbody {...getTableBodyProps()}>
        {rows.length > 0 ? (
            rows.map(row => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                            </td>
                        ))}
                    </tr>
                );
            })
        ) : (
            <tr>
                <td colSpan={columns.length}>
                    <div className="d-grid text-center py-3">
                        <img src="images/emptybox.svg" className="m-auto" alt="" width={128} />
                        <span>No Users Available</span>
                    </div>
                </td>
            </tr>
        )}
    </tbody>
</table>


                   {/*
                    {(loggedUser.toLowerCase() === 'admin') ?
                                    <table className="table mb-0">
                                        <thead className="bg-lightblue">
                                            <tr>
                                                <th scope="col" style={{ "width": "110px" }}>Emp ID</th>
                                                <th scope="col" style={{ "width": "auto" }}>User Name</th>
                                                <th scope="col" style={{ "width": "auto" }}>User Role</th>
                                                <th scope="col" style={{ "width": "200px" }}>Company</th>
                                                <th scope="col" style={{ "width": "auto" }}>Project</th>
                                                <th scope="col" style={{ "width": "200px" }}>Reporting</th>
                                                {(loggedUser.toLowerCase() === 'admin') && (
                                                <th scope="col" style={{ "width": "70px" }}>Actions</th>
                                                )}
                                            </tr>
                                        </thead>
                                        {userListInfo.length > 0 ?
                                        <tbody>


                                                {userListInfo.map((userData, userIndex) => (
                                                    <tr key={userIndex}>

                                                        <td>{userData.user.employee_id}</td>


                                                        <td>
                                                            <div className="d-grid">
                                                                <span><b>{`${userData.user.first_name} ${userData.user.last_name}`}</b></span>
                                                                <span><small>{userData.user.designation ? userData.user.designation.designation_title : "N/A"}</small></span>
                                                                <span>{userData.user.email}</span>
                                                            </div>
                                                        </td>
                                                        <td>{userData.user.role.name ? userData.user.role.name : "N/A"}</td>

                                                        <td>{userData.company ? userData.company.company_name : "N/A"}</td>


                                                        <td>
                                                            {userData.projects && userData.projects.length > 0 ? (
                                                                userData.projects.map((projData, projIndex) => (
                                                                    <span key={projIndex} className="badge rounded-pill project-badge text-dark my-2 ms-1">
                                                                        {projData.name}
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                <span>No Projects Assigned</span>
                                                            )}
                                                        </td>


                                                        <td>
                                                            {userData.reportingManager
                                                                ? userData.reportingManager.first_name+' '+userData.reportingManager.last_name
                                                                : "N/A"}
                                                        </td>


                                                        {(loggedUser.toLowerCase() === 'admin') && (
                                                        <td style={{ textAlign: "center" }}>
                                                            <div className="dropdown table-action-dd">
                                                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                                                                    <img src="images/dots.svg" alt="" />
                                                                </button>
                                                                <ul className="dropdown-menu" data-popper-placement="bottom-end" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(0px, 30px)" }}>
                                                                    <li>
                                                                        <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#createuser" onClick={() => editUser(userData.user.id)}>
                                                                            <img src="images/edit.png" alt="" /> Edit User
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        {userData.user.status === 1 ?
                                                                        <a className="dropdown-item" onClick={() => changeUserStatus(userData.user.id, 0)}>
                                                                            <img src="images/delete.svg" alt="" /> Deactivate User
                                                                        </a>
                                                                        :
                                                                        <a className="dropdown-item" onClick={() => changeUserStatus(userData.user.id, 1)}>
                                                                            <img src="images/greencheck.svg" alt="" /> Activate User
                                                                        </a>
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        )}
                                                    </tr>
                                                ))}


                                        </tbody>
                                        :
                                        <tbody>
                                            <tr>
                                                <td colSpan={6}>
                                                <div className="d-grid text-center py-3">
                                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                        <span> No Users Available</span>
                                        </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                        }
                                    </table>
                                    :
                                    <table {...getTableProps()} className="table mb-0">
                                    <thead className="bg-lightblue">
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        style={{
                                                            width: column.width,
                                                            position: 'relative',

                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {column.render('Header')}
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                display: 'inline-block',
                                                                fontSize: '0.8em',
                                                                color: '#888',
                                                            }}
                                                        >
                                                            {column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : '↕️'}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.length > 0 ? (
                                            rows.map(row => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => (
                                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="6">
                                                <div className="d-grid text-center py-3">
                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                        <span> No Users Available</span>
                        </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

}  */}
                                    {showPopup && (
                                    <DeleteConfirmationPopup
                                    message="Are you sure you want to Update this user?"
                                    onConfirm={handleUpdateUser}
                                    onCancel={handleCancel}
                                    />
                              )}



                                </div>

                                <div class="paginationContent mt-3">
                            <Pagination
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={handlePageChange2}/>

                        </div>

                                {/* User Creation Modal */}
                                <div className="modal fade" id="createuser1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">Create User</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                            {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                                <form onSubmit={handleSubmit}>


                                                    <div class="row d-flex justify-content-between">
                                                        <div className="form-group mb-2 col-md-6">
                                                            <label>First Name*</label>
                                                            <input
                                                                type="text"
                                                                name="firstName"
                                                                value={formData.firstName}
                                                                onChange={handleInputChange}
                                                                className={`form-control ${formErrors.firstName ? 'is-invalid' : ''}`}
                                                            />
                                                            {formErrors.firstName && <span className="text-danger">{formErrors.firstName}</span>}
                                                        </div>
                                                        <div className="form-group mb-2 col-md-6">
                                                            <label>Last Name*</label>
                                                            <input
                                                                type="text"
                                                                name="lastName"
                                                                value={formData.lastName}
                                                                onChange={handleInputChange}
                                                                className={`form-control ${formErrors.lastName ? 'is-invalid' : ''}`}
                                                            />
                                                            {formErrors.lastName && <span className="text-danger">{formErrors.lastName}</span>}
                                                        </div>
                                                    </div>



                                                    <div className="form-group mb-3">
                                                        <label>Email Address*</label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                                        />
                                                        {formErrors.email && <span className="text-danger">{formErrors.email}</span>}
                                                    </div>



                                                    <div class="row">
                                                        <div class="form-group mb-2 col-md-6">
                                                            <label>Designation*</label>
                                                            <select
                                                                name="designation"
                                                                value={formData.designation}
                                                                onChange={handleInputChange}
                                                                className={`form-control ${formErrors.designation ? 'is-invalid' : ''}`}
                                                            >
                                                                <option value="">Select</option>
                                                                {/* Replace with your dynamic options */}
                                                                <option value="1">Designation 1</option>
                                                                <option value="2">Designation 2</option>
                                                            </select>
                                                            {formErrors.designation && <span className="text-danger">{formErrors.designation}</span>}
                                                        </div>
                                                        <div class="form-group mb-2 col-md-6">
                                                            <label>User Role*</label>
                                                            <select
                                                                name="role"
                                                                value={formData.role}
                                                                onChange={handleInputChange}
                                                                className={`form-control ${formErrors.role ? 'is-invalid' : ''}`}
                                                            >
                                                                <option value="">Select</option>
                                                                {/* Replace with your dynamic options */}
                                                                <option value="1">Admin</option>
                                                                <option value="2">Manager</option>
                                                                <option value="3">Employee</option>
                                                            </select>
                                                            {formErrors.role && <span className="text-danger">{formErrors.role}</span>}
                                                        </div>


                                                        <div class="form-group mb-2 col-md-6">
                                                            <label>Company*</label>
                                                            <select
                                                                name="company"
                                                                value={formData.company}
                                                                onChange={handleInputChange}
                                                                className={`form-control ${formErrors.company ? 'is-invalid' : ''}`}
                                                            >
                                                                <option value="">Select</option>
                                                                {/* Replace with your dynamic options */}
                                                                <option value="1">Company 1</option>
                                                                <option value="2">Company 2</option>
                                                            </select>
                                                            {formErrors.company && <span className="text-danger">{formErrors.company}</span>}
                                                        </div>
                                                        <div class="form-group mb-2 col-md-6">
                                                            <label>Employee ID*</label>
                                                            <input
                                                                type="text"
                                                                name="employeeId"
                                                                value={formData.employeeId}
                                                                onChange={handleInputChange}
                                                                className={`form-control ${formErrors.employeeId ? 'is-invalid' : ''}`}
                                                            />
                                                            {formErrors.employeeId && <span className="text-danger">{formErrors.employeeId}</span>}
                                                        </div>

                                                    </div>




                                                    <button type="submit" className="btn btn-primary">Create User</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End User Creation Modal */}
                            </div>
                            {showLoader === 1 ?
                            <div class="loadwrapper">
                                <div class="clock"><div class="circle"></div></div>
                            </div>
                            : '' }
                        </div>
                    </div>

                    <AddUser  onClose={handleCloseModal} onTrigger={handleAddTimeTrigger} editUser={selectedUserIdForEdit} formActionSet={formAction}/>
                </section>
            </main>
        </>
    );
};

export default UserManagement;
