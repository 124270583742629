import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../../components/home/home";
import Projects from "../../components/projects/projects";
import ProjectTaskDetails from "../../components/projects/projects-task-details";
import Reports from "../../components/reports/reports";
import TimesheetLanding from "../../components/timesheet/timesheet-landing";
import PendingForApproval from "../../components/timesheet/pending-for-approval";
//import UnsubmittedTimesheet from "../../components/timesheet/pending-for-approval";
import UnsubmittedTimesheet from "../../components/timesheet/unsubmitted-timesheet";
import ApprovedTimesheet from "../../components/timesheet/approved-timesheet";
import UserDashboard from "../../components/user/dashboard/dashboard";
import SubmittedWeekListView from "../../components/timesheet/submitted-weeklistview";
import router from "../../router/router";
import Login from "../../components/login/login";
import LoginComponent from "../../components/login/login-component";
import Profile from "../../components/profile/profile";
import ChangePassword from "../../components/profile/changepassword";
import AdminManagerDashboard from "../../components/admin-manager/dashboard/dashboard";
import AdminTimesheetManagement from "../../components/admin-manager/timesheet-management/timesheet-management";
import AdminPendingForApproval from "../../components/admin-manager/timesheet-management/admin-pending-for-approval";
import AdminUnsubmitted from "../../components/admin-manager/timesheet-management/admin-unsubmitted";
import AdminApproved from "../../components/admin-manager/timesheet-management/admin-approved";
import ProjectManagement from "../../components/admin-manager/project-management/project-management";
import UserManagement from "../../components/admin-manager/user-management/user-management";
import DetailedReports from "../../components/admin-manager/detailed-reports/detailed-reports";
import ForgotPassword from "../../components/login/forgot-password";
import ResetPassword from "../../components/login/reset-password";
import ProjectDetails from "../../components/admin-manager/project-management/project-details";
import TaskDetails from "../../components/admin-manager/project-management/task-details";
import ApprovedWeekListView from "../../components/timesheet/approved-weeklistview";
import PrivateRoute from "../../components/auth/private-route";
import Notifications from "../../components/notifications/notifications";

function MainContent(props) {
  const routes = router.getInstance().getRoutesInfo([
    "home"
  ]);

  return (

    <Routes>
      <Route path='/login2' element={<Login />} />
      <Route path='/login' element={<LoginComponent />} />

      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path="/forgot-password" element={<Navigate to="/ForgotPassword" />} />

      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path="/reset-password" element={<Navigate to="/ResetPassword" />} />

      <Route path='/ResetPassword' element={<ResetPassword />} />
      <Route path="/ResetPassword" element={<Navigate to="/ResetPassword" />} />


      <Route path="*" element={<Navigate to="/Login" />} />

      <Route path='/home' element={<Home />} />
      <Route path="/home" element={<PrivateRoute><Navigate to="/Home" /> </PrivateRoute>} />

      <Route path='/projects' element={<PrivateRoute><Projects /></PrivateRoute>} />
      <Route path="/projects" element={<Navigate to="/Projects" />} />

      <Route path='/projecttaskdetail' element={<PrivateRoute><ProjectTaskDetails /> </PrivateRoute>} />
      <Route path="/projecttaskdetail" element={<Navigate to="/ProjectTaskDetails" />} />

      <Route path='/reports' element={<PrivateRoute><Reports /></PrivateRoute>} />
      <Route path="/reports" element={<Navigate to="/Reports" />} />

      <Route path='/timesheet' element={<PrivateRoute><TimesheetLanding /></PrivateRoute>} />
      <Route path="/timesheet" element={<Navigate to="/TimesheetLanding" />} />

      <Route path='/pending-for-approval' element={<PrivateRoute><PendingForApproval /></PrivateRoute>} />
      <Route path="/pending-for-approval" element={<Navigate to="/PendingForApproval" />} />

      <Route path='/unsubmitted-list' element={<PrivateRoute><UnsubmittedTimesheet /></PrivateRoute>} />
      <Route path="/unsubmitted-list" element={<Navigate to="/UnsubmittedTimesheet" />} />

      <Route path='/approved-list' element={<PrivateRoute><ApprovedTimesheet /></PrivateRoute>} />
      <Route path="/approved-list" element={<Navigate to="/ApprovedTimesheet" />} />

      <Route path='/submitted-weekview' element={<PrivateRoute><SubmittedWeekListView /></PrivateRoute>} />
      <Route path="/submitted-weekview" element={<Navigate to="/SubmittedWeekListView" />} />

      <Route path='/approved-weekview' element={<PrivateRoute><ApprovedWeekListView /></PrivateRoute>} />
      <Route path="/approved-weekview" element={<Navigate to="/ApprovedWeekListView" />} />

      <Route path="/dashboard" element={<PrivateRoute><UserDashboard /></PrivateRoute>} />
      <Route path="/dashboard" element={<Navigate to="/UserDashboard" />} />
      <Route path="/employee" element={<Navigate to="/UserDashboard" />} />

      <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="/profile" element={<Navigate to="/Profile" />} />

      <Route path='/changepassword' element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
      <Route path="/changepassword" element={<Navigate to="/ChangePassword" />} />

      <Route path='/manager-dashboard' element={<PrivateRoute> <AdminManagerDashboard /> </PrivateRoute>} />
      <Route path="/manager-dashboard" element={<Navigate to="/AdminManagerDashboard" />} />

      <Route path='/admin-dashboard' element={<PrivateRoute><AdminManagerDashboard /></PrivateRoute>} />
      <Route path="/admin-dashboard" element={<Navigate to="/AdminManagerDashboard" />} />

      <Route path='/timesheet-management' element={<PrivateRoute><AdminTimesheetManagement /></PrivateRoute>} />
      <Route path="/timesheet-management" element={<Navigate to="/AdminTimesheetManagement" />} />

      <Route path='/admin-pending-for-approval' element={<PrivateRoute><AdminPendingForApproval /></PrivateRoute>} />
      <Route path="/admin-pending-for-approval" element={<Navigate to="/AdminPendingForApproval" />} />

      <Route path='/admin-unsubmitted' element={<PrivateRoute><AdminUnsubmitted /></PrivateRoute>} />
      <Route path="/admin-unsubmitted" element={<Navigate to="/AdminUnsubmitted" />} />

      <Route path='/admin-approved' element={<PrivateRoute><AdminApproved /></PrivateRoute>} />
      <Route path="/admin-approved" element={<Navigate to="/AdminApproved" />} />

      <Route path='/project-management' element={<PrivateRoute><ProjectManagement /></PrivateRoute>} />
      <Route path="/project-management" element={<Navigate to="/ProjectManagement" />} />

      <Route path='/user-management' element={<PrivateRoute><UserManagement /></PrivateRoute>} />
      <Route path="/user-management" element={<Navigate to="/UserManagement" />} />

      <Route path='/detailed-reports' element={<PrivateRoute><DetailedReports /></PrivateRoute>} />
      <Route path="/detailed-reports" element={<Navigate to="/DetailedReports" />} />

      <Route path='/projectDetail' element={<PrivateRoute><ProjectDetails /></PrivateRoute>} />
      <Route path="/projectDetail" element={<Navigate to="/ProjectDetails" />} />

      <Route path='/taskDetail' element={<PrivateRoute><TaskDetails /></PrivateRoute>} />
      <Route path="/taskDetail" element={<Navigate to="/TaskDetails" />} />

      <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
      <Route path="/notifications" element={<Navigate to="/Notifications" />} />


    </Routes>

  );
}

export default MainContent;