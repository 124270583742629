import React from 'react';
import { Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const PrivateRoute = ({ children }) => {
  //const isAuthenticated = localStorage.getItem('authToken'); // Replace this with actual auth check logic
  const loggedUserId = sessionStorage.getItem('userIdval');
  const accessToken = sessionStorage.getItem('accessToken');

   if (!loggedUserId || !accessToken) {
      return <Navigate to="/login" />;
   } 

   // Decode the access token
   const decodedToken = jwtDecode(accessToken);

   // Check if the token is expired
   const currentTime = Math.floor(Date.now() / 1000);
   if (decodedToken.exp < currentTime) {
      // Token has expired, navigate to login
      return <Navigate to="/login" />;
   }
   return children;

};

export default PrivateRoute;
