//import React from "react";
import React, { history, useEffect, useState, useMemo } from "react";
//import './../css/bootstrap.min.css';
//import '../css/style-guide.css';
//import '../assets/custome.css';
//import '../assets/css/media.css';

import { BrowserRouter } from "react-router-dom";
import MainContent from "./mainContent/MainContent";
import Appbar from "./header/header";
import PublicHeader from "./header/publicHeader";

import Footer from "./footer/index";

function Layout() {
  const [loggedUserRole, setLoggedUserRole] = useState('');
  useEffect(() => {

    var userRole = sessionStorage.getItem('userRole');
    setLoggedUserRole(userRole);

  }, []);

  const isEmpty = (value) => {
    return value == null || value === '';
 }

  return (

    
    <BrowserRouter >
      {!isEmpty(loggedUserRole) ? 
      <div>
          <Appbar/> 
          <PublicHeader/>
    
          <MainContent />
          
          <Footer />
      </div>
          
      : 
      <div>
    
          <MainContent />
          
          
      </div> }
      
    </BrowserRouter>


  );
}

export default Layout;