import axios from "axios";
 const accessTokenTo=sessionStorage.getItem("accToken");
 var accessToken = sessionStorage.getItem("accessToken");
export default axios.create({
  baseURL: process.env.React_App_AWS_API,
  headers: {
    "Content-type": "application/json",
   // "origin":"http://career-sites-uat-hto.s3-website.us-east-2.amazonaws.com"
    'Authorization': "Bearer "+accessToken
  }
});