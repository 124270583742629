import React, { useState, useEffect, useCallback } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import services from '../../../services/services';
import ReactECharts from 'echarts-for-react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const UserDashBoardDateMonthNavigator = () => {
  // Initialize with the current date in UTC
  const [currentDate, setCurrentDate] = useState(new Date(new Date().toISOString().split('T')[0]));
  const [barChartOptions, setBarChartOptions] = useState(null);
  const [weekStartDatePost, setWeekStartDatePost] = useState(null);
  const [weekEndDatePost, setWeekEndDatePost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resourceProject, setResourceProject] = useState("");
  const [projectWiseStatsData, setProjectWiseStatsData] = useState([]);
  const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));

  // Utility function to convert "HH:MM" to minutes
  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };
  // Helper function to convert "HH:MM" format to minutes
  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  useEffect(() => {
    const weekStart = startOfMonth(currentDate, { weekStartsOn: 1 });
    const weekEnd = endOfMonth(currentDate, { weekStartsOn: 1 });
    setWeekStartDatePost(format(weekStart, 'yyyy-MM-dd'));
    setWeekEndDatePost(format(weekEnd, 'yyyy-MM-dd'));
  }, [currentDate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const requestJson4 = {
        weekStartDate: weekStartDatePost,
        weekEndDate: weekEndDatePost,
        isBillable: "",
        projectIds: resourceProject ? [resourceProject] : [],
        userId: useridval,
        //companyId: "2"
      };

      try {

        const result5 =   await services.fetchProjectWiseStatsUser(requestJson4);
        const result4 = result5.projects;
       

      
        if (result4 && Array.isArray(result4) && result4.length > 0) {  
          //alert(JSON.stringify(result4));

          const projectNames = result4.map(item => item.projectName);
          //alert(projectNames);
          const billableHours = result4.map(item => convertTimeToMinutes(item.projectWiseBillableHours));
          const nonBillableHours = result4.map(item => convertTimeToMinutes(item.projectWiseNonBillableHours));

          const barChartOptions = {
            tooltip: {
              trigger: 'axis',
              axisPointer: { type: 'shadow' },
              formatter: (params) => {
                let content = `<strong>${params[0].axisValue}</strong><br/>`;
                params.forEach(item => {
                  const hours = Math.floor(item.value / 60);
                  const minutes = item.value % 60;
                  content += `${item.marker} ${item.seriesName}: ${hours}h ${minutes}m<br/>`;
                });
                return content;
              }
            },
            legend: { data: ['Billable Hours', 'Non-Billable Hours'] },
            xAxis: { type: 'category', data: projectNames },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: value => {
                  const hours = Math.floor(value / 60);
                  const minutes = value % 60;
                  return `${hours}h ${minutes}m`;
                }
              },
              splitLine: {
                lineStyle: {
                  width: 1,
                  color: '#ccc'
                }
              }
            },
            series: [
              { name: 'Billable Hours', type: 'bar', stack: 'total', data: billableHours, itemStyle: { color: '#00449d' } },
              { name: 'Non-Billable Hours', type: 'bar', stack: 'total', data: nonBillableHours, itemStyle: { color: '#3dacc8' } }
            ],
            grid: { left: '4%', right: '4%', bottom: '2%', containLabel: true }
          };

          setBarChartOptions(barChartOptions);
        } else {
          setBarChartOptions(null);
          console.error('Invalid data structure:', result4);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (weekStartDatePost && weekEndDatePost) {
      fetchData();
    }
  }, [weekStartDatePost, weekEndDatePost, resourceProject]);


  const handlePreviousMonth = () => {
    setCurrentDate((prevDate) => new Date(subMonths(prevDate, 1).toISOString().split('T')[0]));
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => new Date(addMonths(prevDate, 1).toISOString().split('T')[0]));
  };

  // Calculate the start and end of the month based on the current date
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);

  return (
    <div className="db-wrap">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex justify-content-start">
          <div className="prev-after-wrap">
            <a onClick={handlePreviousMonth}> <span> <img src="images/left.png" alt="" /></span></a>
            <a onClick={handleNextMonth}><span> <img src="images/right.png" alt="" /></span></a>
          </div>
          <div className="d-grid">
            <h2>{`${format(monthStart, 'd MMM')} - ${format(monthEnd, 'd MMM, yyyy')}`}</h2>
          </div>
        </div>
        <strong>Month</strong>
      </div>

      {/* <img src="images/addition/month.png" width="100%" alt="" /> */}

      <div class="db-wrap">
        {barChartOptions && <ReactECharts option={barChartOptions} style={{ height: 400, width: '100%' }} />}
      </div>


    </div>
  );
};

export default UserDashBoardDateMonthNavigator;
