import React, { useEffect, useState, useRef } from "react";
import services from "../../../services/services";
import $ from 'jquery';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Pagination from "../../../utility/Pagination/Pagination";
import { getCurrentDateInEST, convertDateStringToEST, convertDateStringToGMT, timezone } from "../../../timezone/dateUtils";
import { useTable, useSortBy } from 'react-table';
import ReactExport from "react-data-export";

const Option = (props) => {
    return (
        <div>
            {
                //console.log("results=="+JSON.stringify(props))
            }
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const DetailedReports = () => {
    const printDetailedReportRef = useRef();
    const [useridval, setuseridval] = useState(0);
    const [loggedUser, setLoggedUser] = useState('');

    const [date, setDate] = useState(getCurrentDateInEST());
    const [enddate, setEndDate] = useState(todaydateforCustom());
    const [startdate, setStartDate] = useState(todaydateforCustom());
    const [displayStartDate, setDisplayStartDate] = useState('');
    const [displayEndDate, setDisplayEndDate] = useState('');

    const [showCalendar, setShowCalendar] = useState(false);
    const [showDateChange, setShowDateChange] = useState(1);
    const [showingResultsForDate, setShowingResultsForDate] = useState(getCurrentDateInEST());
    var todayDate = getCurrentDateInEST();
    const [weekStartDate, setWeekStartDate] = useState('');
    const [weekEndDate, setWeekEndDate] = useState('');
    const [displayDate, setDisplayDate] = useState('');
    const [projectClientListInfo, setProjectClientListInfo] = useState([]);
    const [projectListInfo, setProjectListInfo] = useState([]);
    const [clientCompanyId, setClientCompanyId] = useState('');
    const clientStatus = 1;


    const pageNum = 1;
    const ITEMS_PER_PAGE = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();

    const [showLoader, setShowLoader] = useState(0);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    var FileSaver = require('file-saver');

    const toggleCalendar = (event) => {
        event.preventDefault();
        setShowCalendar(!showCalendar);
    };

    const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(0);
    const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(0);
    const toggleStartDateCalendar = () => {
        if (isStartCalendarOpen == 1) {
            setIsStartCalendarOpen(0);
        } else {
            setIsStartCalendarOpen(1);
        }
    };

    const toggleEndDateCalendar = () => {
        if (isEndCalendarOpen == 1) {
            setIsEndCalendarOpen(0);
        } else {
            setIsEndCalendarOpen(1);
        }
    };

    const [reportStartDate, setReportStartDate] = useState('');
    const [reportEndDate, setReportEndDate] = useState('');
    const [reportData, setReportData] = useState([]);
    const [reportEntries, setReportEntries] = useState([]);

    const isManager = sessionStorage.getItem('userRole') === 'Manager';

    function todaydateforCustom() {
        const givenDate1 = new DateObject(todayDate);
        const myformat = givenDate1.format("YYYY-MM-DD");
        return myformat;
    }

    function todaydateforCustomMMDDYYY() {
        const givenDate1 = new DateObject(todayDate);
        const myformat = givenDate1.format("MM-DD-YYYY");
        return myformat;
    }

    const [calendarDisplayStartday, setCalendarDisplayStartday] = useState(todaydateforCustomMMDDYYY());
    const handleStartDateChange = (startdateval) => {
        const givenDate1 = new DateObject(startdateval);
        const startday = givenDate1.format("YYYY-MM-DD");
        setStartDate(startday);

        const calendarDisplayStartday = givenDate1.format("MM-DD-YYYY");
        setCalendarDisplayStartday(calendarDisplayStartday);

        getReportData(startday, enddate, pageNum, ITEMS_PER_PAGE);
        setDisplayStartDate(getFormatCalendarDate(startdateval));
        setIsStartCalendarOpen(0);
    }

    const [calendarDisplayEndday, setCalendarDisplayEndday] = useState(todaydateforCustomMMDDYYY());
    const handleEndDateChange = (enddateval) => {
        const givenDate1 = new DateObject(enddateval);
        const endday = givenDate1.format("YYYY-MM-DD");

        const calendarDisplayEndday = givenDate1.format("MM-DD-YYYY");
        setCalendarDisplayEndday(calendarDisplayEndday);

        //setDisplayEndDate(reportendday);
        setEndDate(endday);
        getReportData(startdate, endday, pageNum, ITEMS_PER_PAGE);
        setDisplayEndDate(getFormatCalendarDate(enddateval));
        setIsEndCalendarOpen(0);
    }

    const handleDateChange = (newDate) => {
        setDate(newDate);
        setShowingResultsForDate(newDate);
        var datefilter = $('#resultDateFormat').val();
        setShowCalendar(false); // Hide calendar after date selection
        if (datefilter === 'day') {
            const givenDate1 = new DateObject(newDate);
            const reportday = givenDate1.format("YYYY-MM-DD");
            getReportData(reportday, reportday, pageNum, ITEMS_PER_PAGE);
            setDisplayDate(getFormatCalendarDate(newDate));
            //setReportStartDate(reportday);
            //setReportEndDate(reportday);
        }
        if (datefilter === 'week') {
            var datechangedobject = new DateObject(newDate);
            var datechangeformattedforentries = datechangedobject.format("YYYY-MM-DD");

            const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
            const newstartOfWeek = new Date(date);
            const dayDifference = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Adjust to Monday
            newstartOfWeek.setDate(date.getDate() + dayDifference);

            const week = [];
            for (let i = 0; i < 7; i++) {
                const weekDate = new Date(newstartOfWeek);
                weekDate.setDate(newstartOfWeek.getDate() + i);
                week.push(weekDate);
            }

            let weekyear1 = week[0].getFullYear();
            let weekmonth1 = ('0' + (week[0].getMonth() + 1)).slice(-2);
            let weekday1 = ('0' + week[0].getDate()).slice(-2);

            let weekendyear1 = week[6].getFullYear();
            let weekendmonth1 = ('0' + (week[6].getMonth() + 1)).slice(-2);
            let weekendday1 = ('0' + week[6].getDate()).slice(-2);

            let weekStartDate1 = `${weekyear1}-${weekmonth1}-${weekday1}`;
            let weekEndDate1 = `${weekendyear1}-${weekendmonth1}-${weekendday1}`;


            setWeekStartDate(weekStartDate1);
            setWeekEndDate(weekEndDate1);

            var weeksday1 = new DateObject(weekStartDate1);
            var weekeday7 = new DateObject(weekEndDate1);

            const givenDate1 = new Date(newDate);
            const dayOfWeek1 = givenDate1.getUTCDay();
            // Create Date objects for the given dates
            const startOfWeek1 = new Date(givenDate1);
            startOfWeek1.setUTCDate(givenDate1.getUTCDate() - dayOfWeek1 + 1);
            startOfWeek1.setUTCHours(0, 0, 0, 0);
            var mydateval = [];
            // Get the dates for the entire week
            for (let i = 0; i < 7; i++) {
                const currentDay1 = new Date(startOfWeek1);
                currentDay1.setUTCDate(startOfWeek1.getUTCDate() + i);
                mydateval.push(formatDate(currentDay1));
            }

            setDisplayDate('Week: ' + caliculateWeekLimit(newDate));
            //setReportStartDate(mydateval[0]);
            //setReportEndDate(mydateval[6]);
            getReportData(mydateval[0], mydateval[6], pageNum, ITEMS_PER_PAGE);
        }
        if (datefilter === 'month') {
            const givenDate = new Date(newDate);

            // Get the year and month from the given date
            const year = givenDate.getUTCFullYear();
            const month = givenDate.getUTCMonth();

            // Create a Date object for the start of the month
            const startOfMonth = new Date(Date.UTC(year, month, 1));

            // Create a Date object for the end of the month
            // Set the date to the next month and then subtract 1 day
            const endOfMonth = new Date(Date.UTC(year, month + 1, 0));

            // Convert the start and end dates to ISO string format (UTC)
            const startUTC = startOfMonth.toISOString();
            const endUTC = endOfMonth.toISOString();
            var mymonthstart = startUTC.split('T');
            var mymonthend = endUTC.split('T');
            getReportData(mymonthstart[0], mymonthend[0], pageNum, ITEMS_PER_PAGE);
            setDisplayDate('Month: ' + getMonthValue(newDate));
        }
        if (datefilter === 'quarter') {
            setDisplayDate('Quarter: ' + getQuarterlyPeriods(newDate));
        }
        if (datefilter === 'halfyear') {
            setDisplayDate('Halfyear: ' + getHalfYearPeriods(newDate));
        }
        if (datefilter === 'year') {
            setDisplayDate('Year: ' + getYearlyPeriod(newDate));
        }
    };

    function getQuarterlyPeriods(date) {
        // Create a new Date object for the given date
        const givenDate = new Date(date);

        // Get the year and month from the given date
        const year = givenDate.getUTCFullYear();
        const month = givenDate.getUTCMonth();

        let startOfQuarter, endOfQuarter;

        // Determine the quarter based on the month
        if (month >= 0 && month <= 2) {
            // Q1: January 1st - March 31st
            startOfQuarter = new Date(Date.UTC(year, 0, 1));
            endOfQuarter = new Date(Date.UTC(year, 2, 31));
        } else if (month >= 3 && month <= 5) {
            // Q2: April 1st - June 30th
            startOfQuarter = new Date(Date.UTC(year, 3, 1));
            endOfQuarter = new Date(Date.UTC(year, 5, 30));
        } else if (month >= 6 && month <= 8) {
            // Q3: July 1st - September 30th
            startOfQuarter = new Date(Date.UTC(year, 6, 1));
            endOfQuarter = new Date(Date.UTC(year, 8, 30));
        } else if (month >= 9 && month <= 11) {
            // Q4: October 1st - December 31st
            startOfQuarter = new Date(Date.UTC(year, 9, 1));
            endOfQuarter = new Date(Date.UTC(year, 11, 31));
        }

        // Convert the start and end dates to ISO string format (UTC)
        const startUTC = startOfQuarter.toISOString();
        const endUTC = endOfQuarter.toISOString();

        var startdatequarter = startUTC.split('T');
        var enddatequarter = endUTC.split('T');
        setReportStartDate(startdatequarter[0]);
        setReportEndDate(enddatequarter[0]);

        //console.log(startdatequarter[0]+' - '+enddatequarter[0]);
        getReportData(startdatequarter[0], enddatequarter[0], pageNum, ITEMS_PER_PAGE);
        //setShowingResultsForDate(startUTC);
        var dateob = new DateObject(date);
        var yearfordate = dateob.format("YYYY");

        const startmonthName = new Date(startUTC).toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' });
        const endmonthName = new Date(endUTC).toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' });
        return startmonthName + ' - ' + endmonthName + ', ' + yearfordate;

    }

    function getHalfYearPeriods(date) {
        // Create a new Date object for the given date
        const givenDate = new Date(date);

        // Get the year and month from the given date
        const year = givenDate.getUTCFullYear();
        const month = givenDate.getUTCMonth();

        let startOfHalfYear, endOfHalfYear;

        // Determine the half-year period based on the month
        if (month >= 0 && month <= 5) {
            // H1: January 1st - June 30th
            startOfHalfYear = new Date(Date.UTC(year, 0, 1));
            endOfHalfYear = new Date(Date.UTC(year, 5, 30));
        } else {
            // H2: July 1st - December 31st
            startOfHalfYear = new Date(Date.UTC(year, 6, 1));
            endOfHalfYear = new Date(Date.UTC(year, 11, 31));
        }

        // Convert the start and end dates to ISO string format (UTC)
        const startUTC = startOfHalfYear.toISOString();
        const endUTC = endOfHalfYear.toISOString();


        var startdatehalfyear = startUTC.split('T');
        var enddatehalfyear = endUTC.split('T');
        setReportStartDate(startdatehalfyear[0]);
        setReportEndDate(enddatehalfyear[0]);

        //console.log(startdatequarter[0]+' - '+enddatequarter[0]);
        getReportData(startdatehalfyear[0], enddatehalfyear[0], pageNum, ITEMS_PER_PAGE);
        //setShowingResultsForDate(startUTC);
        var dateob = new DateObject(date);
        var yearfordate = dateob.format("YYYY");

        const startmonthName = new Date(startUTC).toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' });
        const endmonthName = new Date(endUTC).toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' });
        return startmonthName + ' - ' + endmonthName + ', ' + yearfordate;

    }

    function getYearlyPeriod(date) {
        // Create a new Date object for the given date
        const givenDate = new Date(date);

        // Get the year from the given date
        const year = givenDate.getUTCFullYear();

        // Create a Date object for the start of the year
        const startOfYear = new Date(Date.UTC(year, 0, 1));

        // Create a Date object for the end of the year
        const endOfYear = new Date(Date.UTC(year, 11, 31));

        // Convert the start and end dates to ISO string format (UTC)
        const startUTC = startOfYear.toISOString();
        const endUTC = endOfYear.toISOString();

        var startdateyear = startUTC.split('T');
        var enddateyear = endUTC.split('T');
        setReportStartDate(startdateyear[0]);
        setReportEndDate(enddateyear[0]);

        //console.log(startdatequarter[0]+' - '+enddatequarter[0]);
        getReportData(startdateyear[0], enddateyear[0], pageNum, ITEMS_PER_PAGE);
        //setShowingResultsForDate(startUTC);
        var dateob = new DateObject(date);
        var yearfordate = dateob.format("YYYY");

        const startmonthName = new Date(startUTC).toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' });
        const endmonthName = new Date(endUTC).toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' });
        return startmonthName + ' - ' + endmonthName + ', ' + yearfordate;

    }

    const getReportData = (dateval1, dateval2, pagenumval, iemsperpage) => {
        setShowLoader(1);
        setReportStartDate(dateval1);
        setReportEndDate(dateval2);
        console.log(dateval1 + ' - ' + dateval2);
        const requestObject = {
            "clientIds": clientRequestArray,
            "projectIds": projectRequestArray,
            "sprints": sprintRequestArray,
            "companyIds": companyRequestArray,
            "taskIds": taskRequestArray,
            "userIds": userRequestArray,
            "isBillable": $('#billableFilter').val(),
            "weekStartDate": dateval1,
            "weekEndDate": dateval2,
            "pageSize": iemsperpage,
            "pageNumber": pagenumval
        }
        //$('.loadwrapper').show();
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     //'Authorization': accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        //$(".load-wrap").show(); //console.log(requestObject); return false;
        services.getReportFilteredTimeEntries(requestObject).then((res) => {
            if (res.type === 'success') {
                setReportEntries(res.data.timeEntries)
                setReportData(res.data);
                console.log(res.data.totalElements);
                if (res.data.totalElements > 0) {
                    setTotalItems(res.data.totalElements);
                    settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage(pagenumval);
                }
                else {
                    setTotalItems(0);
                    settotalPages(1);
                    setCurrentPage(pagenumval);
                }

            }
            setShowLoader(0);
        });

    }

    const handlePageChange2 = (offset, triggerevent) => {
        getReportData(reportStartDate, reportEndDate, offset, ITEMS_PER_PAGE);
    }

    const getFormatCalendarDate = (calendarDate) => {

        if (calendarDate == "") {
            return "";
        }

        var daten = new DateObject(calendarDate);
        var daten1 = daten.format("dddd, DD MMM YYYY");
        var selecteddate = daten.format('DD');

        return daten1;
    }

    const caliculateWeekLimit = (date1) => {
        const givenDate = new Date(date1);
        const dayOfWeek = givenDate.getUTCDay();
        // Create Date objects for the given dates
        const startOfWeek = new Date(givenDate);
        startOfWeek.setUTCDate(givenDate.getUTCDate() - dayOfWeek + 1);
        startOfWeek.setUTCHours(0, 0, 0, 0);
        var mydateval = [];
        // Get the dates for the entire week
        for (let i = 0; i < 7; i++) {
            const currentDay = new Date(startOfWeek);
            currentDay.setUTCDate(startOfWeek.getUTCDate() + i);
            mydateval.push(formatDate(currentDay));
        }

        var strtdate = new DateObject(mydateval[0]);
        var dateweek1 = strtdate.format("DD MMM");

        var enddate = new DateObject(mydateval[6]);
        var dateweek2 = enddate.format("DD MMM, YYYY");
        return (dateweek1 + ' - ' + dateweek2);
    }

    const getMonthValue = (date1) => {
        const utcDate = new Date(date1); // Example UTC date
        // Get the full month name
        const monthName = utcDate.toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' }); // Outputs: "August"

        // If you want the abbreviated month name:
        const shortMonthName = utcDate.toLocaleString('en-GB', { month: 'short', timeZone: 'UTC' }); // Outputs: "Aug"

        var dateob = new DateObject(date1);
        var yearfordate = dateob.format("YYYY");
        return monthName + ', ' + yearfordate; // Outputs: "August"
    }

    function formatDate(date) {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateDurationFormat = (event) => {
        var mydateFormat = event.target.value;
        if (mydateFormat === 'custom') {
            setShowDateChange(0);
            setDisplayStartDate(getFormatCalendarDate(date));
            setDisplayEndDate(getFormatCalendarDate(date));
            getReportData(startdate, enddate, pageNum, ITEMS_PER_PAGE);
        } else {
            setShowDateChange(1);
            if (mydateFormat === 'day') {
                const givenDate1 = new DateObject(date);
                const reporday = givenDate1.format("YYYY-MM-DD");

                getReportData(reporday, reporday, pageNum, ITEMS_PER_PAGE);
                setDisplayDate(getFormatCalendarDate(date));
                //setReportStartDate(reporday);
                //setReportEndDate(reporday);
            }
            if (mydateFormat === 'week') {
                const givenDate1 = new Date(date);
                const dayOfWeek1 = givenDate1.getUTCDay();
                // Create Date objects for the given dates
                const startOfWeek1 = new Date(givenDate1);
                startOfWeek1.setUTCDate(givenDate1.getUTCDate() - dayOfWeek1 + 1);
                startOfWeek1.setUTCHours(0, 0, 0, 0);
                var mydateval = [];
                // Get the dates for the entire week
                for (let i = 0; i < 7; i++) {
                    const currentDay1 = new Date(startOfWeek1);
                    currentDay1.setUTCDate(startOfWeek1.getUTCDate() + i);
                    mydateval.push(formatDate(currentDay1));
                }

                setDisplayDate('Week: ' + caliculateWeekLimit(date));
                //setReportStartDate(mydateval[0]);
                //setReportEndDate(mydateval[6]);
                getReportData(mydateval[0], mydateval[6], pageNum, ITEMS_PER_PAGE);
            }
            if (mydateFormat === 'month') {
                const givenDate = new Date(date);

                // Get the year and month from the given date
                const year = givenDate.getUTCFullYear();
                const month = givenDate.getUTCMonth();

                // Create a Date object for the start of the month
                const startOfMonth = new Date(Date.UTC(year, month, 1));

                // Create a Date object for the end of the month
                // Set the date to the next month and then subtract 1 day
                const endOfMonth = new Date(Date.UTC(year, month + 1, 0));

                // Convert the start and end dates to ISO string format (UTC)
                const startUTC = startOfMonth.toISOString();
                const endUTC = endOfMonth.toISOString();
                var mymonthstart = startUTC.split('T');
                var mymonthend = endUTC.split('T');

                getReportData(mymonthstart[0], mymonthend[0], pageNum, ITEMS_PER_PAGE);
                //setReportStartDate(mymonthstart[0]);
                //setReportEndDate(mymonthend[0]);
                setDisplayDate('Month: ' + getMonthValue(date));
            }
            if (mydateFormat === 'quarter') {
                setDisplayDate('Quarter: ' + getQuarterlyPeriods(date));
            }
            if (mydateFormat === 'halfyear') {
                setDisplayDate('Halfyear: ' + getHalfYearPeriods(date));
            }
            if (mydateFormat === 'year') {
                setDisplayDate('Year: ' + getYearlyPeriod(date));
            }
        }
    }

    const getPrevious = () => {
        var datefilter = $('#resultDateFormat').val();
        if (datefilter === 'day') {
            let previousday = new Date(showingResultsForDate);
            previousday.setDate(showingResultsForDate.getDate() - 1);

            // Format the date as YYYY-MM-DD
            let year = previousday.getFullYear();
            let month = ('0' + (previousday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + previousday.getDate()).slice(-2);

            let previousdayDate = `${year}-${month}-${day}`;

            //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
            previousdayDate = convertDateStringToEST(previousdayDate);

            if (previousdayDate.getDay() == 0) //sunday
            {
                getPreviousWeekDates(previousdayDate);
            }

            //setDisplayDate(getFormatCalendarDate(previousdayDate));
            setShowingResultsForDate(previousdayDate);
            handleDateChange(previousdayDate);
        }
        if (datefilter === 'week') {
            let previousweek = new Date(showingResultsForDate);
            //let previousweek = getCurrentDateInEST(new Date(showingResultsForDate));
            previousweek.setDate(showingResultsForDate.getDate() - 7);

            // Format the date as YYYY-MM-DD
            let year = previousweek.getFullYear();
            let month = ('0' + (previousweek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + previousweek.getDate()).slice(-2);

            let previousweekDate = `${year}-${month}-${day}`;

            //previousweekDate = new Date(previousweekDate); // Outputs: YYYY-MM-DD
            previousweekDate = convertDateStringToEST(previousweekDate);
            if (previousweekDate.getDay() == 0) //sunday
            {
                getPreviousWeekDates(previousweekDate);
            }

            setShowingResultsForDate(previousweekDate);
            handleDateChange(previousweekDate);
        }
        if (datefilter === 'month') {
            let currentmonth = new Date(showingResultsForDate);
            console.log(showingResultsForDate)
            // Convert the date to UTC
            let currentutcDate = new Date(Date.UTC(
                showingResultsForDate.getUTCFullYear(),
                showingResultsForDate.getUTCMonth(),
                showingResultsForDate.getUTCDate()
            ));

            // Move to the previous month
            currentutcDate.setUTCMonth(currentutcDate.getUTCMonth() - 1);

            // Set the day to the first day of the month
            currentutcDate.setUTCDate(1);

            // Format the date as yyyy-mm-dd
            const firstDateOfPreviousMonthUTC = currentutcDate.toISOString().split('T')[0];
            var firstDateOfPreviousMonth = convertDateStringToEST(firstDateOfPreviousMonthUTC);

            setShowingResultsForDate(firstDateOfPreviousMonth);
            handleDateChange(firstDateOfPreviousMonth);
        }
        if (datefilter === 'quarter') {
            let currentquarter1 = new Date(showingResultsForDate);
            let previousday = new Date(reportStartDate);
            console.log(previousday + ' - ' + reportStartDate);

            previousday.setDate(previousday.getDate() - 1);

            // Format the date as YYYY-MM-DD
            let year = previousday.getFullYear();
            let month = ('0' + (previousday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + previousday.getDate()).slice(-2);

            let previousdayDate = `${year}-${month}-${day}`;

            //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
            previousdayDate = convertDateStringToEST(previousdayDate);


            console.log(previousdayDate);

            setShowingResultsForDate(previousdayDate);
            handleDateChange(previousdayDate);
        }
        if (datefilter === 'halfyear') {
            let currentquarter1 = new Date(showingResultsForDate);
            let previoushalfyearday = new Date(reportStartDate);
            console.log(previoushalfyearday + ' - ' + reportStartDate);

            previoushalfyearday.setDate(previoushalfyearday.getDate() - 1);

            // Format the date as YYYY-MM-DD
            let year = previoushalfyearday.getFullYear();
            let month = ('0' + (previoushalfyearday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + previoushalfyearday.getDate()).slice(-2);

            let previoushalfyeardayDate = `${year}-${month}-${day}`;

            //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
            previoushalfyeardayDate = convertDateStringToEST(previoushalfyeardayDate);


            console.log(previoushalfyeardayDate);

            setShowingResultsForDate(previoushalfyeardayDate);
            handleDateChange(previoushalfyeardayDate);
        }
        if (datefilter === 'year') {
            let currentquarter1 = new Date(showingResultsForDate);
            let previousyearday = new Date(reportStartDate);
            console.log(previousyearday + ' - ' + reportStartDate);

            previousyearday.setDate(previousyearday.getDate() - 1);

            // Format the date as YYYY-MM-DD
            let year = previousyearday.getFullYear();
            let month = ('0' + (previousyearday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + previousyearday.getDate()).slice(-2);

            let previousyeardayDate = `${year}-${month}-${day}`;

            //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
            previousyeardayDate = convertDateStringToEST(previousyeardayDate);


            console.log(previousyeardayDate);

            setShowingResultsForDate(previousyeardayDate);
            handleDateChange(previousyeardayDate);
        }
    }

    const getNext = () => {
        var datefilter = $('#resultDateFormat').val();
        if (datefilter === 'day') {
            let year = showingResultsForDate.getFullYear();
            let month = ('0' + (showingResultsForDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + showingResultsForDate.getDate()).slice(-2);

            let showingResultsForDateformatted = `${year}-${month}-${day}`;

            var nextdayvalnew = caliculateNextDay(showingResultsForDateformatted, 1);
            let date = new Date(showingResultsForDate);

            // Increment the date by one day
            date.setDate(date.getDate() + 1);

            let todayDateyear = todayDate.getFullYear();
            let todayDatemonth = ('0' + (todayDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let todayDateday = ('0' + todayDate.getDate()).slice(-2);

            let todatedateformattedval = `${todayDateyear}-${todayDatemonth}-${todayDateday}`;

            if (new Date(nextdayvalnew) <= new Date(todatedateformattedval)) {
                handleDateChange(convertDateStringToEST(new Date(nextdayvalnew)));
                // setDisplayDate(getFormatCalendarDate(nextdayvalnew));
            } else {

            }
        }
        if (datefilter === 'week') {
            let year = showingResultsForDate.getFullYear();
            let month = ('0' + (showingResultsForDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + showingResultsForDate.getDate()).slice(-2);

            let showingResultsForDateformatted = `${year}-${month}-${day}`;

            const currentDay = showingResultsForDate.getUTCDay();
            const daysUntilNextMonday = (1 - currentDay + 7) % 7;
            var nextdayvalnew = caliculateNextDay(showingResultsForDateformatted, 6); // week

            var nextMonday = new Date(showingResultsForDate);
            nextMonday.setUTCDate(showingResultsForDate.getUTCDate() + daysUntilNextMonday);
            var nextMondayDateutc = nextMonday.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format

            // Create a Date object for the given date
            let date = new Date(showingResultsForDate);

            // Increment the date by one day
            date.setDate(date.getDate() + 1);

            // Format the date as a string in YYYY-MM-DD format
            let nextDay = date.toISOString().split('T')[0];

            var myenddate = new Date(todayDate);

            let todayDateyear = todayDate.getFullYear();
            let todayDatemonth = ('0' + (todayDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let todayDateday = ('0' + todayDate.getDate()).slice(-2);

            let todatedateformattedval = `${todayDateyear}-${todayDatemonth}-${todayDateday}`;

            var mynextday = convertDateStringToEST(nextDay);
            if (new Date(nextMondayDateutc) <= new Date(todatedateformattedval)) {
                handleDateChange(convertDateStringToEST(new Date(nextdayvalnew)));
            }
            else {
                //console.log('not matched');
            }
        }
        if (datefilter === 'month') {
            let currentmonth = new Date(showingResultsForDate);

            // Convert the date to UTC
            let currentutcDate = new Date(Date.UTC(
                showingResultsForDate.getUTCFullYear(),
                showingResultsForDate.getUTCMonth(),
                showingResultsForDate.getUTCDate()
            ));

            // Move to the Next month
            currentutcDate.setUTCMonth(currentutcDate.getUTCMonth() + 1);
            var currentdayMonth = currentutcDate.getUTCMonth() + 1;

            // Set the day to the first day of the month
            currentutcDate.setUTCDate(1);

            // Format the date as yyyy-mm-dd
            const firstDateOfNextMonthUTC = currentutcDate.toISOString().split('T')[0];
            var firstDateOfNextMonth = convertDateStringToEST(firstDateOfNextMonthUTC);

            let todayDatemonth = ('0' + (todayDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            console.log(currentdayMonth + ' - ' + todayDatemonth);
            if (currentdayMonth <= todayDatemonth) {
                setShowingResultsForDate(firstDateOfNextMonth);
                handleDateChange(firstDateOfNextMonth);
            } else {

            }
        }
        if (datefilter === 'quarter') {
            let currentquarter1 = new Date(showingResultsForDate);
            let nextday = new Date(reportEndDate);

            nextday.setDate(nextday.getDate() + 1);

            // Format the date as YYYY-MM-DD
            let year = nextday.getFullYear();
            let month = ('0' + (nextday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + nextday.getDate()).slice(-2);

            let nextdayDate = `${year}-${month}-${day}`;

            //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
            nextdayDate = convertDateStringToEST(nextdayDate);

            let todayDateyear = todayDate.getFullYear();
            let todayDatemonth = ('0' + (todayDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let todayDateday = ('0' + todayDate.getDate()).slice(-2);

            let todatedateformattedval = `${todayDateyear}-${todayDatemonth}-${todayDateday}`;

            if (nextdayDate <= new Date(todatedateformattedval)) {
                setShowingResultsForDate(nextdayDate);
                handleDateChange(nextdayDate);
            } else {

            }

        }
        if (datefilter === 'halfyear') {
            let currentquarter1 = new Date(showingResultsForDate);
            let nexthalfyearday = new Date(reportEndDate);

            nexthalfyearday.setDate(nexthalfyearday.getDate() + 1);

            // Format the date as YYYY-MM-DD
            let year = nexthalfyearday.getFullYear();
            let month = ('0' + (nexthalfyearday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + nexthalfyearday.getDate()).slice(-2);

            let nexthalfyeardayDate = `${year}-${month}-${day}`;

            //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
            nexthalfyeardayDate = convertDateStringToEST(nexthalfyeardayDate);

            let todayDateyear = todayDate.getFullYear();
            let todayDatemonth = ('0' + (todayDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let todayDateday = ('0' + todayDate.getDate()).slice(-2);

            let todatedateformattedval = `${todayDateyear}-${todayDatemonth}-${todayDateday}`;

            if (nexthalfyeardayDate <= new Date(todatedateformattedval)) {
                setShowingResultsForDate(nexthalfyeardayDate);
                handleDateChange(nexthalfyeardayDate);
            } else {

            }
        }
        if (datefilter === 'year') {
            let currentquarter1 = new Date(showingResultsForDate);
            let nextyearday = new Date(reportEndDate);

            nextyearday.setDate(nextyearday.getDate() + 1);

            // Format the date as YYYY-MM-DD
            let year = nextyearday.getFullYear();
            let month = ('0' + (nextyearday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let day = ('0' + nextyearday.getDate()).slice(-2);

            let nextyeardayDate = `${year}-${month}-${day}`;

            //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
            nextyeardayDate = convertDateStringToEST(nextyeardayDate);

            let todayDateyear = todayDate.getFullYear();
            let todayDatemonth = ('0' + (todayDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
            let todayDateday = ('0' + todayDate.getDate()).slice(-2);

            let todatedateformattedval = `${todayDateyear}-${todayDatemonth}-${todayDateday}`;

            if (nextyeardayDate <= new Date(todatedateformattedval)) {
                setShowingResultsForDate(nextyeardayDate);
                handleDateChange(nextyeardayDate);
            } else {

            }
        }
    }

    function getPreviousWeekDates(sundayDate) {

        // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        let sunDay = sundayDate.getDay();

        // Adjust currentDay to use 0 for Monday, 1 for Tuesday, ..., 6 for Sunday
        let adjustedDay = (sunDay + 6) % 7;

        // Calculate the start and end dates of the week
        let startOfWeek = new Date(sundayDate);
        let endOfWeek = new Date(sundayDate);

        // Adjust the start date to the previous Monday
        startOfWeek.setDate(sundayDate.getDate() - adjustedDay);

        // Adjust the end date to the next Sunday
        endOfWeek.setDate(sundayDate.getDate() + (6 - adjustedDay));

        // Format the dates as YYYY-MM-DD
        let startYear = startOfWeek.getFullYear();
        let startMonth = ('0' + (startOfWeek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let startDay = ('0' + startOfWeek.getDate()).slice(-2);

        let endYear = endOfWeek.getFullYear();
        let endMonth = ('0' + (endOfWeek.getMonth() + 1)).slice(-2);
        let endDay = ('0' + endOfWeek.getDate()).slice(-2);

        let weekStartDate = `${startYear}-${startMonth}-${startDay}`;
        let weekEndDate = `${endYear}-${endMonth}-${endDay}`;

        setWeekStartDate(weekStartDate);
        setWeekEndDate(weekEndDate);
        //getFullWeekTimesInfo(useridval, weekStartDate, weekEndDate);
    }

    const caliculateNextDay = (mydate, adddays) => {
        // Validate and parse the date string
        const dateParts = mydate.split('-');
        if (dateParts.length === 3) {
            const [year, month, day] = dateParts.map(Number);

            // Create a Date object in UTC
            const date = new Date(Date.UTC(year, month - 1, day));

            // Check if the Date object is valid
            if (!isNaN(date.getTime())) {
                // Add one day to the date
                date.setUTCDate(date.getUTCDate() + adddays);

                // Format the new date to YYYY-MM-DD
                const nextDay = date.toISOString().split('T')[0];
                return nextDay; // Outputs "2024-08-04"
            } else {
                //console.error("Invalid Date object created");
            }
        } else {
            //console.error("Invalid date string format");
        }
    }

    useEffect(() => {
        $('.loadwrapper').hide();
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);

        var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));

        const givenDate1 = new Date(date);
        const dayOfWeek1 = givenDate1.getUTCDay();
        // Create Date objects for the given dates
        const startOfWeek1 = new Date(givenDate1);
        startOfWeek1.setUTCDate(givenDate1.getUTCDate() - dayOfWeek1 + 1);
        startOfWeek1.setUTCHours(0, 0, 0, 0);
        var mydateval = [];
        // Get the dates for the entire week
        for (let i = 0; i < 7; i++) {
            const currentDay1 = new Date(startOfWeek1);
            currentDay1.setUTCDate(startOfWeek1.getUTCDate() + i);
            mydateval.push(formatDate(currentDay1));
        }
        //console.log(mydateval);
        setDisplayDate('Week: ' + caliculateWeekLimit(date));

        getReportData(mydateval[0], mydateval[6], pageNum, ITEMS_PER_PAGE);



    }, [1]);

    const [loggedUserCompanyId, setLoggedUserCompanyId] = useState('');
    const [loggedUserCompanyName, setLoggedUserCompanyName] = useState('');

    const getMasterCalls = () => {
        var loggedUserCompanyIdVal = sessionStorage.getItem('userCompanyId');
        getFullCompanyList();
        var loggedUserRole = sessionStorage.getItem('userRole');
        if (loggedUserRole.toLowerCase() === 'manager') {
            getClientsFilter(loggedUserCompanyIdVal);
            getProjectsByClient([]);
            getTaskUsersbytaskids([loggedUserCompanyIdVal], [], [], []); //company, client, project, task
        }
        if (loggedUserRole.toLowerCase() === 'admin') {
            getClientsFilter('');
            getProjectsByClient([]);
            getTaskUsersbytaskids([], [], [], []); //company, client, project, task
        }
    }

    const [companyFilterData, setCompaniesFilterData] = useState([]);
    const [multiCompanyOptions, setmultiCompanyOptions] = useState([]);
    const [optionCompanyFilterSelected, setoptionCompanyFilterSelected] = useState([]);
    const [companyRequestArray, setCompanyRequestArray] = useState([]);

    const [clientFilterData, setClientFilterData] = useState([]);
    const [multiClientOptions, setmultiClientOptions] = useState([]);
    const [optionClientFilterSelected, setoptionClientFilterSelected] = useState([]);
    const [clientRequestArray, setClientRequestArray] = useState([]);

    const [projectFilterData, setProjectFilterData] = useState([]);
    const [multiProjectOptions, setmultiProjectOptions] = useState([]);
    const [optionProjectFilterSelected, setoptionProjectFilterSelected] = useState([]);
    const [projectRequestArray, setProjectRequestArray] = useState([]);

    const [sprintFilterData, setSprintFilterData] = useState([]);
    const [multiSprintOptions, setmultiSprintOptions] = useState([]);
    const [optionSprintFilterSelected, setoptionSprintFilterSelected] = useState([]);
    const [sprintRequestArray, setSprintRequestArray] = useState([]);

    const [taskFilterData, setTaskFilterData] = useState([]);
    const [multiTaskOptions, setmultiTaskOptions] = useState([]);
    const [optionTaskFilterSelected, setoptionTaskFilterSelected] = useState([]);
    const [taskRequestArray, setTaskRequestArray] = useState([]);

    const [userFilterData, setUserFilterData] = useState([]);
    const [multiUserOptions, setmultiUserOptions] = useState([]);
    const [optionUserFilterSelected, setoptionUserFilterSelected] = useState([]);
    const [userRequestArray, setuserRequestArray] = useState([]);



    const getFullCompanyList = () => {
        console.log(loggedUser.toLowerCase());
        if (loggedUser.toLowerCase() === 'manager') {
            var loggedUserCompanyIdVal = sessionStorage.getItem('userCompanyId');
            setLoggedUserCompanyId(parseInt(loggedUserCompanyIdVal));
            var loggedUserCompanyNameVal = sessionStorage.getItem('userCompanyName');
            setLoggedUserCompanyName(parseInt(loggedUserCompanyNameVal));

            var dummArray_vals = [];
            var dummArray = [];
            var dummArray_label = [];
            var companyidsSelected = [];
            dummArray.push({ "label": loggedUserCompanyNameVal, "value": loggedUserCompanyIdVal });

            dummArray_vals.push(loggedUserCompanyIdVal);
            companyidsSelected.push(loggedUserCompanyIdVal);

            setCompanyRequestArray(dummArray_vals);
            setoptionCompanyFilterSelected(dummArray);
            handleCompanyFilterChange(dummArray);
        }
        if (loggedUser.toLowerCase() === 'admin') {
            services.getCompanyList(1).then((res) => {
                if (res.data.type === "success") {
                    setCompaniesFilterData(res.data.data);
                    var compdummArray = [{ "label": "All", "value": "all" }];
                    var compdummArray_all = [];
                    for (var i = 0; i < res.data.data.length; i++) {
                        compdummArray.push({ "label": res.data.data[i].company_name, "value": res.data.data[i].id });
                        compdummArray_all.push({ "label": res.data.data[i].company_name, "value": res.data.data[i].id });
                    }
                    setmultiCompanyOptions(compdummArray);
                    //setoptionCompanyFilterSelected(compdummArray_all);
                    if (optionCompanyFilterSelected.length > 0) {
                        handleCompanyFilterChange(optionCompanyFilterSelected);
                    }
                }
            });
        }
    }

    const handleCompanyFilterChange = (selected) => {
        var allIndex = '';
        for (var i = 0; i < selected.length; i++) {
            if (selected[i].label == 'All') {
                allIndex = i;
            }
        }

        var dummArray = [];
        var dummArray_label = [];
        var companyidsSelected = [];
        if (!isEmpty(allIndex)) {
            if (allIndex == 0) {
                selected.splice(allIndex, 1);
                for (var i = 0; i < selected.length; i++) {
                    dummArray.push(selected[i].value.toString());
                    dummArray_label.push(selected[i].label.toString());
                    companyidsSelected.push(selected[i].value.toString());
                }
                setCompanyRequestArray(dummArray);
                setoptionCompanyFilterSelected(selected);
            }
            else {
                var mynewSelected = selected[allIndex];
                var dummArray_vals = [];
                for (var i = 0; i < companyFilterData.length; i++) {
                    dummArray.push({ "label": companyFilterData[i].company_name, "value": companyFilterData[i].id });

                    dummArray_vals.push(companyFilterData[i].id);
                    companyidsSelected.push(companyFilterData[i].id);
                }
                setCompanyRequestArray(dummArray_vals);
                setoptionCompanyFilterSelected(dummArray);
            }
        } else {
            for (var i = 0; i < selected.length; i++) {
                dummArray.push(selected[i].value.toString());
                dummArray_label.push(selected[i].label.toString());
                companyidsSelected.push(selected[i].value);
            }
            setCompanyRequestArray(dummArray);
            setoptionCompanyFilterSelected(selected);
        }

        //console.log(companyidsSelected); return false;
        if (companyidsSelected.length < companyFilterData.length) {
            getClientsFilter(companyidsSelected[0]);
        } else {
            getClientsFilter('');
        }
    }

    const getClientsFilter = (companyid) => {
        var managerid = '';
        services.getClientsList(companyid, 1, 1, 1000, managerid).then((res) => {
            if (res.data.type === "success") {
                setClientFilterData(res.data.data);

                var dummArray = [{ "label": "All", "value": "all" }];
                var dummArray_all = [];
                var clientidsDefault = [];
                for (var i = 0; i < res.data.data.length; i++) {
                    dummArray.push({ "label": res.data.data[i].client_name, "value": res.data.data[i].id });
                    dummArray_all.push({ "label": res.data.data[i].client_name, "value": res.data.data[i].id });
                    clientidsDefault.push(res.data.data[i].id);
                }
                setmultiClientOptions(dummArray);
                if (companyid != '') {
                    getProjectsByClient(clientidsDefault);
                }

                if (optionClientFilterSelected.length > 0) {
                    handleClientFilterChange(optionClientFilterSelected);
                }
                //setoptionClientFilterSelected(dummArray_all);
                //handleClientFilterChange(dummArray);
            }
        });
    }

    const handleClientFilterChange = (selected) => {
        var allIndex = '';
        for (var i = 0; i < selected.length; i++) {
            if (selected[i].label == 'All') {
                allIndex = i;
            }
        }

        var dummArray = [];
        var dummArray_label = [];
        var clientidsSelected = [];
        if (!isEmpty(allIndex)) {
            if (allIndex == 0) {
                selected.splice(allIndex, 1);
                for (var i = 0; i < selected.length; i++) {
                    dummArray.push(selected[i].value.toString());
                    dummArray_label.push(selected[i].label.toString());
                    clientidsSelected.push(selected[i].value.toString());
                }
                setClientRequestArray(dummArray);
                setoptionClientFilterSelected(selected);
            }
            else {
                var mynewSelected = selected[allIndex];
                var dummArray_vals = [];
                for (var i = 0; i < clientFilterData.length; i++) {
                    dummArray.push({ "label": clientFilterData[i].client_name, "value": clientFilterData[i].id });

                    dummArray_vals.push(clientFilterData[i].id);
                    clientidsSelected.push(clientFilterData[i].id);
                }
                setClientRequestArray(dummArray_vals);
                setoptionClientFilterSelected(dummArray);
            }
        } else {
            for (var i = 0; i < selected.length; i++) {
                dummArray.push(selected[i].value.toString());
                dummArray_label.push(selected[i].label.toString());
                clientidsSelected.push(selected[i].value);
            }
            setClientRequestArray(dummArray);
            setoptionClientFilterSelected(selected);
        }
        getProjectsByClient(clientidsSelected);
    }

    const getProjectsByClient = (clientIds) => {
        if (loggedUser.toLowerCase() === 'manager') {
            var requestObject = {
                "clientIds": clientIds,
                "companyIds": [sessionStorage.getItem('userCompanyId')]
            }
        }
        if (loggedUser.toLowerCase() === 'admin') {
            var requestObject = {
                "clientIds": clientIds,
                "companyIds": companyRequestArray
            }
        }

        $('.loadwrapper').show();
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     //'Authorization': accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        //$(".load-wrap").show(); //console.log(requestObject); return false;
        services.getProjectsFilterbyClient(requestObject).then((res) => {
            if (res.type === 'success') {
                setProjectFilterData(res.data);

                var projdummArray = [{ "label": "All", "value": "all" }];
                var projdummArray_all = [];
                for (var i = 0; i < res.data.length; i++) {
                    projdummArray.push({ "label": res.data[i].name, "value": res.data[i].id });
                    projdummArray_all.push({ "label": res.data[i].name, "value": res.data[i].id });
                }
                setmultiProjectOptions(projdummArray);

                if (optionProjectFilterSelected.length > 0) {
                    handleProjectFilterChange(optionProjectFilterSelected);
                }
                //setoptionProjectFilterSelected(projdummArray_all);
                //handleProjectFilterChange(projdummArray);
            }
        });
    }

    const handleProjectFilterChange = (projselected) => {
        var allprojIndex = '';
        for (var i = 0; i < projselected.length; i++) {
            if (projselected[i].label == 'All') {
                allprojIndex = i;
            }
        }

        var projdummArray = [];
        var projdummArray_label = [];
        var projectidsSelected = [];
        if (!isEmpty(allprojIndex)) {
            if (allprojIndex == 0) {
                projselected.splice(allprojIndex, 1);
                for (var i = 0; i < projselected.length; i++) {
                    projdummArray.push(projselected[i].value.toString());
                    projdummArray_label.push(projselected[i].label.toString());
                    projectidsSelected.push(projselected[i].value.toString());
                }
                setProjectRequestArray(projdummArray);
                setoptionProjectFilterSelected(projselected);
            }
            else {
                var projdummArray_vals = [];
                for (var i = 0; i < projectFilterData.length; i++) {
                    projdummArray.push({ "label": projectFilterData[i].name, "value": projectFilterData[i].id });
                    projdummArray_vals.push(projectFilterData[i].id);
                    projectidsSelected.push(projectFilterData[i].id);
                }
                setProjectRequestArray(projdummArray_vals);
                setoptionProjectFilterSelected(projdummArray);
            }
        } else {
            for (var i = 0; i < projselected.length; i++) {
                projdummArray.push(projselected[i].value.toString());
                projdummArray_label.push(projselected[i].label.toString());
                projectidsSelected.push(projselected[i].value);
            }
            setProjectRequestArray(projdummArray);
            setoptionProjectFilterSelected(projselected);
        }
        getTasksByProject(projectidsSelected, []);
    }

    const getTasksByProject = (projectids, sprintids) => {
        const requestObject = {
            "projectIds": projectids,
            "sprints": sprintids,
            "taskIds": []
        }
        $('.loadwrapper').show();
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     //'Authorization': accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        // $(".load-wrap").show(); //console.log(requestObject); return false;
        services.getTasksFilterbyProject(requestObject).then((res) => {
            if (res.type === 'success') {
                //console.log(res.data);
                setTaskFilterData(res.data);

                const sprints = res.data.map(task => task.sprint);

                setSprintFilterData(sprints);

                var taskdummArray = [{ "label": "All", "value": "all" }];
                var taskdummArray_all = [];
                for (var i = 0; i < res.data.length; i++) {
                    taskdummArray.push({ "label": res.data[i].name, "value": res.data[i].id });
                    taskdummArray_all.push({ "label": res.data[i].name, "value": res.data[i].id });
                }
                setmultiTaskOptions(taskdummArray);
                //setoptionProjectFilterSelected(projdummArray_all);
                //handleProjectFilterChange(projdummArray);

                var sprintdummArray = [{ "label": "All", "value": "all" }];
                var sprintdummArray_all = [];
                for (var i = 0; i < sprints.length; i++) {
                    sprintdummArray.push({ "label": sprints[i], "value": sprints[i] });
                    sprintdummArray_all.push({ "label": sprints[i], "value": sprints[i] });
                }
                setmultiSprintOptions(sprintdummArray);

                if (optionTaskFilterSelected.length > 0) {
                    handleTaskFilterChange(optionTaskFilterSelected);
                }

                if (optionSprintFilterSelected.length > 0) {
                    handleSprintFilterChange(optionSprintFilterSelected);
                }
            }
        });
    }

    const handleSprintFilterChange = (sprintSelected) => {
        var allsprintIndex = '';
        for (var i = 0; i < sprintSelected.length; i++) {
            if (sprintSelected[i].label == 'All') {
                allsprintIndex = i;
            }
        }

        var sprintdummArray = [];
        var sprintdummArray_label = [];
        var sprintidsSelected = [];
        if (!isEmpty(allsprintIndex)) {
            if (allsprintIndex == 0) {
                sprintSelected.splice(allsprintIndex, 1);
                for (var i = 0; i < sprintSelected.length; i++) {
                    sprintdummArray.push(sprintSelected[i].value.toString());
                    sprintdummArray_label.push(sprintSelected[i].label.toString());
                    sprintidsSelected.push(sprintSelected[i].value.toString());
                }
                setSprintRequestArray(sprintdummArray);
                setoptionSprintFilterSelected(sprintSelected);
            }
            else {
                var sprintdummArray_vals = [];
                for (var i = 0; i < sprintFilterData.length; i++) {
                    sprintdummArray.push({ "label": sprintFilterData[i], "value": sprintFilterData[i] });

                    sprintdummArray_vals.push(sprintFilterData[i]);
                    sprintidsSelected.push(sprintFilterData[i]);
                }
                setSprintRequestArray(sprintdummArray_vals);
                setoptionSprintFilterSelected(sprintdummArray);
            }
        } else {
            for (var i = 0; i < sprintSelected.length; i++) {
                sprintdummArray.push(sprintSelected[i].value.toString());
                sprintdummArray_label.push(sprintSelected[i].label.toString());
                sprintidsSelected.push(sprintSelected[i].value);
            }
            setSprintRequestArray(sprintdummArray);
            setoptionSprintFilterSelected(sprintSelected);
        }
        getTasksByProject(projectRequestArray, sprintidsSelected);
    }

    const handleTaskFilterChange = (taskSelected) => {
        var allTaskIndex = '';
        for (var i = 0; i < taskSelected.length; i++) {
            if (taskSelected[i].label == 'All') {
                allTaskIndex = i;
            }
        }

        var taskdummArray = [];
        var taskdummArray_label = [];
        var taskidsSelected = [];
        if (!isEmpty(allTaskIndex)) {
            if (allTaskIndex == 0) {
                taskSelected.splice(allTaskIndex, 1);
                for (var i = 0; i < taskSelected.length; i++) {
                    taskdummArray.push(taskSelected[i].value.toString());
                    taskdummArray_label.push(taskSelected[i].label.toString());
                    taskidsSelected.push(taskSelected[i].value.toString());
                }
                setTaskRequestArray(taskdummArray);
                setoptionTaskFilterSelected(taskSelected);
            }
            else {
                var sprintdummArray_vals = [];
                for (var i = 0; i < taskFilterData.length; i++) {
                    taskdummArray.push({ "label": taskFilterData[i].name, "value": taskFilterData[i].id });

                    sprintdummArray_vals.push(taskFilterData[i].id);
                    taskidsSelected.push(taskFilterData[i].id);
                }
                setTaskRequestArray(sprintdummArray_vals);
                setoptionTaskFilterSelected(taskdummArray);
            }
        } else {
            for (var i = 0; i < taskSelected.length; i++) {
                taskdummArray.push(taskSelected[i].value.toString());
                taskdummArray_label.push(taskSelected[i].label.toString());
                taskidsSelected.push(taskSelected[i].value);
            }
            setTaskRequestArray(taskdummArray);
            setoptionTaskFilterSelected(taskSelected);
        }
        if (loggedUser.toLowerCase() === 'manager') {
            getTaskUsersbytaskids([sessionStorage.getItem('userCompanyId')], clientRequestArray, projectRequestArray, taskidsSelected);
        }
        if (loggedUser.toLowerCase() === 'admin') {
            getTaskUsersbytaskids(companyRequestArray, clientRequestArray, projectRequestArray, taskidsSelected);
        }
    }

    const getTaskUsersbytaskids = (companyids, clientids, projectids, taskids) => {
        const requestObject = {
            "projectIds": projectids,
            "clientIds": clientids,
            "companyIds": companyids,
            "taskIds": taskids
        }
        $('.loadwrapper').show();
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     //'Authorization': accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        //$(".load-wrap").show(); //console.log(requestObject); return false;
        services.getTasksUsersbyTask(requestObject).then((res) => {
            console.log(res);
            if (res.type === 'success') {

                setUserFilterData(res.data);

                var userdummArray = [{ "label": "All", "value": "all" }];
                var userdummArray_all = [];
                for (var i = 0; i < res.data.length; i++) {
                    userdummArray.push({ "label": res.data[i].name, "value": res.data[i].id });
                    userdummArray_all.push({ "label": res.data[i].name, "value": res.data[i].id });
                }
                setmultiUserOptions(userdummArray);
                //setoptionProjectFilterSelected(projdummArray_all);
                if (optionUserFilterSelected.length > 0) {
                    handleUserFilterChange(optionUserFilterSelected);
                }
                //handleProjectFilterChange(projdummArray);
            }
        });
    }

    const handleUserFilterChange = (teamSelected) => {
        var allTeamIndex = '';
        for (var i = 0; i < teamSelected.length; i++) {
            if (teamSelected[i].label == 'All') {
                allTeamIndex = i;
            }
        }

        var teamdummArray = [];
        var teamdummArray_label = [];
        var teamidsSelected = [];
        if (!isEmpty(allTeamIndex)) {
            if (allTeamIndex == 0) {
                teamSelected.splice(allTeamIndex, 1);
                for (var i = 0; i < teamSelected.length; i++) {
                    teamdummArray.push(teamSelected[i].value.toString());
                    teamdummArray_label.push(teamSelected[i].label.toString());
                    teamidsSelected.push(teamSelected[i].value.toString());
                }
                setuserRequestArray(teamdummArray);
                setoptionUserFilterSelected(teamSelected);
            }
            else {
                var teamdummArray_vals = [];
                for (var i = 0; i < userFilterData.length; i++) {
                    teamdummArray.push({ "label": userFilterData[i].name, "value": userFilterData[i].id });

                    teamdummArray_vals.push(userFilterData[i].id);
                    teamidsSelected.push(userFilterData[i].id);
                }
                setuserRequestArray(teamdummArray_vals);
                setoptionUserFilterSelected(teamdummArray);
            }
        } else {
            for (var i = 0; i < teamSelected.length; i++) {
                teamdummArray.push(teamSelected[i].value.toString());
                teamdummArray_label.push(teamSelected[i].label.toString());
                teamidsSelected.push(teamSelected[i].value);
            }
            setuserRequestArray(teamdummArray);
            setoptionUserFilterSelected(teamSelected);
        }
    }

    const runReport = () => {
        const requestObject = {
            "clientIds": clientRequestArray,
            "projectIds": projectRequestArray,
            "sprints": sprintRequestArray,
            "companyIds": companyRequestArray,
            "taskIds": taskRequestArray,
            "userIds": userRequestArray,
            "isBillable": $('#billableFilter').val(),
            "weekStartDate": reportStartDate,
            "weekEndDate": reportEndDate,
            "pageSize": ITEMS_PER_PAGE,
            "pageNumber": pageNum
        }
        setShowLoader(1);
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     //'Authorization': accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        // $(".load-wrap").show(); //console.log(requestObject); return false;
        services.getReportFilteredTimeEntries(requestObject).then((res) => {
            if (res.type === 'success') {
                $('#closemodal').click();
                setReportEntries(res.data.timeEntries)
                setReportData(res.data);
                console.log(res.data.totalElements);
                if (res.data.totalElements > 0) {
                    setTotalItems(res.data.totalElements);
                    settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage(pageNum);
                }
                else {
                    setTotalItems(0);
                    settotalPages(1);
                    setCurrentPage(pageNum);
                }

            }
            setShowLoader(0);
        });
    }

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'day',
                width: '15%',
                Cell: ({ value }) => {
                    const date = new Date(value);
                    const mm = String(date.getMonth() + 1).padStart(2, '0');
                    const dd = String(date.getDate()).padStart(2, '0');
                    const yyyy = date.getFullYear();
                    return `${mm}-${dd}-${yyyy}`;
                },
            },
            {
                Header: 'Client',
                accessor: 'client.client_name',
                width: '15%',
            },
            {
                Header: 'Project',
                accessor: 'project.name',
                width: '15%',
            },
            {
                Header: 'Sprint',
                accessor: 'task.sprint',
                width: '10%',
            },
            {
                Header: 'Task',
                accessor: 'task.name',
                width: '15%',
            },
            {
                Header: 'Users',
                accessor: row => `${row.user.first_name} ${row.user.last_name}`,
                width: '10%',
            },
            {
                Header: 'Hours',
                accessor: row => row.duration.slice(0, -3),
                width: '10%',
            },
            {
                Header: 'Is Billable',
                accessor: row => row.is_billable,
                width: '10%',
                Cell: ({ value }) => (
                    value === 1 ? (
                        <img src="images/billed.png" width="20" alt="" />
                    ) : (

                        <img src="images/dollarred.svg" width="20" alt="" />
                    )
                ),
            },
        ],
        []
    );
    {/* <img src="images/nonbill.png" alt="" /> */ }
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: reportEntries,
        },
        useSortBy
    );

    const filterContent_export = () => {
        const requestObject = {
            "clientIds": [],
            "projectIds": [],

            "taskIds": [],
            "userIds": [],
            "isBillable": "",
            "weekStartDate": reportStartDate,
            "weekEndDate": reportEndDate,
            "pageSize": '',
            "pageNumber": 1
        }

        setShowLoader(1);

        var accessTokenIn = sessionStorage.getItem("accToken");
        console.log(requestObject);
        // const requestOptions = {
        // method: 'POST',
        // headers: { 'Content-Type': 'application/json',
        //           'Authorization': "Bearer "+accessTokenIn
        //         },
        // body: JSON.stringify(requestObject)
        // };
        //$(".load-wrap").show();
        services.getReportFilteredTimeEntries(requestObject).then((res) => {
            if (res.type === 'success') {
                setReportEntries(res.data.timeEntries)
                setExportReportDataWithHours(res.data);
                download_excel(res.data.timeEntries);
            }
        });
    }

    const [exportReportDataWithHours, setExportReportDataWithHours] = useState([]);

    function download_excel(exportReportData) {
        //   console.log(profileListFull);

        var today = new Date();
        var month = today.toLocaleString('en-US', { month: 'short' });
        var date = today.getDate();
        var year = today.getFullYear();

        var filename = "AdminReport-export-" + month + "-" + date + "-" + year + ".csv";
        // console.log(filename);
        let csv = '';
        let row = '';
        let headers = '';

        var reportlisthead = 'TimeEntry On' + ',' + 'Client' + ',' + 'Project' + ',' + 'Sprint' + ',' + 'Task' + ',' + 'User' + ',' + 'Hours' + ',' + 'Is Billable' + ',';

        headers = headers.concat(reportlisthead);
        csv = csv.concat(headers);
        row = '';

        console.log(exportReportData.length);
        for (var i = 0; i < exportReportData.length; i++) {

            var reportlistrow = "\n" + exportReportData[i].day + ',"' + exportReportData[i].client.client_name + '",' + exportReportData[i].project.name + ", " + exportReportData[i].task.sprint + ',' + exportReportData[i].task.name + ',' + (exportReportData[i].user.first_name + ' ' + exportReportData[i].user.last_name) + ',' + (exportReportData[i].duration).slice(0, -3) + ',';

            if (exportReportData[i].is_billable === 1) {
                reportlistrow = reportlistrow + 'Billable' + ',';
            } else {
                reportlistrow = reportlistrow + 'Non-Billable' + ',';
            }


            row = row.concat(reportlistrow);

        }
        csv = csv.concat(row);

        var file = new File([csv], filename, { type: "application/csv" });
        FileSaver.saveAs(file);
        setShowLoader(0);
    }

    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">
                            Reports
                        </h2>

                        <div class="tab-primary">
                            <div class="tab-content-bg-primary position-relative">

                                <div class="d-flex justify-content-between ">
                                    <div class="d-flex">
                                        <div class="prev-after-wrap">
                                            {showDateChange === 1 ?
                                                <>
                                                    <a href="#" onClick={getPrevious}> <span> <img src="images/left.png" alt="" /></span></a>
                                                    <a href="#" onClick={getNext}><span> <img src="images/right.png" alt="" /></span></a>
                                                </>
                                                :
                                                ''}
                                        </div>
                                        <div class="daytitlewrap ">
                                            <span class="d-flex gap-3">
                                                {showDateChange === 1 ?
                                                    <>
                                                        <div>
                                                            <button onClick={toggleCalendar} style={{ background: 'none', margin: "0px", border: 'none', cursor: 'pointer' }}><img class="icon" src="images/cal-icon.png" alt="" /></button>
                                                            {/*<div><Calendar onChange={handleDateChange} value={date} maxDate={new Date()} /></div> */}
                                                            {showCalendar && (

                                                                <div><Calendar onChange={handleDateChange} value={date} maxDate={getCurrentDateInEST()} /></div>

                                                            )}
                                                        </div>
                                                        <div class="d-grid align-items-center">
                                                            <h2>{displayDate} </h2>
                                                            {/* <a href="#"><img src="images/returnArrow.png" style={{"margin-right": "4px"}} alt=""/>Return to Today</a> */}

                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="date-picker-container">
                                                            <input className="cal-icon" type="text" id="editproject_startdate" name="editproject_startdate" value={calendarDisplayStartday} onClick={toggleStartDateCalendar} readOnly />
                                                            {isStartCalendarOpen == 1 && (
                                                                <Calendar onChange={handleStartDateChange} value={startdate} className="calendar" />
                                                            )}
                                                        </div>

                                                        <div className="date-picker-container">
                                                            <input type="text" value={calendarDisplayEndday} id="editproject_enddate" name="editproject_enddate" onClick={toggleEndDateCalendar} readOnly />
                                                            {isEndCalendarOpen == 1 && (
                                                                <Calendar onChange={handleEndDateChange} value={enddate} className="calendar" />
                                                            )}
                                                        </div>
                                                        {/* <div>
                                        <button onClick={toggleCalendar} style={{ background: 'none', margin: "0px", border: 'none', cursor: 'pointer' }}><img class="icon" src="images/cal-icon.png" alt="" /></button>

                                        {showCalendar && (

                                            <div><Calendar onChange={handleStartDateChange} value={startdate} maxDate={getCurrentDateInEST()} /></div>

                                        )}
                                    </div>
                                    <div class="d-grid align-items-center"><h2>start: {displayStartDate} </h2></div>

                                    <div>
                                        <button onClick={toggleCalendar} style={{ background: 'none', margin: "0px", border: 'none', cursor: 'pointer' }}><img class="icon" src="images/cal-icon.png" alt="" /></button>

                                        {showCalendar && (
                                            <div><Calendar onChange={handleEndDateChange} value={enddate} maxDate={getCurrentDateInEST()} /></div>
                                        )}
                                    </div>
                                    <div class="d-grid align-items-center"><h2> end: {displayEndDate} </h2></div> */}
                                                    </>
                                                }



                                            </span></div>
                                    </div>
                                    <div class="dayweekswitch">
                                        <div class="d-flex justify-content-end align-items-center">

                                            <select class="form-select" id="resultDateFormat" onChange={handleDateDurationFormat} style={{ "max-width": "200px" }}>
                                                <option value="day" >day</option>
                                                <option value="week" selected>Weekly</option>
                                                <option value="month">Monthly</option>
                                                <option value="quarter">Quarterly</option>
                                                <option value="halfyear">Halfyearly</option>
                                                <option value="year">Yearly</option>
                                                <option value="custom">Custom</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>



                                <div class="my-3"></div>
                                <div class="d-flex justify-content-between">
                                    <div class="billnonbill d-flex gap-4 align-items-center me-5">
                                        <div class="item d-grid">
                                            <small> Total hour</small>
                                            <strong class="mainbluetxt ">{reportData.totalHoursOfWholeData}</strong>
                                        </div>
                                        <span>|</span>
                                        <div class="item d-grid">
                                            <small class="d-flex gap-1 align-items-center"> <img src="images/billed.png" width="20" alt="" /> Billable</small>
                                            <strong class="mainbluetxt ">{reportData.totalBillableHours}</strong>
                                        </div>
                                        <span>|</span>
                                        <div class="item d-grid">
                                            <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg" width="20" alt="" />
                                                Non-billable</small>
                                            <strong class="mainbluetxt ">{reportData.totalNonBillableHours}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-content ">

                                <div class="d-flex justify-content-between align-items-center pt-2 pb-2">
                                    <button class="btn btn-primary d-flex align-items-center gap-2 m-0" data-bs-toggle="modal" data-bs-target="#editday" onClick={getMasterCalls}> <img src="images/filter.svg" alt="" /> Show Filter</button>
                                    {/* <span>
                        <div class="d-flex justify-content-end align-items-center" style={{"width": "320px"}}>
                            <span style={{"min-width": "69px"}}>Sort by:</span>
                    <select class="form-select" id="inputGroupSelect01" style={{"max-width": "200px"}}>
                                <option selected="Select">Select Client</option>
                                <option value="1">Small Nation</option>
                                <option value="2">Option Scanner</option>
                                <option value="3">Nexa</option>
                            </select>
                </div>
                </span> */}
                                    <div class="dropdown">
                                        <button class="btn exportbut" type="button" id="dropdownMenuButton1" aria-expanded="false" onClick={filterContent_export} >
                                            <img src="images/export.svg" alt="" /><span> Export to Excel</span>
                                        </button>
                                        {/* <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#">PDF</a></li>
                            <li><a class="dropdown-item" href="#">XLS</a></li>
                            <li><a class="dropdown-item" href="#">Doc</a></li>
                        </ul> */}
                                    </div>
                                </div>

                                <div class="tbl-container bdr mt-0">
                                    <table {...getTableProps()} className="table mb-0">
                                        <thead className="bg-lightblue">
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th
                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                            style={{
                                                                width: column.width,
                                                                position: 'relative',

                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            {column.render('Header')}
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    top: '50%',
                                                                    transform: 'translateY(-50%)',
                                                                    display: 'inline-block',
                                                                    fontSize: '0.8em',
                                                                    color: '#888',
                                                                }}
                                                            >
                                                                {column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : '↕️'}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {rows.length > 0 ? (
                                                rows.map(row => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            {row.cells.map(cell => (
                                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            ))}
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="d-grid text-center py-3">
                                                            <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                                            <span> No Entries Available</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {parseInt(reportData.totalHoursOfPage) > 0 ?
                                                <tr>
                                                    <td colspan="7" style={{ "width": "auto" }}><b>Total</b></td>
                                                    <td style={{ "width": "auto" }}><b>{reportData.totalHoursOfPage}</b></td>
                                                </tr>
                                                : ''}
                                        </tbody>

                                    </table>

                                    {/*
                    <table class="table mb-0">
                        <thead class="bg-lightblue">
                            <tr>
                                <th scope="col" style={{"width": "150px"}}>Date</th>
                                <th scope="col" style={{"width": "150px"}}>Client</th>
                                <th scope="col" style={{"width": "auto"}}>Project</th>
                                <th scope="col" style={{"width": "auto"}}>Sprint</th>
                                <th scope="col" style={{"width": "auto"}}>Task</th>
                                <th scope="col" style={{"width": "200px"}}>User</th>
                                <th scope="col" style={{"width": "100px"}}>Hours</th>


                            </tr>

                        </thead>
                        {reportEntries.length > 0 ?
                        <tbody>
                            {reportEntries.map((reportinfo, index) => (
                                <tr>
                                    <td style={{ "width": "auto" }}>{reportinfo.day}</td>
                                    <td style={{ "width": "auto" }}>{reportinfo.client.client_name}</td>
                                    <td style={{ "width": "auto" }}>{reportinfo.project.name}</td>
                                    <td style={{ "width": "auto" }}>{!isEmpty(reportinfo.task.sprint) ?
                                                <div class="lastrongelsprint ">{reportinfo.task.sprint}</div>
                                                : '...' }</td>
                                    <td style={{ "width": "auto" }}>{reportinfo.task.name}</td>
                                    <td style={{ "width": "auto" }}>{reportinfo.user.first_name+' '+reportinfo.user.last_name}</td>
                                    <td style={{ "width": "auto" }}>
                                        <span class="d-flex align-items-center justify-content-start gap-2">
                                            <div>{(reportinfo.duration).slice(0, -3)}</div>
                                            {reportinfo.is_billable === 1 ?
                                            <img src="images/billed.png" width="20" height="20" alt="" />
                                            :
                                            <img src="images/nonbill.png" width="20" height="20" alt="" />
                                            }
                                        </span>
                                    </td>
                                </tr>
                            ))}



        <tr>
            <td colspan="6"><b>Total</b></td>
            <td><b>{reportData.totalHoursOfPage}</b></td>
        </tr>




        </tbody>
        : <tbody><tr>
        <td colspan="7">
        <div className="d-grid text-center py-3">
            <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
            <span> No Entries Available</span>
            </div>
           </td>
    </tr></tbody> }
        </table>  */}


                                </div>
                                {/* <div class="loadwrapper">
        <div class="clock"><div class="circle"></div></div>
    </div> */}
                                {/* <div class="my-4">
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">Next</a></li>
            </ul>
        </nav>
    </div> */}
                                {reportEntries.length > 0 ?
                                    <div class="paginationContent mt-3">
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={handlePageChange2} />

                                    </div>
                                    : ''}

                            </div>
                        </div>

                    </div>

                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    ...
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="modal fade" id="editday" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Filter</h5>
                                    <button type="button" id="closemodal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">Company</label>
                                        {/*<select class="form-select" aria-label="Default select example" id="companyFilter" onChange={handleCompanyFilterChange}>
                            <option value="" selected>All</option>
                            {companyFilterData.map((companyData, companyindex) => (
                                <option value={companyData.id}>{companyData.company_name}</option>
                            ))}
                        </select> */}
                                        <ReactSelect
                                            class="form-select"
                                            options={multiCompanyOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option
                                            }}
                                            onChange={handleCompanyFilterChange}
                                            allowSelectAll={true}
                                            value={optionCompanyFilterSelected}
                                            defaultValue={['1']}
                                            isDisabled={isManager}
                                        />
                                    </div>

                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">Client</label>
                                        <ReactSelect
                                            class="form-select"
                                            options={multiClientOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option
                                            }}
                                            onChange={handleClientFilterChange}
                                            allowSelectAll={true}
                                            value={optionClientFilterSelected}
                                            defaultValue={['1']}
                                        />

                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">Project</label>
                                        <ReactSelect
                                            class="form-select"
                                            options={multiProjectOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option
                                            }}
                                            onChange={handleProjectFilterChange}
                                            allowSelectAll={true}
                                            value={optionProjectFilterSelected}
                                            defaultValue={['1']}
                                        />

                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">Sprint</label>
                                        <ReactSelect
                                            class="form-select"
                                            options={multiSprintOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option
                                            }}
                                            onChange={handleSprintFilterChange}
                                            allowSelectAll={true}
                                            value={optionSprintFilterSelected}
                                            defaultValue={['1']}
                                        />
                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">Task</label>
                                        <ReactSelect
                                            class="form-select"
                                            options={multiTaskOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option
                                            }}
                                            onChange={handleTaskFilterChange}
                                            allowSelectAll={true}
                                            value={optionTaskFilterSelected}
                                            defaultValue={['1']}
                                        />
                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">Team</label>
                                        <ReactSelect
                                            class="form-select"
                                            options={multiUserOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option
                                            }}
                                            onChange={handleUserFilterChange}
                                            allowSelectAll={true}
                                            value={optionUserFilterSelected}
                                            defaultValue={['1']}
                                        />
                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">Billable/Nonbillable</label>
                                        <select class="form-select" id="billableFilter" aria-label="Default select example">
                                            <option value='' selected>All</option>
                                            <option value="1">Billable</option>
                                            <option value="0">Non-Billable</option>
                                        </select>
                                    </div>
                                    {/*
                    <div class="form-group mb-2">
                        <input className="form-input" type="checkbox" id="archived_items" />
                        <label for="recipient-name" class="col-form-label">Include archieved items in filters</label>
                    </div> */}
                                </div>
                                <div class="modal-footer">
                                    <div class="d-flex justify-content-between w-100">
                                        <div className="d-flex gap-2">
                                            <button type="button" onClick={runReport} class="btn btn-primary">Run Report</button>
                                            <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            {showLoader === 1 ?
                                <div class="loadwrapper">
                                    <div class="clock"><div class="circle"></div></div>
                                </div>
                                : ''}
                        </div>
                    </div>
                </section>

            </main>

        </>
    )
};

DetailedReports.propTypes = {};

DetailedReports.defaultProps = {};

export default DetailedReports;
