//import logo from './logo.svg';
import './App.css';
//import './css/bootstrap.css';
//import './css/style-guide.css';
//import './assets/custome.css';
import React,{history,useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
import Layout from './layouts/layout';
//ReactGA.initialize('UA-233788033-1');

//import ReactGA from "react-ga4";
//ReactGA.initialize("G-65HRLDB8E7");

function App() {
  //const TRACKING_ID = "UA-233788033-1"; // OUR_TRACKING_ID
  //ReactGA.initialize(TRACKING_ID);

  useEffect(() => 
  {
    
  },[]);  
  return (
   
    <Layout /> 
 
  );
}

export default App;
