import React, { useEffect, useState, useMemo } from "react";
import services from "../../services/services";
import $ from 'jquery';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Pagination from "../../utility/Pagination/Pagination";
import { getCurrentDateInEST, convertDateStringToEST, convertDateStringToGMT, timezone } from "../../timezone/dateUtils";
import { useTable, useSortBy } from 'react-table';
import ReactExport from "react-data-export";
import {
    format,
    startOfDay,
    startOfWeek,
    startOfMonth,
    endOfWeek,
    endOfMonth,
    addDays,
    addWeeks,
    addMonths,
    subDays,
    subWeeks,
    subMonths,
} from "date-fns";

const getCurrentUTCDate = () => {
    const now = new Date();
    return new Date(new Date().toISOString().split('T')[0]);
};

const Notifications = () => {
    const [date, setDate] = useState(getCurrentDateInEST());
    const pageNum = 1;
    const ITEMS_PER_PAGE = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState(1);
    const [displayDate, setDisplayDate] = useState('');

    const [totalPages, setTotalPages] = useState(1); // Set this from the API response

    // States for pagination
    const [clientCurrentPage, setClientCurrentPage] = useState(1);
    const [clientTotalPages, setClientTotalPages] = useState(1);

    const [projectCurrentPage, setProjectCurrentPage] = useState(1);
    const [projectTotalPages, setProjectTotalPages] = useState(1);

    const [taskCurrentPage, setTaskCurrentPage] = useState(1);
    const [taskTotalPages, setTaskTotalPages] = useState(1);

    const pageSize = 3;

    ////////////////////////////////////////////////////////////////////////////////
    const [rangeType, setRangeType] = useState("Month"); // Default to Day
    const [currentDate, setCurrentDate] = useState(new Date(new Date().toISOString().split('T')[0]));
    const [weekStartDatePost, setWeekStartDatePost] = useState(format(currentDate, "yyyy-MM-dd"));
    const [weekEndDatePost, setWeekEndDatePost] = useState(format(currentDate, "yyyy-MM-dd"));

    const [clientWiseStatsUser, setClientWiseStatsUser] = useState({ clients: [] });
    const [projectWiseStatsUser, setProjectWiseStatsUser] = useState({ projects: [] });
    const [taskWiseStatsUser, setTaskWiseStatsUser] = useState({ tasks: [] });

    const [allClientsTotalHours, setAllClientsTotalHours] = useState('');
    const [allClientsBillableHours, setAllClientsBillableHours] = useState('');
    const [allClientsNonBillableHours, setAllClientsNonBillableHours] = useState('');

    const [isCheckedClient, setIsCheckedClient] = useState(false);
    const [isCheckedProject, setIsCheckedProject] = useState(false);
    const [isCheckedTask, setIsCheckedTask] = useState(false);
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
    const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());

    const [projects, setProjects] = useState([]);
    const [clients, setClients] = useState([]);

    const [selectedProject, setSelectedProject] = useState('');
    const [selectedClient, setSelectedClient] = useState('');

    const [showLoader, setShowLoader] = useState(0);
    const [notifications, setNotifications] = useState([]);
const [notificationLength, setNotificationLength] = useState([]);

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleClientChange = (event) => {
        setSelectedClient(event.target.value);
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = () => {

        const requestJson = { user_id: useridval };

        // Fetching notifications from the service
        services.getAllNotifications(requestJson).then((res) => {
            console.log("#####################");
           // setNotificationLength(res.data.length)


            if (res.data && Array.isArray(res.data)) {
                setNotificationLength(res.data.length); // Ensure res.data is an array before accessing length
            } else {
                setNotificationLength(0); // Handle the case where res.data is undefined or not an array
            }

            console.log(JSON.stringify(res));
            if (res.type === 'success') {
                setNotifications(res.data); // Set fetched notifications
            }
        });
    }

    const clientColumns = useMemo(
        () => [
            { Header: "Client Name", accessor: "clientName", sortType: 'basic', },
            { Header: "Billable Hours", accessor: "clientWiseBillableHours", sortType: 'basic', },
            { Header: "Total Hours", accessor: "clientWiseTotalHours", sortType: 'basic', },
        ],
        []
    );

    const projectColumns = useMemo(
        () => [
            { Header: "Project Name", accessor: "projectName", sortType: 'basic', },
            { Header: "Client Name", accessor: "clientName", sortType: 'basic', },
            { Header: "Billable Hours", accessor: "projectWiseBillableHours", sortType: 'basic', },
            { Header: "Total Hours", accessor: "projectWiseTotalHours", sortType: 'basic', },
        ],
        []
    );

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    //#############################
    // Pagination Handlers for Projects
    const handleProjectPrevPage = () => {
        if (projectCurrentPage > 1) {
            setProjectCurrentPage(projectCurrentPage - 1);
        }
    };

    const handleProjectNextPage = () => {
        if (projectCurrentPage < projectTotalPages) {
            setProjectCurrentPage(projectCurrentPage + 1);
        }
    };

    const renderProjectPagination = () => {
        const pageNumbers = generatePageNumbers(projectCurrentPage, projectTotalPages);

        return (
            <div className="pagination-controls justify-content-end">
                <button onClick={handleProjectPrevPage} disabled={projectCurrentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setProjectCurrentPage(page)}
                        className={page === projectCurrentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleProjectNextPage} disabled={projectCurrentPage === projectTotalPages}>
                    Next »
                </button>
            </div>
        );
    };

    // Helper function to generate page numbers
    const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrent = Math.floor(maxPageButtons / 2);
            const maxPagesAfterCurrent = Math.ceil(maxPageButtons / 2) - 1;

            if (currentPage <= maxPagesBeforeCurrent) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrent;
                endPage = currentPage + maxPagesAfterCurrent;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };


    const handleNotificationClose = (id) => {
        // Update the notification flag when a notification is closed
        var accessToken2 = sessionStorage.getItem("accessToken");
        fetch(`${apiEndPoint}/notifications/update-flag`, {
          method: 'POST',
          headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken2}`
             },
          body: JSON.stringify({ user_id: useridval, notification_id: id })
        })
          .then(response => response.json())
          .then(result => {
            if (result.type === 'success') {
              // Remove the closed notification from the list
              setNotifications(notifications.filter(notification => notification.notification_id !== id));
              fetchNotifications();
            } else {
              console.error('Failed to update notification flag');
            }
          })
          .catch(error => console.error('Error updating notification flag:', error));
      };

    //############################
    //////////////////////////////////////////
    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">
                            Notifications
                        </h2>

                        <div class="tab-primary my-4">

                            <div class="tab-content ">
                                <div id="menu2" class="container tab-pane fade show active px-0">

                                    <div class="tbl-container mt-0 mb-3">

                        <table class="table mb-0 ntf">

                            {notifications.length > 0 ?
                            <tbody>
                                {notifications.map((notificationData, notificationIndex) => (
                                <tr>
                                    <td class="text-center d-flex justify-content-between">
                                        <b className="mb-0 text-start">{notificationData.action_description_show}</b>
                                        <small>one min ago</small>
                                    </td>

                                    <td style={{"width": "auto", "text-align": "center"}}>
                                            <a href="#" onClick={() => handleNotificationClose(notificationData.notification_id)}>
                                                <div className="img-notify">
                                                    <img src="images/close.svg" width={20} height={20} alt="Close" />
                                                </div>
                                            </a>
                                    </td>
                                </tr>
                                ))}

                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colspan="4">

                                    <div className="d-grid text-center py-3">
                                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                        <span>No Notifications Available</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                        </table>
                                    </div>

                                    {renderProjectPagination()}
                                </div>
                            </div>
                        </div>
                        {showLoader === 1 ?
                        <div class="loadwrapper">
                            <div class="clock"><div class="circle"></div></div>
                        </div>
                        : '' }
                    </div>
                </section>

            </main>
        </>
    )
};

Notifications.propTypes = {};

Notifications.defaultProps = {};

export default Notifications;