import React, { useEffect, useState, useMemo, useRef  } from "react";
import services from "../../services/services";
import $ from 'jquery';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Pagination from "../../utility/Pagination/Pagination";
import { getCurrentDateInEST, convertDateStringToEST, convertDateStringToGMT, timezone } from "../../timezone/dateUtils";
import { useTable, useSortBy } from 'react-table';
import ReactExport from "react-data-export";
import {
    format,
    startOfDay,
    startOfWeek,
    startOfMonth,
    endOfWeek,
    endOfMonth,
    addDays,
    addWeeks,
    addMonths,
    subDays,
    subWeeks,
    subMonths,
} from "date-fns";

const getCurrentUTCDate = () => {
    const now = new Date();
    return new Date(new Date().toISOString().split('T')[0]);
};

const Reports = () => {
    const printRefClients = useRef();
    const printRefProjects = useRef();
    const printRefTasks = useRef();
    const [date, setDate] = useState(getCurrentDateInEST());
    const pageNum = 1;
    const ITEMS_PER_PAGE = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState(1);
    const [displayDate, setDisplayDate] = useState('');

    const [totalPages, setTotalPages] = useState(1); // Set this from the API response

    // States for pagination
    const [clientCurrentPage, setClientCurrentPage] = useState(1);
    const [clientTotalPages, setClientTotalPages] = useState(1);

    const [projectCurrentPage, setProjectCurrentPage] = useState(1);
    const [projectTotalPages, setProjectTotalPages] = useState(1);

    const [taskCurrentPage, setTaskCurrentPage] = useState(1);
    const [taskTotalPages, setTaskTotalPages] = useState(1);

    const pageSize = 10;

    ////////////////////////////////////////////////////////////////////////////////
    const [rangeType, setRangeType] = useState("Month"); // Default to Day
    //const [currentDate, setCurrentDate] = useState(new Date(new Date().toISOString().split('T')[0]));
    const [currentDate, setCurrentDate] = useState(getCurrentDateInEST());
    const [weekStartDatePost, setWeekStartDatePost] = useState(format(currentDate, "yyyy-MM-dd"));
    const [weekEndDatePost, setWeekEndDatePost] = useState(format(currentDate, "yyyy-MM-dd"));

    const [clientWiseStatsUser, setClientWiseStatsUser] = useState({ clients: [] });
    const [projectWiseStatsUser, setProjectWiseStatsUser] = useState({ projects: [] });
    const [taskWiseStatsUser, setTaskWiseStatsUser] = useState({ tasks: [] });


    const [clientWiseStatsUserFull, setClientWiseStatsUserFull] = useState({ clients: [] });
    const [projectWiseStatsUserFull, setProjectWiseStatsUserFull] = useState({ projects: [] });
    const [taskWiseStatsUserFull, setTaskWiseStatsUserFull] = useState({ tasks: [] });

    const [allClientsTotalHours, setAllClientsTotalHours] = useState('');
    const [allClientsBillableHours, setAllClientsBillableHours] = useState('');
    const [allClientsNonBillableHours, setAllClientsNonBillableHours] = useState('');

    const [isCheckedClient, setIsCheckedClient] = useState(false);
    const [isCheckedProject, setIsCheckedProject] = useState(false);
    const [isCheckedTask, setIsCheckedTask] = useState(false);
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));

    const [projects, setProjects] = useState([]);
    const [clients, setClients] = useState([]);

    const [selectedProject, setSelectedProject] = useState('');
    const [selectedClient, setSelectedClient] = useState('');

    const [showLoader, setShowLoader] = useState(0);

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleClientChange = (event) => {
        setSelectedClient(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        switch (id) {
            case 'clientcheckbox':
                setIsCheckedClient(checked);
                break;
            case 'projectcheckbox':
                setIsCheckedProject(checked);
                break;
            case 'taskcheckbox':
                setIsCheckedTask(checked);
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        adjustDateToRangeType(rangeType);
    }, [rangeType]);


    useEffect(() => {
        fetchProjectsAndClients();
    }, []);

    const adjustDateToRangeType = (type) => {
        let newStartDate, newEndDate;
        // setCurrentDate(new Date(new Date().toISOString().split('T')[0]));
        // let currentDate_t = new Date(new Date().toISOString().split('T')[0]);

        setCurrentDate(getCurrentDateInEST());
        let currentDate_t = getCurrentDateInEST();

        switch (type) {
            case "Day":
                newStartDate = newEndDate = currentDate_t; // Keep the current date as is
                break;
            case "Week":
                newStartDate = startOfWeek(currentDate_t, { weekStartsOn: 1 }); // Start of the current week
                newEndDate = endOfWeek(currentDate_t, { weekStartsOn: 1 }); // End of the current week
                break;
            case "Month":
                newStartDate = startOfMonth(currentDate_t); // Start of the current month
                newEndDate = endOfMonth(currentDate_t); // End of the current month
                break;
            default:
                newStartDate = newEndDate = currentDate_t;
        }
        //alert(currentDate)
        console.log("currentDate--->" + currentDate_t);
        console.log("newStartDate--->" + newStartDate);
        setCurrentDate(newStartDate);
        setWeekStartDatePost(format(newStartDate, "yyyy-MM-dd"));
        setWeekEndDatePost(format(newEndDate, "yyyy-MM-dd"));
    };

    const adjustDateRange = (direction) => {
        let newStartDate, newEndDate;

        switch (rangeType) {
            case "Day":
                newStartDate = newEndDate = direction === "prev" ? subDays(currentDate, 1) : addDays(currentDate, 1);
                break;
            case "Week":
                const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 }); // Monday as the first day
                newStartDate = direction === "prev" ? subWeeks(startOfCurrentWeek, 1) : addWeeks(startOfCurrentWeek, 1);
                newEndDate = endOfWeek(newStartDate, { weekStartsOn: 1 });
                break;
            case "Month":
                const startOfCurrentMonth = startOfMonth(currentDate);
                newStartDate = direction === "prev" ? subMonths(startOfCurrentMonth, 1) : addMonths(startOfCurrentMonth, 1);
                newEndDate = endOfMonth(newStartDate);
                break;
            default:
                newStartDate = newEndDate = currentDate;
        }
        setCurrentDate(newStartDate);
        setWeekStartDatePost(format(newStartDate, "yyyy-MM-dd"));
        setWeekEndDatePost(format(newEndDate, "yyyy-MM-dd"));

    };

    const resetToCurrent = () => {
        //setCurrentDate(getCurrentUTCDate()); // Reset to current UTC date
        //const now = getCurrentUTCDate();

        const now = new Date(new Date().toISOString().split('T')[0]);
        //setCurrentDate(now);
        setCurrentDate(new Date(new Date().toISOString().split('T')[0]));
        setWeekStartDatePost(format(now, "yyyy-MM-dd"));
        setWeekEndDatePost(format(now, "yyyy-MM-dd"));
    };

    const formatDateRange = () => {
        const start = format(currentDate, "EEEE, dd MMM yyyy"); // Start date formatted
        let end;
        switch (rangeType) {
            case "Day":
                end = start; // Same day
                break;
            case "Week":
                end = format(endOfWeek(currentDate, { weekStartsOn: 1 }), "dd MMM, yyyy"); // End of week
                break;
            case "Month":
                end = format(endOfMonth(currentDate), "dd MMM, yyyy"); // End of month
                break;
            default:
                end = start;
        }
        if (rangeType == "Day") {
            return `${start}`;
        } else {
           // return `${start} - ${end}`;
            return `${format(currentDate, "dd MMM")} - ${end}`;
        }

    };
    useEffect(() => {
        fetchReportsUser();
    }, [weekStartDatePost, weekEndDatePost, isCheckedClient,clientCurrentPage]);

    useEffect(() => {
        fetchReportsProject();
    }, [weekStartDatePost, weekEndDatePost, isCheckedProject, selectedClient,projectCurrentPage]);

    useEffect(() => {
        fetchReportsTask();
    }, [weekStartDatePost, weekEndDatePost, isCheckedTask, selectedProject, taskCurrentPage]);

    const fetchProjectsAndClients = async () => {
        const requestJson = {
            userId: useridval,
        };
        const result1_fetchProjectByUser = await services.fetchProjectByUser(requestJson);

        const projects = result1_fetchProjectByUser.map(project => ({
            id: project.id,
            name: project.name
        }));
        // Check if project.client is defined before accessing its properties
        const clients = result1_fetchProjectByUser
            .filter(project => project.client) // Filter out projects without a client
            .map(project => ({
                id: project.client.id,
                client_name: project.client.client_name
            }));

        // Remove duplicate clients if necessary
        const uniqueClients = Array.from(new Map(clients.map(client => [client.id, client])).values());

        setProjects(projects);
        setClients(uniqueClients);

    }

    const fetchReportsUser = async () => {
        setShowLoader(1);
        let clientProjectStatus = "";
        if (isCheckedClient) {
            clientProjectStatus = "1";
        }
        const requestJsonClient = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: clientProjectStatus,
            "pageNumber": clientCurrentPage,
            "pageSize": pageSize
        };

        const result1_fetchClientWiseStatsUser = await services.fetchClientWiseStatsUser(requestJsonClient);
        setClientWiseStatsUser(result1_fetchClientWiseStatsUser);


        const requestJsonClientFull = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: clientProjectStatus,
            //"pageNumber": clientCurrentPage,
            //"pageSize": pageSize
        };

        const result1_fetchClientWiseStatsUserFull = await services.fetchClientWiseStatsUser(requestJsonClientFull);
        setClientWiseStatsUserFull(result1_fetchClientWiseStatsUserFull);


        setClientTotalPages(result1_fetchClientWiseStatsUser.totalNumberOfPages);

        setAllClientsTotalHours(result1_fetchClientWiseStatsUser.allClientsTotalHours);
        setAllClientsBillableHours(result1_fetchClientWiseStatsUser.allClientsBillableHours);
        setAllClientsNonBillableHours(result1_fetchClientWiseStatsUser.allClientsNonBillableHours);
        setShowLoader(0);
    };

    const fetchReportsProject = async () => {
        setShowLoader(1);
        let projectProjectStatus = "";
        if (isCheckedProject) {
            projectProjectStatus = "1";
        }
        const requestJsonProject = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: projectProjectStatus,
            clientId: selectedClient,
            "pageNumber": projectCurrentPage,
            "pageSize": pageSize
        };

        const result1_fetchProjectWiseStatsUser = await services.fetchProjectWiseStatsUser(requestJsonProject);
        setProjectWiseStatsUser(result1_fetchProjectWiseStatsUser);


        const requestJsonProjectFull = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: projectProjectStatus,
            clientId: selectedClient,
            //"pageNumber": projectCurrentPage,
            //"pageSize": pageSize
        };

        const result1_fetchProjectWiseStatsUserFull = await services.fetchProjectWiseStatsUser(requestJsonProjectFull);
        setProjectWiseStatsUserFull(result1_fetchProjectWiseStatsUserFull);

        setProjectTotalPages(result1_fetchProjectWiseStatsUser.totalNumberOfPages);
        setShowLoader(0);
    };

    const fetchReportsTask = async () => {
        setShowLoader(1);
        let taskProjectStatus = "";
        if (isCheckedTask) {
            taskProjectStatus = "1";
        }

        const requestJsonTask = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: taskProjectStatus,
            projectId: selectedProject,
            "pageNumber": taskCurrentPage,
            "pageSize": pageSize
        };
        const result1_fetchTaskWiseStatsUser = await services.fetchTaskWiseStatsUser(requestJsonTask);
        setTaskWiseStatsUser(result1_fetchTaskWiseStatsUser);


        const requestJsonTaskFull = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: taskProjectStatus,
            projectId: selectedProject,
           // "pageNumber": taskCurrentPage,
           // "pageSize": pageSize
        };
        const result1_fetchTaskWiseStatsUserFull = await services.fetchTaskWiseStatsUser(requestJsonTaskFull);
        setTaskWiseStatsUserFull(result1_fetchTaskWiseStatsUserFull);


        setTaskTotalPages(result1_fetchTaskWiseStatsUser.totalNumberOfPages);
        setShowLoader(0);
    };

    const renderTable = (columns, data = [], totalHours, totalBillableHours, colspan) => {
        const tableInstance = useTable({ columns, data }, useSortBy);

        return (
            <table {...tableInstance.getTableProps()} className="table mb-0 lastchild-color">
                {/* Table header */}
                <thead className="bg-lightblue">
                    {tableInstance.headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '↕️'}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {/* Table body */}
                <tbody {...tableInstance.getTableBodyProps()}>
                    {tableInstance.rows.map(row => {
                        tableInstance.prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                    <tr>
                        <td colSpan={colspan}>Total: </td>
                        <td>{totalBillableHours}</td>
                        <td>{totalHours}</td>
                    </tr>
                </tbody>
            </table>
        );
    };


    const clientColumns = useMemo(
        () => [
            { Header: "Client", accessor: "clientName", sortType: 'basic', },
            { Header: "Billable Hrs", accessor: "clientWiseBillableHours", sortType: 'basic', },
            { Header: "Total Hrs", accessor: "clientWiseTotalHours", sortType: 'basic', },
        ],
        []
    );

    const projectColumns = useMemo(
        () => [
            { Header: "Project", accessor: "projectName", sortType: 'basic', },
            { Header: "Client", accessor: "clientName", sortType: 'basic', },
            { Header: "Billable Hrs", accessor: "projectWiseBillableHours", sortType: 'basic', },
            { Header: "Total Hrs", accessor: "projectWiseTotalHours", sortType: 'basic', },
        ],
        []
    );

    const taskColumns = useMemo(
        () => [
            { Header: "Task", accessor: "taskName", sortType: 'basic', },
            { Header: "Project", accessor: "projectName", sortType: 'basic', },
            { Header: "Client", accessor: "clientName", sortType: 'basic', },
            { Header: "Billable Hrs", accessor: "billableHours", sortType: 'basic', },
            { Header: "Total Hrs", accessor: "totalHours", sortType: 'basic', },
        ],
        []
    );

    /////////////////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////


    const Pagination = () => {
        const pageNumbers = generatePageNumbers();

        return (
            <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setCurrentPage(page)}
                        className={page === currentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next »
                </button>
            </div>
        );
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };




    //#############################
    // Pagination Handlers for Clients
    const handleClientPrevPage = () => {
        if (clientCurrentPage > 1) {
            setClientCurrentPage(clientCurrentPage - 1);
        }
    };

    const handleClientNextPage = () => {
        if (clientCurrentPage < clientTotalPages) {
            setClientCurrentPage(clientCurrentPage + 1);
        }
    };

    // Pagination Handlers for Projects
    const handleProjectPrevPage = () => {
        if (projectCurrentPage > 1) {
            setProjectCurrentPage(projectCurrentPage - 1);
        }
    };

    const handleProjectNextPage = () => {
        if (projectCurrentPage < projectTotalPages) {
            setProjectCurrentPage(projectCurrentPage + 1);
        }
    };

    // Pagination Handlers for Tasks
    const handleTaskPrevPage = () => {
        if (taskCurrentPage > 1) {
            setTaskCurrentPage(taskCurrentPage - 1);
        }
    };

    const handleTaskNextPage = () => {
        if (taskCurrentPage < taskTotalPages) {
            setTaskCurrentPage(taskCurrentPage + 1);
        }
    };


     // Example of rendering paginations independently
     const renderClientPagination = () => {
        const pageNumbers = generatePageNumbers(clientCurrentPage, clientTotalPages);

        return (
            <div className="pagination-controls justify-content-end">
                <button onClick={handleClientPrevPage} disabled={clientCurrentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setClientCurrentPage(page)}
                        className={page === clientCurrentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleClientNextPage} disabled={clientCurrentPage === clientTotalPages}>
                    Next »
                </button>
            </div>
        );
    };

    const renderProjectPagination = () => {
        const pageNumbers = generatePageNumbers(projectCurrentPage, projectTotalPages);

        return (
            <div className="pagination-controls justify-content-end">
                <button onClick={handleProjectPrevPage} disabled={projectCurrentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setProjectCurrentPage(page)}
                        className={page === projectCurrentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleProjectNextPage} disabled={projectCurrentPage === projectTotalPages}>
                    Next »
                </button>
            </div>
        );
    };

    const renderTaskPagination = () => {
        const pageNumbers = generatePageNumbers(taskCurrentPage, taskTotalPages);

        return (
            <div className="pagination-controls justify-content-end">
                <button onClick={handleTaskPrevPage} disabled={taskCurrentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setTaskCurrentPage(page)}
                        className={page === taskCurrentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleTaskNextPage} disabled={taskCurrentPage === taskTotalPages}>
                    Next »
                </button>
            </div>
        );
    };

    // Helper function to generate page numbers
    const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrent = Math.floor(maxPageButtons / 2);
            const maxPagesAfterCurrent = Math.ceil(maxPageButtons / 2) - 1;

            if (currentPage <= maxPagesBeforeCurrent) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrent;
                endPage = currentPage + maxPagesAfterCurrent;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };




    //############################
    //////////////////////////////////////////


    ////########### print @@@@@@@@@@@@@@@@@@@@@@@@@@
    //const renderClientTable = (columns, data = []) => {
    const renderClientTable = (columns, data = [], totalHours, totalBillableHours, colspan) => {
        const tableInstance = useTable({ columns, data }, useSortBy);
        return (
          <table {...tableInstance.getTableProps()} className="table mb-0 lastchild-color">
            <thead className="bg-lightblue">
              {tableInstance.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '↕️'}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...tableInstance.getTableBodyProps()}>
              {tableInstance.rows.map((row) => {
                tableInstance.prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>

                );
              })}
               <tr>
                        <td colSpan={colspan}>Total: </td>
                        <td>{totalBillableHours}</td>
                        <td>{totalHours}</td>
                    </tr>
            </tbody>
          </table>
        );
      };

      const handlePrintClients = () => {
        const contentToPrint = printRefClients.current;
        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Client Report</title>
              <style>
                /* Add custom styles for print view */
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #000; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body>
              ${contentToPrint.innerHTML}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };

      const handlePrintProjects = () => {
        const contentToPrint = printRefProjects.current;
        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Project Report</title>
              <style>
                /* Add custom styles for print view */
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #000; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body>
              ${contentToPrint.innerHTML}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };

      const handlePrintTasks = () => {
        const contentToPrint = printRefTasks.current;
        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Tasks Report</title>
              <style>
                /* Add custom styles for print view */
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #000; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body>
              ${contentToPrint.innerHTML}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };


    /////////////////////////////////////
    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">
                            Reports
                        </h2>

                        <div class="tab-content-bg-primary position-relative">

                            <div class="d-flex justify-content-between ">
                                <div class="d-flex">
                                    <div class="prev-after-wrap">
                                        <a onClick={() => adjustDateRange("prev")}> <span> <img src="images/left.png" alt="" /></span></a>
                                        <a onClick={() => adjustDateRange("next")}><span> <img src="images/right.png" alt="" /></span></a>
                                    </div>
                                    <div class="daytitlewrap ">
                                        <span class="d-flex">
                                            <img class="icon" src="images/cal-icon.png" alt="" />
                                            <div class="d-grid align-items-center">
                                                <h2>{formatDateRange()}</h2>
                                                {/* <a href="#"><img src="images/returnArrow.png" style="margin-right: 4px;" alt="">Return to Today</a> */}

                                            </div>

                                        </span></div>
                                </div>
                                <div class="dayweekswitch">
                                    <div class="d-flex justify-content-end align-items-center">

                                        <select class="form-select" value={rangeType} onChange={(e) => setRangeType(e.target.value)} style={{ "max-width": "200px" }}>
                                            <option value="Day">Day</option>
                                            <option value="Week">Week</option>
                                            <option value="Month">Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr/>

                            <div class="my-3"></div>
                            <div class="d-flex justify-content-end">
                                <div class="billnonbill d-flex gap-4 align-items-center me-5">
                                    <div class="item d-grid">
                                        <small> Total hours</small>
                                        <strong class="mainbluetxt ">{allClientsTotalHours}</strong>
                                    </div>
                                    <span>|</span>
                                    <div class="item d-grid">
                                        <small class="d-flex gap-1 align-items-center"> <img src="images/billed.png" width="20" alt="" /> Billable</small>
                                        <strong class="mainbluetxt ">{allClientsBillableHours}</strong>
                                    </div>
                                    <span>|</span>
                                    <div class="item d-grid">
                                        <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg" width="20" alt="" />
                                            Non-Billable</small>
                                        <strong class="mainbluetxt ">{allClientsNonBillableHours}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-primary my-4">

                            <ul class="nav nav-tabs" role="tablist">

                                <li class="nav-item">
                                    <a class="nav-link bg-none" data-bs-toggle="tab" href="#menu1">Clients</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link active bg-none" data-bs-toggle="tab" href="#menu2">Projects </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link bg-none" data-bs-toggle="tab" href="#menu3">Tasks </a>
                                </li>

                            </ul>

                            <div class="tab-content ">

                                <div id="menu1" class="container tab-pane fade  px-0">
                                    <div class="d-flex justify-content-between align-items-center py-2">
                                    <span class="d-flex align-items-center gap-2">

                                            </span>
                                        <div class="d-flex justify-content-end align-items-center" style={{ "width": "320px" }}>
                                            <button class="btn btn-primary" onClick={handlePrintClients}>Print Clients</button>
                                        </div>
                                    </div>

                                    <div class="tbl-container bdr mt-0 mb-3">
                                        {renderTable(clientColumns, clientWiseStatsUser.clients || [], clientWiseStatsUser.allClientsTotalHours, clientWiseStatsUser.allClientsBillableHours, 1)}
                                    </div>
                                    {renderClientPagination()}



                                </div>


                                {/* Hidden div for printing */}
                                <div style={{ display: 'none' }}>
                                    <div ref={printRefClients}>
                                        {renderClientTable(clientColumns, clientWiseStatsUserFull.clients || [], clientWiseStatsUserFull.allClientsTotalHours, clientWiseStatsUserFull.allClientsBillableHours, 1)}
                                    </div>
                                </div>


                                <div id="menu2" class="container tab-pane fade show active px-0">
                                    <div class="d-flex justify-content-between align-items-center py-2">
                                        <span class="d-flex align-items-center  gap-2">
                                            {/*<input
                                                className="form-check-input mt-0"
                                                type="checkbox"
                                                id="projectcheckbox"
                                                checked={isCheckedProject}
                                                onChange={handleCheckboxChange}
                                            />
                                            <small><strong>Active Projects Only</strong></small> */}
                                        </span>

                                        <div class="d-flex justify-content-end align-items-center gap-2" style={{ "width": "520px" }}>
                                            <span style={{ "min-width": "69px" }}>Filter by:</span>


                                            <select
                                                className="form-select me-2"
                                                id="clientSelect"
                                                style={{ maxWidth: '200px' }}
                                                value={selectedClient}
                                                onChange={handleClientChange}
                                            >
                                                <option value="">Select Client</option>
                                                {clients.map(client => (
                                                    <option key={client.id} value={client.id}>
                                                        {client.client_name}
                                                    </option>
                                                ))}
                                            </select>
                                        <button class="btn btn-primary" onClick={handlePrintProjects}>Print Projects</button>


                                        </div>


                                    </div>

                                    <div class="tbl-container bdr mt-0 mb-3">
                                        {renderTable(projectColumns, projectWiseStatsUser.projects, projectWiseStatsUser.allProjectsTotalHours, projectWiseStatsUser.allProjectsBillableHours, 2)}
                                    </div>

                                    {renderProjectPagination()}

                                </div>

                                  {/* Hidden div for printing */}
                                  <div style={{ display: 'none' }}>
                                    <div ref={printRefProjects}>
                                        {renderClientTable(projectColumns, projectWiseStatsUserFull.projects || [], projectWiseStatsUserFull.allProjectsTotalHours, projectWiseStatsUserFull.allProjectsBillableHours, 2)}
                                    </div>
                                </div>

                                <div id="menu3" class="container tab-pane fade px-0">
                                    <div class="d-flex justify-content-between align-items-center py-2">
                                        <span class="d-flex align-items-center gap-2">
                                            {/*s<input
                                                className="form-check-input mt-0"
                                                type="checkbox"
                                                id="taskcheckbox"
                                                checked={isCheckedTask}
                                                onChange={handleCheckboxChange}
                                            />
                                            <small><strong>Active Projects Only</strong></small> */}
                                        </span>

                                        <div class="d-flex justify-content-end align-items-center gap-2" style={{ "width": "520px" }}>
                                            <span style={{ "min-width": "69px" }}>Filter by:</span>




                                            <select
                                                className="form-select me-2"
                                                id="projectSelect"
                                                style={{ maxWidth: '200px' }}
                                                value={selectedProject}
                                                onChange={handleProjectChange}
                                            >
                                                <option value="">Select Project</option>
                                                {projects.map(project => (
                                                    <option key={project.id} value={project.id}>
                                                        {project.name}
                                                    </option>
                                                ))}
                                            </select>

                                            <button class="btn btn-primary" onClick={handlePrintTasks}>Print Tasks</button>
                                        </div>
                                    </div>

                                    <div class="tbl-container bdr mt-0 mb-3">
                                    {renderTable(taskColumns, taskWiseStatsUser.tasks, taskWiseStatsUser.allTasksTotalHours, taskWiseStatsUser.allTasksBillableHours, 3)}
                                    </div>

                                    <div class="">
                                    {renderTaskPagination()}

                                    </div>

                                     {/* Hidden div for printing */}
                                  <div style={{ display: 'none' }}>
                                    <div ref={printRefTasks}>
                                        {renderClientTable(taskColumns, taskWiseStatsUserFull.tasks, taskWiseStatsUserFull.allTasksTotalHours, taskWiseStatsUserFull.allTasksBillableHours, 3)}
                                    </div>
                                </div>


                                </div>
                            </div>
                        </div>
                        {showLoader === 1 ?
                        <div class="loadwrapper">
                            <div class="clock"><div class="circle"></div></div>
                        </div>
                        : '' }
                    </div>
                </section>

            </main>
        </>
    )
};

Reports.propTypes = {};

Reports.defaultProps = {};

export default Reports;