import React from "react";



const Footer = () => {

  return (
    <>
      <footer>

        <div className="container">

        <div class="row m-auto">
           <div className="d-flex justify-content-between align-items-center pt-2 pb-2"> <span>© 2024 Nexa Systems, Inc. All Rights Reserved. </span>
         <a href="#">Terms & Conditions</a></div>
        </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
