import React, { useEffect, useState } from "react";
import services from "../../services/services";
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import TimesheetHeader from "./timesheet-header";
import $ from 'jquery';
import { useTable, useSortBy } from 'react-table';

const UnsubmittedTimesheet = () => {
    //const useridval = 4;
    const [unsubmittedTimesheetList, setUnsubmittedTimesheetList] = useState([]);
    var loggedUserId = sessionStorage.getItem('userIdval');
    const [useridval, setuseridval] = useState(parseInt(loggedUserId));
    const [projectCurrentPage, setProjectCurrentPage] = useState(1);
    const [projectTotalPages, setProjectTotalPages] = useState(1);
    const pageSize = 10;
    const [showLoader, setShowLoader] = useState(0);

    useEffect(() => {
        //console.log('here');
        var timeSheetStatus1 = 'Unsubmitted';
        var timeSheetStatus2 = 'Rejected';
        var timeSheetStatus3 = 'Approval Withdrawn';
        getTimesheetEntriesUsubmitted(timeSheetStatus1, timeSheetStatus2, timeSheetStatus3);

        var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));
    }, [projectCurrentPage]);

    function getTimesheetEntriesUsubmitted(timeSheetStatus1, timeSheetStatus2, timeSheetStatus3) {
        setShowLoader(1);
        const requestObject =
        {
            "userIds": [useridval],
            "timeSheetStatuses": [timeSheetStatus1, timeSheetStatus2, timeSheetStatus3],
            "pageNumber": projectCurrentPage,
            "pageSize": pageSize
        }
        //console.log(requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer " + accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getTimeEntriesByStatus(requestObject).then((res) => {
            //console.log(res);
            setUnsubmittedTimesheetList(res.data);
            setProjectTotalPages(res.totalNumberOfPages);
            setShowLoader(0);
        });
    }

    const getDateRange = (weekstart, weekend) => {
        var weekStartobj = new DateObject(weekstart);
        var weekEndobj = new DateObject(weekend);
        return weekStartobj.format("DD MMM YYYY") + ' - ' + weekEndobj.format("DD MMM YYYY");
    }

    const getTotalTimeUnsubmitted = (timesheetinfoindex) => {
        for (var i = 0; i < unsubmittedTimesheetList.length; i++) {
            var userinfodata = unsubmittedTimesheetList[timesheetinfoindex].users;
            //console.log(userinfodata);
            for (var j = 0; j < userinfodata.length; j++) {
                if (userinfodata[j].userId == useridval) {
                    var workedHours = userinfodata[j].workedHours;
                    return workedHours;
                    // const [hours, minutes] = workedHours.split('.').map(Number);

                    // // Pad single-digit hours and minutes with leading zero
                    // const paddedHours = hours.toString().padStart(2, '0');
                    // const paddedMinutes = minutes.toString().padStart(2, '0');
                    // return `${paddedHours}:${paddedMinutes}`;
                }
            }
        }
    }

    const setTimesheetDate = (weekstartdate, weekenddate) => {
        window.location.href = '/timesheet?req=' + weekstartdate + '&endreq=' + weekenddate + '&view=week&status=unsubmitted';
    }



    const [unsubmittedCheckboxValues, setUnsubmittedCheckboxValues] = useState([]);
    const handleUnsubmittedListCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setUnsubmittedCheckboxValues(prevUnsubmittedCheckedValues =>
            checked
                ? [...prevUnsubmittedCheckedValues, value]
                : prevUnsubmittedCheckedValues.filter(val => val !== value)
        );
    }

    const [userUnsubmittedListValidation, setUserUnsubmittedListValidation] = useState(0);
    const submitTimesheetsforApproval = () => {
        if (unsubmittedCheckboxValues.length > 0) {
            setUserUnsubmittedListValidation(0);
            formatRequestandAction(unsubmittedCheckboxValues);
        }
        else {
            setUserUnsubmittedListValidation(1);
        }
    }

    const [userUnsubmittedListTimesValidation, setUserUnsubmittedListTimesValidation] = useState(0);
    const formatRequestandAction = (inputValues) => {
        setShowLoader(1);
        const result = [];

        inputValues.forEach(item => {
            const [day1, day2, timeIndex, userid] = item.split('_');

            // Find or create the group for the current day1 and day2
            let group = result.find(g => g.day1 === day1 && g.day2 === day2);

            var mytimeIndex = timeIndex;

            if (!group) {
                // If the group doesn't exist, create it
                group = {
                    day1,
                    day2,
                    timeIndex: '',
                    userid: []
                };
                result.push(group);
            }

            // Add the userid to the group's userid array if not already present
            if (!group.userid.includes(Number(userid))) {
                group.userid.push(Number(userid));
            }

            group.timeIndex = getTotalTimeUnsubmitted(mytimeIndex);
        });

        // Sort userid arrays within each group
        result.forEach(group => {
            group.userid.sort((a, b) => a - b);
        });

        // Function to validate if any timeIndex has the value "00:00"
const hasZeroTimeIndex = result.some(item => item.timeIndex === "00:00");


if (hasZeroTimeIndex) {
    setShowLoader(0);
    //console.log("A timeIndex with the value '00:00' exists.");
    setUserUnsubmittedListTimesValidation(1);
} else {
    setShowLoader(1);
    setUserUnsubmittedListTimesValidation(0);
        for (var i = 0; i < result.length; i++) {
                var myupdateentry = {
                    "userIds": [useridval],
                    "weekStartDate": result[i].day1,
                    "weekEndDate": result[i].day2,
                    "timeSheetStatus": "Submitted",
                    "reasonForRejection": "",
                    "approvedBy": "",
                    "rejectedBy": ""
                }
                

                var accessTokenIn = sessionStorage.getItem("accToken");
                // const myrequestOptions = {
                //     method: 'PUT',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': "Bearer " + accessTokenIn
                //     },
                //     body: JSON.stringify(myupdateentry)
                // };
                services.updateTimeSheetSatus(myupdateentry).then((res) => {
                    if (res.type == 'success') {
                        setTimeout(function () { //$('#closemodal').click();
                            var timeSheetStatus1 = 'Unsubmitted';
                            var timeSheetStatus2 = 'Rejected';
                            var timeSheetStatus3 = 'Approval Withdrawn';
                            getTimesheetEntriesUsubmitted(timeSheetStatus1, timeSheetStatus2, timeSheetStatus3);
                        }, 1000);
                    }
                });
            }
            setUnsubmittedCheckboxValues([]);
            $("#approvedsuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#approvedsuccessMessage").slideUp(500);
            });
            setShowLoader(0);
        }   
    }


    // Pagination Handlers for Projects
    const handleProjectPrevPage = () => {
        if (projectCurrentPage > 1) {
            setProjectCurrentPage(projectCurrentPage - 1);
        }
    };

    const handleProjectNextPage = () => {
        if (projectCurrentPage < projectTotalPages) {
            setProjectCurrentPage(projectCurrentPage + 1);
        }
    };

    const renderProjectPagination = () => {
        const pageNumbers = generatePageNumbers(projectCurrentPage, projectTotalPages);

        return (
            <div className="pagination-controls justify-content-end">
                <button onClick={handleProjectPrevPage} disabled={projectCurrentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setProjectCurrentPage(page)}
                        className={page === projectCurrentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleProjectNextPage} disabled={projectCurrentPage === projectTotalPages}>
                    Next »
                </button>
            </div>
        );
    };


     // Helper function to generate page numbers
     const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrent = Math.floor(maxPageButtons / 2);
            const maxPagesAfterCurrent = Math.ceil(maxPageButtons / 2) - 1;

            if (currentPage <= maxPagesBeforeCurrent) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrent;
                endPage = currentPage + maxPagesAfterCurrent;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    const columns = React.useMemo(() => [
        {
            Header: 'Week',
            accessor: 'week',  // Just a placeholder, we'll customize the Cell rendering
            width: 'auto',
            sortType: 'basic',  // Enable sorting for this column
            Cell: ({ row }) => (
                <div className="d-flex gap-2 align-items-center">
                    <input type="checkbox" class="form-check-input mx-2 " name="unsubmitted_timesheet" value={row.original.weekStartDate + '_' + row.original.weekEndDate+' _'+row.index} onChange={handleUnsubmittedListCheckboxChange} />

                    <a href="#" onClick={() => setTimesheetDate(row.original.weekStartDate, row.original.weekEndDate)}>
                        {getDateRange(row.original.weekStartDate, row.original.weekEndDate)}
                    </a>
                    {/* Add conditional rendering logic here */}
                {row.original.timeSheetStatuses === 'Unsubmitted' ? (
                    ''
                ) : row.original.timeSheetStatuses === 'Rejected' ? (
                    <a href="#" className="btn btn-danger scale85">
                        <img src="images/reject.svg" alt="" /> {row.original.timeSheetStatuses}
                    </a>
                ) : row.original.timeSheetStatuses === 'Approval Withdrawn' ? (
                    <a href="#" className="btn btn-danger scale85">
                        <img src="images/reject.svg" alt="" /> Withdrawn
                    </a>
                ) : (
                    ''
                )}
                </div>
            ),
        },
    
        {
            Header: 'Total Hours',
            accessor: 'totalHours',
            width: '150px',
            sortType: 'basic',  // Enable sorting for this column
            Cell: ({value,  row }) => {
                // Debug output
                //console.log('Row Index:', row.index);
                //console.log('Row Index:', row.original.users[0].workedHours);
                //console.log('Total Hours:', getTotalTimePendingForApproval(row.index));
        
                // Return the total hours or a fallback value
                //return <div>{getTotalTimePendingForApproval(row.index) || 'N/A'}</div>;
                return <div>{row.original.users[0].workedHours || 'N/A'}</div>;
            },
        },
        {
            Header: '',
            accessor: 'actions',
            width: '160px',
            sortType: 'basic',  // Enable sorting for this column
            Cell: ({ row }) => (
                <a href="#" onClick={() => setTimesheetDate(row.original.weekStartDate, row.original.weekEndDate)} className="btn btn-outline-primary scale85">
                    View Details
                </a>
            ),
        }
    ], []);

    const data = React.useMemo(() => unsubmittedTimesheetList, [unsubmittedTimesheetList]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data
        },
        useSortBy
    );

    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">Time Sheet</h2>
                        <div class="tab-primary">
                            <TimesheetHeader />

                            {/* Tab panes */}
                            <div class="tab-content ">
                                <div>
                                    {/*
<div class="d-flex justify-content-end gap-2 align-items-center my-2">
    <span>Sort by:</span>
    <select class="form-select" id="inputGroupSelect01" style={{"max-width": "200px"}}>
            <option selected="">Select Week</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
</div> */}
                                    <div class="tbl-container bdr mb-3">
                                        {/* <== overflow: hidden applied to parent */}
                                        <table {...getTableProps()} className="table mb-0">
                                    <thead className="bg-lightblue">
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        style={{
                                                            width: column.width,
                                                            position: 'relative',
            
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {column.render('Header')}
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                display: 'inline-block',
                                                                fontSize: '0.8em',
                                                                color: '#888',
                                                            }}
                                                        >
                                                            {column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : '↕️'}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.length > 0 ? (
                                            rows.map(row => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => (
                                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="6">
                                                <div className="d-grid text-center py-3">
                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                        <span> No Entries pending for approval</span>
                        </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
{/*
                                        <table class="table mb-0">
                                            <thead class="bg-lightblue">
                                                <tr>

                                                    <th scope="col" style={{ "width": "auto" }}>Week</th>
                                                    <th scope="col" style={{ "width": "150px" }}>Total Hours</th>
                                                    <th scope="col" style={{ "width": "160px" }}></th>
                                                </tr>
                                            </thead>
                                            {unsubmittedTimesheetList.length > 0 ?
                                                <tbody>
                                                    {unsubmittedTimesheetList.map((data, index) => (
                                                        <tr>
                                                            <td style={{ "width": "auto" }}>
                                                                <div class="d-flex gap-2 align-items-center">
                                                                    <input type="checkbox" class="form-check-input mx-2 " name="unsubmitted_timesheet" value={data.weekStartDate + '_' + data.weekEndDate+' _'+index} onChange={handleUnsubmittedListCheckboxChange} />
                                                                    <a href="#" onClick={() => setTimesheetDate(data.weekStartDate, data.weekEndDate)}>{getDateRange(data.weekStartDate, data.weekEndDate)}</a>

                                                                    {data.timeSheetStatuses == 'Unsubmitted' ? ''
                                                                        : data.timeSheetStatuses == 'Rejected' ?
                                                                            <a href="#" class="btn btn-danger scale85"> <img src="images/reject.svg" alt="" /> {data.timeSheetStatuses}</a>
                                                                            : data.timeSheetStatuses == 'Approval Withdrawn' ?
                                                                                <a href="#" class="btn btn-danger scale85"> <img src="images/reject.svg" alt="" /> {data.timeSheetStatuses == 'Approval Withdrawn' ? 'Withdrawn' : ''}</a>
                                                                                : ''
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td style={{ "width": "150px" }}>{getTotalTimeUnsubmitted(index)}</td>
                                                            <td style={{ "width": "160px" }}><a href="#" class="btn btn-outline-primary scale85" onClick={() => setTimesheetDate(data.weekStartDate, data.weekEndDate)}>View Details</a></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td colspan="3">

                                                            <div className="d-grid text-center py-3">
                                                                <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                                                <span>No Unsubmitted Entries</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table> */}

                                    </div>

                                    {renderProjectPagination()}
                                    {userUnsubmittedListValidation == 1 ?
                                        <div class="alert alert-danger" role="alert">Please select time Sheet(s) for submission</div>
                                        : ''}

                                    {userUnsubmittedListTimesValidation == 1 ?
                                        <div class="alert alert-danger" role="alert">A time sheet with a total time of 00:00 exists in your submission. Please review your selected time sheets before submitting them for approval.</div>
                                        : ''}


                                    <button class="btn btn-primary my-4" onClick={submitTimesheetsforApproval}>Submit for approval</button>

                                </div>

                            </div>
                        </div>

                    </div>
                    {showLoader === 1 ?
                    <div class="loadwrapper">
                        <div class="clock"><div class="circle"></div></div>
                    </div>
                    : '' }
                </section>

            </main>


        </>
    )
};

UnsubmittedTimesheet.propTypes = {};

UnsubmittedTimesheet.defaultProps = {};

export default UnsubmittedTimesheet;
