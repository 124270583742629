import React, { useEffect, useState, useRef } from "react";
import services from "../../services/services";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import AddTime from "../modals/add-time";
import TaskDescription from "../modals/task-description";
import ApprovedTimesheet from "./approved-timesheet";
import TimesheetHeader from "./timesheet-header";
import $ from 'jquery';
import { useForm } from "react-hook-form";

const ApprovedWeekListView = ({listday1, listday2}) => {
    const printTimeSheetWeekviewRef = useRef();
   const [date, setDate] = useState(new Date());
   const [showCalendar, setShowCalendar] = useState(false);
   const [requestDate, setRequestDate] = useState('');
   var showReturntoToday = 0;
   var todayDate = new Date();
   var weekdaytoday = todayDate.getDay();
   const [weekdayselected, setWeekdayselected] = useState(weekdaytoday);
   const [showTimeEtriesList, setShowTimeEtriesList] = useState([]);
   const [showingResultsForDate, setShowingResultsForDate] = useState(new Date());
   const [weekStartDate, setWeekStartDate] = useState('');
   const [weekEndDate, setWeekEndDate] = useState('');
   const [totalEntryTime, setTotalEntryTime] = useState('');
   const [totalBillableTime, setTotalBillableTime] = useState('');
   const [totalNonBillableTime, setTotalNonBillableTime] = useState('');
   const [totalStatus, setTotalStatus] = useState('');

   const [totalEntryTimeWeek, setTotalEntryTimeWeek] = useState('');
   const [totalBillableTimeWeek, setTotalBillableTimeWeek] = useState('');
   const [totalNonBillableTimeWeek, setTotalNonBillableTimeWeek] = useState('');
   const [totalStatusWeek, setTotalStatusWeek] = useState('');

   const [clickedButtonIndex, setClickedButtonIndex] = useState(null);
   //const useridval = 4;
   const [totalWeekTime, setTotalWeekTime] = useState('');
   const [dateWiseTimes, setDateWiseTimes] = useState([]);
   const [weekLimitDates, setWeekLimitDates] = useState('');
   const [weekLimitDatesFull, setWeekLimitDatesFull] = useState('');
   const [showReturntoWeek, setshowReturntoWeek] = useState(0);
   const [dayweektoggle, setDayWeekToggle] = useState('day');
   const [showWeekTimeEtriesList, setShowWeekTimeEtriesList] = useState([]);
   const [timesheetfreeze , setTimesheetFreeze] = useState(false);
   const [timesheetStatusHolidays, setTimesheetStatusHolidays] = useState([]);
   const [dayWekkIsChecked, setDayWekkIsChecked] = useState(false);
   const [useridval, setuseridval] = useState(0);
   const [selectedEntryResult, setSelectedEntryResult] = useState([]);
   const [selectedProject, setSelectedProject] = useState(null);
   const [selectedClient, setSelectedClient] = useState(null);
   const [selectedTask, setSelectedTask] = useState(null);
   const [selectedBillable, setSelectedBillable] = useState(null);
   const [loggedUser, setLoggedUser] = useState('');
   const [viewTimesheetDetailsStat, setViewTimesheetDetailsStat] = useState(0);

   const {
      register,
      formState: { errors },
      handleSubmit
  } = useForm({
      criteriaMode: "all",mode: "onBlur"
  });


  const handlePrintTimeSheetWeekview = () => {
    const contentToPrint = printTimeSheetWeekviewRef.current;
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Time Sheet</title>
          <link href="/css/bootstrap.min.css" rel="stylesheet">
            <link href="/css/style-guide.css" rel="stylesheet">
            <link href="/assets/custome.css" rel="stylesheet">
          <style>
            /* Add custom styles for print view */
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #000; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
            @media print {
            .no-print { display: none; }
          }
             .no-print { display: none !important; }
          </style>
        </head>
        <body>
          ${contentToPrint.innerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    //printWindow.close();
    // setTimeout(() => {
    //     printWindow.close();
    //   }, 1800000); // Adjust the delay as needed
  };

   const caliculateNextDay1 = (mydate, adddays) => {
      var mydate = new Date(mydate);
      // Subtract one day from the current date
      let nextday = new Date(mydate);
      nextday.setDate(mydate.getDate() + adddays);
      // Format the date as YYYY-MM-DD
      let year = nextday.getFullYear();
      let month = ('0' + (nextday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      let day = ('0' + nextday.getDate()).slice(-2);

      let nextdayDate = `${year}-${month}-${day}`;
      return nextdayDate;
   }

   const caliculateNextDay = (mydate, adddays) => {

    // Input date string
    //const mydate = "2024-08-03";
    //console.log(mydate);
    // Validate and parse the date string
    const dateParts = mydate.split('-');
    if (dateParts.length === 3) {
        const [year, month, day] = dateParts.map(Number);

        // Create a Date object in UTC
        const date = new Date(Date.UTC(year, month - 1, day));

        // Check if the Date object is valid
        if (!isNaN(date.getTime())) {
            // Add one day to the date
            date.setUTCDate(date.getUTCDate() + adddays);

            // Format the new date to YYYY-MM-DD
            const nextDay = date.toISOString().split('T')[0];
            return nextDay; // Outputs "2024-08-04"
        } else {
            //console.error("Invalid Date object created");
        }
    } else {
        //console.error("Invalid date string format");
    }



    /*
          //var mydate = String(mydate);
          var mydate1 = mydate;
          console.log(typeof(mydate));
          //var mydate = convertDateStringToEST(mydate);
          // Subtract one day from the current date

          let nextday = new Date(mydate);

          const [newyear, newmonth, newday] = mydate.split('-').map(Number);
          const newsetoutdate = new Date(Date.UTC(newyear, newmonth - 1, newday));
          // Format the date to YYYY-MM-DD (in UTC)
    const formattedDate = newsetoutdate.toISOString().split('T')[0];

          console.log(mydate+'*************'+adddays+'***********'+nextday+'-'+newsetoutdate+'-**'+formattedDate);
          nextday.setDate(nextday.getDate() + adddays);
          console.log(nextday);
          // Format the date as YYYY-MM-DD
          let year = nextday.getFullYear();
          let month = ('0' + (nextday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
          let day = ('0' + nextday.getDate()).slice(-2);

          let nextdayDate = `${year}-${month}-${day}`;
          console.log(nextdayDate);
          return nextdayDate; */
       }


   function getTimesheetEntriesForWeek(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDate, weekEndDate) {
      setClickedButtonIndex(requestdate);
      var datetolocal = new DateObject(requestdate);
      var storetolocal = datetolocal.format("dddd, DD MMM");
      localStorage.setItem('itemforDate', storetolocal);
      var projectstatus = '';

      services.getAllTimeSheetEntriesForWeek(clientId, projectId, projectstatus, weekTimeEntryId, userId, requestdate, weekStartDate, weekEndDate).then((res) => {
         //console.log(res.data.data);
         setTotalEntryTimeWeek(res.data.data.totalTotalTime);
         setTotalBillableTimeWeek(res.data.data.totalBillableTime);
         setTotalNonBillableTimeWeek(res.data.data.totalUnbillableTime);
         setTotalStatusWeek(res.data.data.consolidatedStatus);

            if(res.data.data.projects == null){
               setShowWeekTimeEtriesList([]);
            }
            else {
               var converteddata = transformObject(res.data);
               //console.log(converteddata);
               setShowWeekTimeEtriesList(converteddata.data.projects);
               setTimesheetStatusHolidays(res.data.data.holidays);
               setWeekStartDate(weekStartDate);
               setWeekEndDate(weekEndDate);
            }

      });
   }

   function transformObject(obj) {
      obj.data.projects.map(project => {
         const billableTimeEntries = [];
         const unbillableTimeEntries = [];

         project.tasks.forEach(task => {
            if (task.billableEntries.length > 0) {
               billableTimeEntries.push(
                  {
                     taskId: task.taskId,
                     taskName: task.taskName,
                     sprint: task.sprint,
                     taskBillableTime: task.taskBillableTime,
                     taskUnbillableTime: task.taskUnbillableTime,
                     taskTotalTime: task.taskTotalTime,
                     timeentries: task.billableEntries.map(entry => ({
                     id: entry.id,
                     day: entry.day,
                     duration: entry.duration,
                     is_billable: 1,
                     clientId: entry.clientId,
                     projectId: entry.projectId,
                     taskId: entry.taskId,
                     time_sheet_status: entry.time_sheet_status,
                     entry_created_from_day_or_week: entry.entry_created_from_day_or_week,
                     time_entry_description: entry.time_entry_description,
                     userId: entry.userId,
                     is_holiday: entry.is_holiday,
                     is_leave: entry.is_leave,
                     is_comp_off: entry.is_comp_off,
                     group_week_entry_sequence: entry.group_week_entry_sequence,
                     week_time_entry_id: entry.week_time_entry_id
                     }))
                  }
               );
            }
            if (task.unbillableEntries.length > 0) {
               unbillableTimeEntries.push({
                  taskId: task.taskId,
                  taskName: task.taskName,
                  sprint: task.sprint,
                  taskBillableTime: task.taskBillableTime,
                  taskUnbillableTime: task.taskUnbillableTime,
                  taskTotalTime: task.taskTotalTime,
                  timeentries: task.unbillableEntries.map(entry => ({
                     id: entry.id,
                     day: entry.day,
                     duration: entry.duration,
                     is_billable: 0,
                     clientId: entry.clientId,
                     projectId: entry.projectId,
                     taskId: entry.taskId,
                     time_sheet_status: entry.time_sheet_status,
                     entry_created_from_day_or_week: entry.entry_created_from_day_or_week,
                     time_entry_description: entry.time_entry_description,
                     userId: entry.userId,
                     is_holiday: entry.is_holiday,
                     is_leave: entry.is_leave,
                     is_comp_off: entry.is_comp_off,
                     group_week_entry_sequence: entry.group_week_entry_sequence,
                     week_time_entry_id: entry.week_time_entry_id
                  }))
              });
            }
         });
         project.billableTimeEntries=billableTimeEntries;
         project.unbillableTimeEntries=unbillableTimeEntries;
      });
      return obj;
  }

  function getFullWeekTimesInfo(useridval, mondayDate, sundayDate){
    const requestObject =
    {
       "week_start_date": mondayDate,
       "week_end_date": sundayDate,
       "user_id": useridval
    }
    caliculateWeekLimit(mondayDate, sundayDate);
    //console.log(requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    // const requestOptions = {
    //    method: 'POST',
    //    headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': "Bearer "+accessTokenIn
    //    },
    //    body: JSON.stringify(requestObject)
    // };
    services.getFullWeekTimesInfo(requestObject).then((res) => {
       if(res.data){
          setTotalWeekTime(res.data.total_duration);
          setDateWiseTimes(res.data.days);
       }
    });
 }

   useEffect(() => {
    var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));

$('#viewTimesheetDetails').hide();

        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);

    var listdates = sessionStorage.getItem('viewlistfor');
    var mydates = listdates.split('&');
        if(!isEmpty(mydates[0]) && !isEmpty(mydates[1])){

            //console.log(mydates[0]+' - '+ mydates[1])
            getTimesheetEntriesForWeek('', '', '', '', '', '', loggedUserId, '', mydates[0], mydates[1]);
            getFullWeekTimesInfo(loggedUserId, mydates[0], mydates[1]);
            getUserTimesheetEntriesWithTaskId('', '', '', '', '', '', loggedUserId, '', mydates[0], mydates[1]);
            getTimesheetComments(mydates[0], mydates[1], loggedUserId);
        }
   }, []);

   const [formattedDetails, setFormattedDetails] = useState([]);
   const [fullEntryResult, setFullEntryResult] = useState([]);
    function getUserTimesheetEntriesWithTaskId(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval) {
        services.getAllTimeSheetEntries(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval).then((res) => {
           if (res.data.type == 'success') {
                console.log(res.data.data);
              setFullEntryResult(res.data.data);
              const transformedData = transformData(res.data.data);

              setFormattedDetails(transformedData);
           }
        });
     }


     // Transform data
     const transformData = (input) => {
        return input
            .filter(entry => entry.duration !== "00:00:00") // Filter out entries with duration 00:00:00
            .reduce((acc, entry) => {
            const { day } = entry;
            let dayEntry = acc.find(d => d.day === day);

            if (!dayEntry) {
                dayEntry = { day, timeEntries: [] };
                acc.push(dayEntry);
            }

            dayEntry.timeEntries.push(entry);
            return acc;
            }, []);
      };

   const isEmpty = (value) => {
      return value == null || value === '';
   }

   const caliculateWeekLimit = (date1, date2) => {
      const todayOfWeek = todayDate.getDay(); // 0 (Sunday) to 6 (Saturday)
      const todaystartOfWeek = new Date(todayDate);
      const todayDifference = (todayOfWeek === 0 ? -6 : 1) - todayOfWeek; // Adjust to Monday
      todaystartOfWeek.setDate(date.getDate() + todayDifference);

      const thisweek = [];
      for (let i = 0; i < 7; i++) {
        const thisweekDate = new Date(todaystartOfWeek);
        thisweekDate.setDate(todaystartOfWeek.getDate() + i);
        thisweek.push(thisweekDate);
      }

      if(new Date(thisweek[0]).setHours(0, 0, 0, 0) == new Date(date1).setHours(0, 0, 0, 0)){
         setshowReturntoWeek(0);
      } else {
         setshowReturntoWeek(1);
      }

      var strtdate = new DateObject(date1);
      var dateweek1 = strtdate.format("DD MMM");

      var enddate = new DateObject(date2);
      var dateweek2 = enddate.format("DD MMM, YYYY");
      setWeekLimitDates(dateweek1 + ' - ' + dateweek2);

      setWeekLimitDatesFull(strtdate.format("DD MMM YYYY") + ' - ' + enddate.format("DD MMM YYYY"));
   }

   const handleWeekDatesDisplayFormat = (datetoformat) => {
      var displaydate = new DateObject(datetoformat);
      return displaydate.format("DD MMM");
   };


   const handleToDisplayWeekDescription = (id, day1, day2, projectname, clientname, taskname, billable) => {
      setSelectedProject(projectname);
      setSelectedClient(clientname);
      setSelectedTask(taskname);
      setSelectedBillable(billable);
      getMyTimesheetEntriesWithTaskId('', '', id, '', billable, '', useridval, '', day1, day2);
   }

   function getMyTimesheetEntriesWithTaskId(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval) {
      services.getAllTimeSheetEntries(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval).then((res) => {
         if (res.data.type == 'success') {
            setSelectedEntryResult(res.data.data);
         }
      });
   }

   const getProjectTotalTime = (weekday, projectid) => {
      var mylist = showWeekTimeEtriesList;
      for (var i = 0; i < mylist.length; i++) {
         if (mylist[i].projectId == projectid) {
            var durationval = '...';
           // console.log(mylist[i].dayWiseTotalTime);
            var myentriestime = mylist[i].dayWiseTotalTime;
            for (var j = 0; j < myentriestime.length; j++) {

               // console.log(myentriestime[j].day+' == '+weekday);
               if (myentriestime[j].day == weekday) {
                //console.log(myentriestime[j].total_duration);
                    if(!isEmpty(myentriestime[j].total_duration)){
                        if(parseInt(myentriestime[j].total_duration) > 0){
                            durationval = (myentriestime[j].total_duration).slice(0, -3);
                        } else {
                            //return '...';
                        }
                    } else {
                        //return '...';
                    }
               } else {
                //return '...';
               }

            }
            return durationval;
         }
      }
   }

   const getProjectTotalTime1 = (weekday, addrow, projectid) => {
//console.log(weekday);
        // Create a Date object from the string, interpreted as UTC
        var date = new Date(weekday + 'T00:00:00Z'); // Appending 'T00:00:00Z' ensures it's treated as UTC

        // Add one day
        date.setUTCDate(date.getUTCDate() + addrow);

        // Function to format date as YYYY-MM-DD
        function formatDate(date) {
            var year = date.getUTCFullYear();
            var month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Months are zero-based
            var day = ('0' + date.getUTCDate()).slice(-2);
            return `${year}-${month}-${day}`;
        }

        // Get the new date and format it
        var newDateStr = formatDate(date);
        //console.log(newDateStr);
         return getProjectTotalTime(newDateStr, projectid);
   }

   const getTimeEntryDateDuration = (entrybillable, weekday, projectid, taskid) => {
      for (var i = 0; i < showWeekTimeEtriesList.length; i++) {
        if (showWeekTimeEtriesList[i].projectId == projectid) {
            if(entrybillable == '1'){
               var timeentriesinfo = showWeekTimeEtriesList[i].billableTimeEntries;
            } else {
               var timeentriesinfo = showWeekTimeEtriesList[i].unbillableTimeEntries;
            }
           if(timeentriesinfo){
            var durationentryval = '...';
            for (var l = 0; l < timeentriesinfo.length; l++) {
               if(timeentriesinfo[l].taskId == taskid){
                  var mytimeentries = timeentriesinfo[l].timeentries;
                  for(var t=0; t< mytimeentries.length; t++){
                     if (new Date(mytimeentries[t].day).setHours(0, 0, 0, 0) == new Date(weekday).setHours(0, 0, 0, 0)) {
                        //console.log(mytimeentries[t].duration);
                        if(!isEmpty(mytimeentries[t].duration)){
                            if(parseInt(mytimeentries[t].duration) > 0){
                                durationentryval = (mytimeentries[t].duration).slice(0, -3);
                            } else {
                                //return '...';
                            }
                        } else {
                            //return '...';
                        }
                     } else {
                        //return '...';
                     }
                  }
               }
            }
            return durationentryval;
         }
         }
      }
   }

   const hideDetails = () => {
    setViewTimesheetDetailsStat(0);
 }

 const showDetails = () => {
    setViewTimesheetDetailsStat(1);
 }

 const handleDetailedInfoDatesDisplayFormat = (datetoformat) => {
    var displaydate = new DateObject(datetoformat);
    return displaydate.format("DD MMM YYYY");
};

const handleCommentDatesDisplayFormat = (datetoformat) => {
    var displaydate = new DateObject(datetoformat);
    return displaydate.format("MMM DD, YYYY, HH:MM");
};

const [commentsData, setCommentsData] = useState([]);
    function getTimesheetComments(weekStartDateval, weekEndDateval, usertimesheetval) {
        services.getTimesheetComments(weekStartDateval, weekEndDateval, usertimesheetval).then((res) => {
            console.log(res.data);
           if (res.data.type == 'success') {
                if(res.data.data != null)
                    setCommentsData(res.data.data);
           }
        });
     }


   return (
      <>
          <main>


<section>
   <div class="container">
      <h2 class="mainHeadline">Time Sheet</h2>
      <div class="tab-primary">
         <TimesheetHeader />

         {/* Tab panes */}
         <div class="tab-content ">

                        <div id="listview" >
                           <div class="row">
                        <div class="col-lg-12">
                            <div class="tab-content w-100" ref={printTimeSheetWeekviewRef}>
                                <div class="tab-pane container-fluid active px-0" id="menu1">
                                    <div class="tab-content-bg-primary">

                                        <div class="daytitlewrap mb-3">
                                            <span class="d-flex">

                                                <div class="d-grid">
                                                    <a href="/approved-list" className="mb-1 no-print">
                                                        <img class="no-print" src="images/left.png"
                                                            style={{ marginRight:"6px"}}
                                                            alt=""/>Back to List Page</a>
                                                    <h2>Time Sheet for Week: {weekLimitDates}</h2>


                                                </div>
                                                </span>
                                        </div>
                                        <div class="billnonbill d-flex gap-4 align-items-center">
                                            <div class="item d-grid">
                                                <small> Total Hours</small>
                                                <strong class="mainbluetxt">{totalEntryTimeWeek.slice(0,-3)}</strong>
                                            </div>
                                            <span>|</span>
                                            <div class="item d-grid">
                                                <small class="d-flex gap-1 align-items-center"> <img
                                                        src="images/billed.png" width="20" alt=""/> Billable</small>
                                                <strong class="mainbluetxt">{totalBillableTimeWeek.slice(0, -3)}</strong>
                                            </div>                                            <span>|</span>
                                            <div class="item d-grid">
                                                <small class="d-flex gap-1 align-items-center"> <img
                                                        src="images/dollarred.svg" width="20" alt=""/>
                                                    Non-Billable</small>
                                                <strong class="mainbluetxt">{totalNonBillableTimeWeek.slice(0, -3)}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mainbox">
                                        <div class="weekcal">
                                            <div></div>
                                            <div class="weekcalmain">
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">MON</b>
                                                    <small>{handleWeekDatesDisplayFormat(weekStartDate)}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">tue</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 1))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">wed</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 2))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">thu</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 3))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">fri</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 4))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">sat</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 5))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">sun</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(weekStartDate, 6))}</small>
                                                </span>
                                            </div>
                                            <div></div>
                                        </div>

                                        {showWeekTimeEtriesList.length > 0 ?
                                        <div class="accordion" id="accordionPanelsStayOpenExample ">
                                            {showWeekTimeEtriesList.map((data, index) => (
                                            <div class="accordion-item">
                                                <h2 class="accordion-header weekafterem">
                                                    <button class="accordion-button px-0 position-relative weekviewlist" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse`+index} aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                        <div class="weekcallist w-100">
                                                            <div class="px-3 py-2 d-flex align-items-center gap-2 justify-content-between">
                                                                <div class="list ps-2">
                                                                    <div class="client d-flex gap-2 align-items-center">
                                                                        <b>{data.projectName} </b>
                                                                        <span class="bluetext ">({data.clientName})</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*
                                                            <span class="weekcalmain">
                                                                <b class="d-flex justify-content-center">{getProjectTotalTime1(weekStartDate, 0, data.projectId)}</b>
                                                                <b class="d-flex justify-content-center">{getProjectTotalTime1(weekStartDate, 1, data.projectId)}</b>
                                                                <b class="d-flex justify-content-center">{getProjectTotalTime1(weekStartDate, 2, data.projectId)} </b>
                                                                <b class="d-flex justify-content-center">{getProjectTotalTime1(weekStartDate, 3, data.projectId)} </b>
                                                                <b class="d-flex justify-content-center">{getProjectTotalTime1(weekStartDate, 4, data.projectId)} </b>
                                                                <b class="d-flex justify-content-center">{getProjectTotalTime1(weekStartDate, 5, data.projectId)} </b>
                                                                <b class="d-flex justify-content-center">{getProjectTotalTime1(weekStartDate, 6, data.projectId)} </b>

                                                            </span> */}
                                                            <div>
                                                                <div> <b class="mainbluetxt ">{(data.projectTotalTime).slice(0, -3)}</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id={`panelsStayOpen-collapse`+index} class="accordion-collapse collapse show" data-bs-parent="#accordionPanelsStayOpenExample">
                                                    <div class="accordion-body px-0 position-relative py-1">
                                                    {data.billableTimeEntries.length > 0 ?
                                                   <div>
                                                   {data.billableTimeEntries.map((billabletasks, billabletaskindex) => (
                                                   <div>
                                                   {billabletasks.timeentries.length > 0 ?
                                                    <div class="innerlist ">
                                                        <div class="weekcallist w-100 position-relative">
                                                            <div class="px-3 d-flex align-items-center gap-2">
                                                                <div class="list ps-2">

                                                                    <div class="projName d-flex gap-2 align-items-center my-2 ">
                                                                        <div class="props" style={{ "margin-top": "0px" }}><img src="images/billed.png" width="20" alt="" /></div>
                                                                            <strong>{billabletasks.taskName}</strong>
                                                                            <a href="#" data-bs-toggle="modal" data-bs-target="#taskdes" onClick={() => handleToDisplayWeekDescription(billabletasks.taskId, weekStartDate, caliculateNextDay(weekStartDate, 6), data.projectName, data.clientName, billabletasks.taskName, '1')}>More</a>
                                                                            {!isEmpty(billabletasks.sprint) ?
                                                                  <div class="lastrongelsprint ">{billabletasks.sprint}</div> : '' }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <span class="weekcalmain">
                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', weekStartDate, data.projectId, billabletasks.taskId)}</b>
                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 1), data.projectId, billabletasks.taskId)}</b>
                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 2), data.projectId, billabletasks.taskId)}</b>
                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 3), data.projectId, billabletasks.taskId)}</b>
                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 4), data.projectId, billabletasks.taskId)}</b>
                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 5), data.projectId, billabletasks.taskId)}</b>
                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(weekStartDate, 6), data.projectId, billabletasks.taskId)}</b>

                                                                </span>
                                                                <div>
                                                                    <div> <b class="mainbluetxt ">{(billabletasks.taskBillableTime).slice(0, -3)}</b>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                     : '' }
                                                     </div>
                                                     ))}
                                                     </div>
                                                  :  '' }

                                                  {data.unbillableTimeEntries.length > 0 ?
                                                     <div>
                                                     {data.unbillableTimeEntries.map((unbillabletasks, unbillabletaskindex) => (
                                                     <div>
                                                       {unbillabletasks.timeentries.length > 0 ?
 <div class="innerlist ">
 <div class="weekcallist w-100 position-relative">
     <div class="px-3 d-flex align-items-center gap-2">

         <div class="list ps-2">

             <div class="projName d-flex gap-2 align-items-center my-2 ">
                 <div class="props" style={{"margin-top":"0px"}}>
                 {/* <img src="images/nonbill.png" alt="" /> */}
                 <img src="images/dollarred.svg" width="20" alt="" />
                 </div>
                 <strong>{unbillabletasks.taskName}</strong>
                 <a href="#" data-bs-toggle="modal" data-bs-target="#taskdes" onClick={() => handleToDisplayWeekDescription(unbillabletasks.taskId, weekStartDate, caliculateNextDay(weekStartDate, 6), data.projectName, data.clientName, unbillabletasks.taskName, '0')}>More</a>
                 {!isEmpty(unbillabletasks.sprint) ?
                    <div class="lastrongelsprint ">{unbillabletasks.sprint}</div> : '' }
             </div>

         </div>
     </div>
     <span class="weekcalmain">
         <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', weekStartDate, data.projectId, unbillabletasks.taskId)}</b>
         <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 1), data.projectId, unbillabletasks.taskId)}</b>
         <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 2), data.projectId, unbillabletasks.taskId)}</b>
         <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 3), data.projectId, unbillabletasks.taskId)}</b>
         <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 4), data.projectId, unbillabletasks.taskId)}</b>
         <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 5), data.projectId, unbillabletasks.taskId)}</b>
         <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(weekStartDate, 6), data.projectId, unbillabletasks.taskId)}</b>

     </span>
     <div>
         <div> <b class="mainbluetxt ">{(unbillabletasks.taskUnbillableTime).slice(0, -3)}</b>
         </div>

     </div>
 </div>

</div>
 : '' }
 </div>
 ))}
 </div>
:  '' }
                                                    </div>
                                                </div>
                                            </div>
                                             ))}

                                        </div>
                                        : ''}
                                       {/* <div class="lhcwraper border-bottom">

                                            <div class="lhsbody my-2">
                                                <div class="weekcallist">
                                                    <span class="ps-4">Leave</span>
                                                    <span class="checkbox-list align-items-center">
                                                        <span class="d-flex justify-content-center"> <input
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDisabled"/></span>
                                                    <span class="d-flex justify-content-center"><input checked
                                                                disabled="" class="form-check-input" type="checkbox"
                                                                value="" id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input checked
                                                                disabled="" class="form-check-input" type="checkbox"
                                                                value="" id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input checked
                                                                disabled="" class="form-check-input" type="checkbox"
                                                                value="" id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    </span>
                                                    <b>00:00</b>
                                                </div>
                                                <div class="weekcallist">
                                                    <span class="ps-4">Holiday</span>
                                                    <span class="checkbox-list align-items-center">
                                                        <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input checked
                                                                disabled class="form-check-input" type="checkbox"
                                                                value="" id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    </span>
                                                    <b>00:00</b>
                                                </div>
                                                <div class="weekcallist">
                                                    <span class="ps-4">Comp-off</span>
                                                    <span class="checkbox-list align-items-center">
                                                        <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input disabled
                                                                class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"/></span>
                                                    <span class="d-flex justify-content-center"><input checked
                                                                disabled class="form-check-input" type="checkbox"
                                                                value="" id="flexCheckDefault"/></span>
                                                    </span>
                                                    <b>00:00</b>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>



                                </div>

                                <div class="dayweektotal  ">
                                    <div class="weekcallist">
                                        <span class="p-4">
                                            <b class="d-flex justify-content-center">Total Hours</b>
                                        </span>
                                        <span class="weekcalmain">
                                            <b class="d-flex justify-content-center">
                                                {dateWiseTimes.map((data, index) => (
                                                            data.day === weekStartDate ? (data.total_duration).slice(0, -3) : ''
                                                         ))}</b>
                                            <b class="d-flex justify-content-center">
                                            {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 1) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                            </b>
                                            <b class="d-flex justify-content-center">
                                            {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 2) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                            </b>
                                            <b class="d-flex justify-content-center">
                                            {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 3) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                            </b>
                                            <b class="d-flex justify-content-center">
                                            {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 4) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                            </b>
                                            <b class="d-flex justify-content-center">
                                            {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 5) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                            </b>
                                            <b class="d-flex justify-content-center">
                                            {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(weekStartDate, 6) ? (data.total_duration).slice(0, -3) : ''
                                                         ))}
                                            </b>

                                        </span>
                                        <span><b class="bluetext">{totalWeekTime.slice(0, -3)}</b></span>
                                    </div>
                                </div>
                                {commentsData.length > 0 ?
                    <div class="commentwrap">
                        <b class="mb-2 d-block">Comments</b>
                        {commentsData.map((commentdata, commentindex) => (
                            <div class="commentItem my-2">
                                <div class="d-flex gap-2">
                                    <strong class="name">{commentdata.user.first_name+' '+commentdata.user.last_name}</strong>
                                    <span class="px-1">|</span>
                                    <span class="reason">{commentdata.comments}</span>
                                </div>
                                <span>Reviewed : <span>{handleCommentDatesDisplayFormat(commentdata.created_time)}</span></span>
                            </div>
                        ))}


                    </div>
                    : ''}

                    {formattedDetails.length > 0 ?
                    <>

                    { viewTimesheetDetailsStat == 1 ?
                    <>
                    <div class="border-bottom w-100 my-2 py-4">
                        <button class="btn btn-outline-secondary" id="hideTimesheetDetails" onClick={hideDetails}>Hide Details</button>
                    </div>


                    <div class="use-detail-info border-bottom" id="timesheetDetails">
                        {/* <h3 class="my-3">{detailedViewUserFname}'s Timesheet Details</h3> */}
                        {formattedDetails.map((detailData, detailIndex) => (
                        <>
                        <div class="weekheader">
                            <span>{handleDetailedInfoDatesDisplayFormat(detailData.day)}</span>
                            {/* <b class="ms-2">{totalEntryTimeWeek.slice(0,-3)} Hrs</b> */}
                        </div>
                        {detailData.timeEntries.map((entryData, entryIndex) => (
                        <div class="d-flex justify-content-between border-bottom mb-2">
                            <div class="d-flex gap-2">
                                <div class="titlewrap py-2">
                                    <h3>{entryData.task.name}</h3>
                                    <h6>{entryData.project.name} ({entryData.client.client_name})</h6>
                                    <span>{entryData.time_entry_description}</span>
                                </div>
                            </div>
                            <div class="weekitemlistitem border-bottom-0">
                                <div class="hours scale65">
                                    <span class="hrtime">{(entryData.duration).slice(0, -3)} <div class="hrlabel">Hrs</div></span>

                                </div>
                            </div>
                        </div>
                        ))}
                        </>
                        ))}

                    </div>
                    </>
                    :
                    <div class="border-bottom w-100 my-2 py-4">
                        <button class="btn btn-primary no-print" id="viewTimesheetDetails"  onClick={showDetails}>View Details</button>
                        <button class="btn btn-primary no-print" onClick={handlePrintTimeSheetWeekview}>Print</button>
                    </div>
                    }
                    </> : <></> }


                            </div>
                        </div>
                    </div>
                        </div>

                        </div>
                     </div>

                  </div>
                  <TaskDescription project={selectedProject} client={selectedClient} task={selectedTask} isbillable={selectedBillable} result={selectedEntryResult} />

            </section>

         </main>

      </>
   )
};

ApprovedWeekListView.propTypes = {};

ApprovedWeekListView.defaultProps = {};

export default ApprovedWeekListView;
