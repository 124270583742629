import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import DateObject from "react-date-object";
//import services from "../../../services/services";
import services from '../../../services/services';
import ReactECharts from 'echarts-for-react';
import { useTable, useSortBy } from 'react-table';
import Notification from "../../common/notification";
import { format, addDays, subDays, startOfWeek, endOfWeek } from 'date-fns';


import { getCurrentDateInEST, convertDateStringToEST, convertDateStringToGMT, timezone } from '../../../timezone/dateUtils';
//import { getCurrentDateInEST, convertDateStringToEST, convertDateStringToGMT, timezone} from dateUtils
//import { getCurrentDateInEST, convertDateStringToEST, convertDateStringToGMT, timezone } from "../../timezone/dateUtils";

const AdminManagerDashboard = () => {
    const navigate = useNavigate();
    const history = useNavigate();
    const [loggedUser, setLoggedUser] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [date, setDate] = useState(getCurrentDateInEST());
    const [weekStartDate, setWeekStartDate] = useState('');
    const [weekEndDate, setWeekEndDate] = useState('');
    const [monthStartDate, setMonthStartDate] = useState('');
    const [monthEndDate, setMonthEndDate] = useState('');
    const [monthDisDate, setMonthDisDate] = useState('');
    const [showProjectList, setShowProjectList] = useState([]);
    const [resourceProject, setResourceProject] = useState("");
    const [weekStartDatePost, setWeekStartDatePost] = useState('');
    const [weekEndDatePost, setWeekEndDatePost] = useState('');
    const [weekLimitDates, setWeekLimitDates] = useState('');
    const [monthDateDis, setMonthDateDis] = useState('');
    const [showReturntoWeek, setshowReturntoWeek] = useState(0);
    const [dayWekkIsChecked, setDayWekkIsChecked] = useState(false);
    const [dayweektoggle, setDayWeekToggle] = useState('day');
    const [userWiseStatsData, setUserWiseStatsData] = useState([]);
    const [timeSheetStatsReport, setTimeSheetStatsReport] = useState([]);
    const [projectWiseStatsData, setProjectWiseStatsData] = useState([]);
    const [timeEntryStatsData, setTimeEntryStatsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState("");
    const [projectBillingType, setProjectBillingType] = useState('');
    const [showingResultsForDate, setShowingResultsForDate] = useState(getCurrentDateInEST());

    const [barChartOptions, setBarChartOptions] = useState(null);
    const [pieChartOptions, setPieChartOptions] = useState(null);
    const [doughnutChartOptions, setDoughnutChartOptions] = useState(null);


    const [billableWidth, setBillableWidth] = useState(0);
    const [nonBillableWidth, setNonBillableWidth] = useState(0);
    const [billableTasksWidth, setBillableTasksWidth] = useState(0);
    const [nonBillableTasksWidth, setNonBillableTasksWidth] = useState(0);
    const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
    const [userRole, setUserRole] = useState(sessionStorage.getItem('userRole'));
    const [notifications, setNotifications] = useState([]);

    const [projectCurrentPage, setProjectCurrentPage] = useState(1);
    const [projectTotalPages, setProjectTotalPages] = useState(1);

    const [taskCurrentPage, setTaskCurrentPage] = useState(1);
    const [taskTotalPages, setTaskTotalPages] = useState(1);

    const [projectWiseStatsTableData, setProjectWiseStatsTableData] = useState([]);
    const [userWiseStatsTableData, setUserWiseStatsTableData] = useState([]);

    const [companyFilterData, setCompaniesFilterData] = useState([]);

    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));

    const pageSize = 3;

    var showReturntoToday = 0;
    var todayDate = getCurrentDateInEST();

    //const [mycurrentDate, setMyCurrentDate] = useState(new Date(new Date().toISOString().split('T')[0]));
    const [mycurrentDate, setMyCurrentDate] = useState(getCurrentDateInEST());

    var weekStart = startOfWeek(mycurrentDate, { weekStartsOn: 1 }); // Assuming Monday as the first day of the week
  var weekEnd = endOfWeek(mycurrentDate, { weekStartsOn: 1 });

  const [showLoader, setShowLoader] = useState(0);

    const handleClickAddDayEntry = () => {
        //alert(format(currentDate, 'yyyy-MM-dd'));
        window.location.href = '/timesheet?req='+format(mycurrentDate, 'yyyy-MM-dd')+'&view=day';

    }

    const handleClickAddWeekEntry = () => {
        window.location.href = '/timesheet?req='+format(weekStart, 'yyyy-MM-dd')+'&endreq='+format(weekEnd, 'yyyy-MM-dd')+'&view=week';
    }

    const handleClickProjectId = (pid) => {

        window.location.href = '/projectDetail?id=' + pid;
    };

    const handleClickPending = () => {
        //navigate('/timesheet-management?tab=pending');
        //history.push('/timesheet-management?tab=pending');
        window.location.href = '/timesheet-management?tab=pending';
    };

    const handleClickApproved = () => {
        //navigate('/timesheet-management?tab=approved');
        //history.push('/timesheet-management?tab=approved');
        window.location.href = '/timesheet-management?tab=approved';

    };

    const handleClickUnsubmitted = () => {
        //navigate('/timesheet-management?tab=unsubmitted');
        //history.push('/timesheet-management?tab=unsubmitted');
        window.location.href = '/timesheet-management?tab=unsubmitted';
    };
    /*
    useEffect(() => {
        // Fetch notifications from the API when the component mounts
        var roleToSubmit = "manager_dashboard";
        if(userRole == "Manager"){
            roleToSubmit = "manager_dashboard";
        }else if(userRole == "Admin"){
            roleToSubmit = "admin_dashboard";
        }
        fetch(`${apiEndPoint}/notifications/get`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: useridval, page_filter: roleToSubmit })
        })
          .then(response => response.json())
          .then(data => {
            if (data.type === 'success') {
              setNotifications(data.data);
            } else {
              console.error('Failed to fetch notifications:', data.message);
            }
          })
          .catch(error => console.error('Error fetching notifications:', error));
      }, []);

      */

      const fetchNotifications = () => {
        let roleToSubmit = userRole === "Manager" ? "manager_dashboard" : "admin_dashboard";
        var accessToken = sessionStorage.getItem("accessToken");

        fetch(`${apiEndPoint}/notifications/get`, {
          method: 'POST',
          headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
          body: JSON.stringify({ user_id: useridval, page_filter: roleToSubmit })
        })
          .then(response => response.json())
          .then(data => {
            if (data.type === 'success') {
              setNotifications(data.data);  // Assuming setNotifications is defined in your component
            } else {
              console.error('Failed to fetch notifications:', data.message);
            }
          })
          .catch(error => console.error('Error fetching notifications:', error));
      };

      useEffect(() => {
        // Fetch notifications when the component mounts
        fetchNotifications();
      }, []); // Add any dependencies if required


      const handleNotificationClose = (id) => {
        // Update the notification flag when a notification is closed
        var accessToken2 = sessionStorage.getItem("accessToken");
        fetch(`${apiEndPoint}/notifications/update-flag`, {
          method: 'POST',
          headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken2}`
             },
          body: JSON.stringify({ user_id: useridval, notification_id: id })
        })
          .then(response => response.json())
          .then(result => {
            if (result.type === 'success') {
              // Remove the closed notification from the list
              setNotifications(notifications.filter(notification => notification.notification_id !== id));

              fetchNotifications();
            } else {
              console.error('Failed to update notification flag');
            }
          })
          .catch(error => console.error('Error updating notification flag:', error));
      };

    const chart_option = {
        title: {
            text: 'Monthly Sales',
        },
        tooltip: {},
        xAxis: {
            data: ['January', 'February', 'March', 'April', 'May', 'June'],
        },
        yAxis: {},
        series: [
            {
                name: 'Sales',
                type: 'bar',
                data: [12, 19, 3, 5, 2, 3],
            },
        ],
    };

    const handleProjectBillingTypeChange = (event) => {
        setProjectBillingType(event.target.value);
        console.log('Selected Project Billing Type:', event.target.value);
    };


    function handleProjectChange(event) {
        const selectedProjectId = event.target.value;
        //alert(selectedProjectId)
        setResourceProject(selectedProjectId);
        fetchStatsData(event.target.value);
        // setTimeout(() => {
        //     handleFetchData();
        // }, 1000); // 2000 milliseconds = 2 seconds
    }

    function getProjects(clientid, projstatus, companyval) {
        services.getAllProjects(clientid, projstatus, companyval).then((res) => {
            //console.log(res.data.data);
            if (res) {
                if (res.data.data !== null) {
                    setShowProjectList(res.data.data);
                }
            }
        });
    }

    // Handle change event
    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
        //handleDateChange();
        //getWeekDates(todayDate);
        if (event.target.value === "Week") {
            //handleDayWeekChange();
            getCurrentWeekDates(todayDate);
        } else if (event.target.value === "Month") {
            getCurrentMonthDates(todayDate);
        } else if (event.target.value === "Day") {
            currentDay()
        }
        setTimeout(() => {
            //@@// handleFetchData();
        }, 1000); // 2000 milliseconds = 2 seconds
    };

    useEffect(() => {
        if (weekStartDatePost && weekEndDatePost) {
            handleFetchData();
        }
        // handleFetchData();
    }, [weekStartDatePost, weekEndDatePost, resourceProject, projectBillingType]);

    // Helper function to convert "HH:MM" format to minutes
    const convertTimeToMinutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };


    // Utility function to convert "HH:MM" to minutes
    const convertToMinutes = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours * 60 + minutes;
    };
    const fetchStatsData = async (resourceProjectInput) => {
        /*
        setLoading(true);
        //alert(resourceProject);
        // Create the request JSON object
        const requestJson = {
            //weekEndDate: weekStartDatePost,//'2024-08-11',
            //weekStartDate: weekEndDatePost,//'2024-08-05',
            weekEndDate: weekEndDatePost,
            weekStartDate: weekStartDatePost,
            projectId: resourceProjectInput
        };

        try {
            const result = await services.fetchUserWiseStats(requestJson); // Calling the static method
            setUserWiseStatsData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
        */
    };

    useEffect(() => {
        handleFetchDataUserWiseDataTable()
    }, [taskCurrentPage,weekStartDatePost, weekEndDatePost, resourceProject, projectBillingType]);

    const handleFetchDataUserWiseDataTable = async () => {
        setShowLoader(1);

        if (resourceProject !== "") {
            var projectids_val = [resourceProject];
        } else {
            var projectids_val = [];
        }

        var statsreqCompanyVal = '';
        //console.log(sessionStorage.getItem('userRole'));
        if(sessionStorage.getItem('userRole') == 'Manager'){
            //managerid = sessionStorage.getItem('userIdval');
            statsreqCompanyVal = companyid;
        }

        if(sessionStorage.getItem('userRole') == 'Admin'){
            statsreqCompanyVal = adminCompanyid;
        }
        const requestJson = {
            //weekEndDate: weekStartDatePost,//'2024-08-11',
            //weekStartDate: weekEndDatePost,//'2024-08-05',
            weekEndDate: weekEndDatePost,
            weekStartDate: weekStartDatePost,
            projectIds: projectids_val,
            "pageSize":pageSize,
            "pageNumber":taskCurrentPage,
            "companyId": statsreqCompanyVal,
        };

        try {
            const result = await services.fetchUserWiseStats(requestJson); // Calling the static method
            //setUserWiseStatsData(result);
            setUserWiseStatsTableData(result);
            setTaskTotalPages(result.data.totalNumberOfPages)
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setShowLoader(0);
        }
    }

    useEffect(() => {
        handleFetchDataProjectDataTable()
    }, [projectCurrentPage,weekStartDatePost, weekEndDatePost, resourceProject, projectBillingType]);

    const handleFetchDataProjectDataTable = async () => {
        setShowLoader(1);
        var statsreqCompanyVal1 = '';
        //console.log(sessionStorage.getItem('userRole'));
        if(sessionStorage.getItem('userRole') == 'Manager'){
            //managerid = sessionStorage.getItem('userIdval');
            statsreqCompanyVal1 = companyid;
        }

        if(sessionStorage.getItem('userRole') == 'Admin'){
            statsreqCompanyVal1 = adminCompanyid;
        }

        const requestJson4 = {
            weekEndDate: weekEndDatePost,
            weekStartDate: weekStartDatePost,
            "pageSize":pageSize,
            "pageNumber":projectCurrentPage,
            "companyId":statsreqCompanyVal1,

        };

        try {
            var result4 = await services.fetchProjectWiseStats(requestJson4); // Calling the static method
            //setProjectWiseStatsData(result4);

            // Create a new variable to hold the sorted data
            const sortedProjectWiseStatsData = [...result4].sort((a, b) => {
                if (a.projectName.toLowerCase() < b.projectName.toLowerCase()) {
                    return -1;
                }
                if (a.projectName.toLowerCase() > b.projectName.toLowerCase()) {
                    return 1;
                }
                return 0;
            });

            // Set the sorted data in state
            //setProjectWiseStatsData(sortedProjectWiseStatsData);

            setProjectWiseStatsTableData(sortedProjectWiseStatsData);
            setProjectTotalPages(result4.data.totalNumberOfPages);

            console.log("@@@@@@@@@@@@@214");
            console.log(JSON.stringify(result4));
            //alert(result4.length);
            //################################################################
            // Log the full response to verify its structure
            console.log('Full response:', result4);

            // Check if result4 is already an array


            //#######################################################

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
            setShowLoader(0);
        }
    }

    const handleFetchData = async () => {
        setShowLoader(1);
        //alert(resourceProject);
        // Create the request JSON object

        if (resourceProject !== "") {
            var projectids_val = [resourceProject];
        } else {
            var projectids_val = [];
        }

        var statsreqCompanyVal1 = '';
        //console.log(sessionStorage.getItem('userRole'));
        if(sessionStorage.getItem('userRole') == 'Manager'){
            //managerid = sessionStorage.getItem('userIdval');
            statsreqCompanyVal1 = companyid;
        }

        if(sessionStorage.getItem('userRole') == 'Admin'){
            statsreqCompanyVal1 = adminCompanyid;
        }

        const requestJson = {
            //weekEndDate: weekStartDatePost,//'2024-08-11',
            //weekStartDate: weekEndDatePost,//'2024-08-05',
            weekEndDate: weekEndDatePost,
            weekStartDate: weekStartDatePost,
            projectIds: projectids_val,
            "companyId": statsreqCompanyVal1,

        };

        try {
            const result = await services.fetchUserWiseStats(requestJson); // Calling the static method
            setUserWiseStatsData(result);
           // setProjectWiseStatsTableData(result);
           // setTaskTotalPages(result.totalNumberOfPages)
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }

        var reqCompanyVal = '';
        //console.log(sessionStorage.getItem('userRole'));
        if(sessionStorage.getItem('userRole') == 'Manager'){
            //managerid = sessionStorage.getItem('userIdval');
            reqCompanyVal = companyid;
        }

        if(sessionStorage.getItem('userRole') == 'Admin'){
            reqCompanyVal = adminCompanyid;
        }



        const requestJson2 = {
            //weekEndDate: weekStartDatePost,//'2024-08-11',
            //weekStartDate: weekEndDatePost,//'2024-08-05',
            weekEndDate: weekEndDatePost,
            weekStartDate: weekStartDatePost,
            isBillable: projectBillingType,
            projectIds: [],
            userIds: [],
            companyId: reqCompanyVal
        };

        try {
            const result2 = await services.fetchTimeEntryStats(requestJson2); // Calling the static method
            //setUserWiseStatsData(result2);
            setTimeEntryStatsData(result2);

            // Calculate percentages
            //alert(result2.billableHours);
            //alert(result2.nonBillableHours)
            //const totalHours = result2.billableHours + result2.nonBillableHours;

            // Convert hours to minutes
            const billableHoursInMinutes = convertToMinutes(result2.billableHours);
            const nonBillableHoursInMinutes = convertToMinutes(result2.nonBillableHours);
            const totalHoursInMinutes = billableHoursInMinutes + nonBillableHoursInMinutes;


            const billableWidth = totalHoursInMinutes ? (billableHoursInMinutes / totalHoursInMinutes) * 100 : 0;
            const nonBillableWidth = totalHoursInMinutes ? (nonBillableHoursInMinutes / totalHoursInMinutes) * 100 : 0;

            const totalTasks = result2.billableTasks + result2.nonBillableTasks;
            const billableTasksWidth = totalTasks ? (result2.billableTasks / totalTasks) * 100 : 0;
            const nonBillableTasksWidth = totalTasks ? (result2.nonBillableTasks / totalTasks) * 100 : 0;

            setBillableWidth(billableWidth); // Assuming you have state for these values
            setNonBillableWidth(nonBillableWidth);
            setBillableTasksWidth(billableTasksWidth);
            setNonBillableTasksWidth(nonBillableTasksWidth);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }

        var statsreqCompanyVal = '';
        //console.log(sessionStorage.getItem('userRole'));
        if(sessionStorage.getItem('userRole') == 'Manager'){
            //managerid = sessionStorage.getItem('userIdval');
            statsreqCompanyVal = companyid;
        }

        if(sessionStorage.getItem('userRole') == 'Admin'){
            statsreqCompanyVal = adminCompanyid;
        }

        const requestJson3 = {
            weekEndDate: weekEndDatePost,
            weekStartDate: weekStartDatePost,
            "companyId": statsreqCompanyVal
        };

        try {
            const result3 = await services.fetchTimeSheetStatusReport(requestJson3); // Calling the static method
            //setUserWiseStatsData(result2);
            setTimeSheetStatsReport(result3);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }

        var statsreqCompanyVal1 = '';
        //console.log(sessionStorage.getItem('userRole'));
        if(sessionStorage.getItem('userRole') == 'Manager'){
            //managerid = sessionStorage.getItem('userIdval');
            statsreqCompanyVal1 = companyid;
        }

        if(sessionStorage.getItem('userRole') == 'Admin'){
            statsreqCompanyVal1 = adminCompanyid;
        }

        const requestJson4 = {
            weekEndDate: weekEndDatePost,
            weekStartDate: weekStartDatePost,
            "companyId":statsreqCompanyVal1,

        };

        try {
            var result4 = await services.fetchProjectWiseStats(requestJson4); // Calling the static method
            //setProjectWiseStatsData(result4);

            // Create a new variable to hold the sorted data
            const sortedProjectWiseStatsData = [...result4].sort((a, b) => {
                if (a.projectName.toLowerCase() < b.projectName.toLowerCase()) {
                    return -1;
                }
                if (a.projectName.toLowerCase() > b.projectName.toLowerCase()) {
                    return 1;
                }
                return 0;
            });

            // Set the sorted data in state
            setProjectWiseStatsData(sortedProjectWiseStatsData);

            //setProjectWiseStatsTableData(sortedProjectWiseStatsData);
            //setProjectTotalPages(result4.totalNumberOfPages);

            console.log("@@@@@@@@@@@@@214");
            console.log(JSON.stringify(result4));
            //alert(result4.length);
            //################################################################
            // Log the full response to verify its structure
            console.log('Full response:', result4);

            // Check if result4 is already an array
            if (result4 && Array.isArray(result4) && result4.length > 0) {
                //alert('Data is an array');

                // Processing the data for charts
                const xAxisData = result4.map((item) => item.projectName);
                const yAxisData = result4.map((item) => convertToMinutes(item.totalHours));

                // const xAxisData = result4.map((item) => item.projectName);
                const billableHoursData = result4.map((item) => {
                    const [hours, minutes] = item.billableHours.split(':').map(Number);
                    return hours * 60 + minutes; // Convert billable hours to minutes
                });
                const nonBillableHoursData = result4.map((item) => {
                    const [hours, minutes] = item.nonBillableHours.split(':').map(Number);
                    return hours * 60 + minutes; // Convert non-billable hours to minutes
                });


                //const projectNames = result4.map(item => item.projectName);
                //const projectNames = result4.map(item => item.projectCode);
                const projectNames = result4.map(item => item.projectCode || item.projectName);
                const billableHours = result4.map(item => convertTimeToMinutes(item.billableHours));
                const nonBillableHours = result4.map(item => convertTimeToMinutes(item.nonBillableHours));


                console.log("billableHoursData")
                console.log(billableHoursData);
                console.log("nonBillableHoursData")
                console.log(nonBillableHoursData)
                console.log("yAxisData")
                console.log(yAxisData)
                const pieChartData = result4.map((item) => ({
                    name: item.projectName,
                    value: convertToMinutes(item.totalHours),
                    //value: item.totalHours,
                }));

                // Bar Chart Options



                const barChartOptions = {
                    title: {
                        text: '',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: (params) => {
                            let content = `<strong>${params[0].axisValue}</strong><br/>`;
                            params.forEach(item => {
                                const hours = Math.floor(item.value / 60);
                                const minutes = item.value % 60;
                                content += `${item.marker} ${item.seriesName}: ${hours}h ${minutes}m<br/>`;
                            });
                            return content;
                        }
                    },
                    legend: {
                        data: ['Billable Hours', 'Non-Billable Hours']
                    },
                    xAxis: {
                        type: 'category',
                        data: projectNames,
                    },
                    yAxis: {
                        type: 'value',
                        //name: 'Total Hours (in minutes)',
                        name: '',
                        axisLabel: {
                            formatter: (value) => {
                                const hours = Math.floor(value / 60);
                                const minutes = value % 60;
                                return `${hours}h ${minutes}m`;
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                width: 1, // Adjust the thickness of the horizontal lines
                                color: '#ccc' // Optionally change the color of the lines
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Billable Hours',
                            type: 'bar',
                            stack: 'total',
                            data: billableHours,
                            itemStyle: {
                                color: '#00449d' // Color for billable hours
                            }
                        },
                        {
                            name: 'Non-Billable Hours',
                            type: 'bar',
                            stack: 'total',
                            data: nonBillableHours,
                            itemStyle: {
                                color: '#3dacc8' // Color for non-billable hours
                            }
                        }
                    ],
                    // Optional: adjust grid layout if necessary
                    grid: {
                        left: '4%',
                        right: '4%',
                        bottom: '2%',
                        containLabel: true
                    }
                };

                // Pie Chart Options
                const pieChartOptions = {
                    title: {
                        //text: 'Project-Wise Distribution of Total Hours',
                        text: '',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                    },
                    series: [
                        {
                            name: 'Total Hours',
                            type: 'pie',
                            radius: '50%',
                            data: pieChartData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            },
                            label: {
                                formatter: '{b}: {c} minutes ({d}%)',
                            },
                        },
                    ],
                };

                const doughnutChartOptions = {
                    title: {
                        text: '',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: function (params) {
                            const totalMinutes = params.value;
                            const hours = Math.floor(totalMinutes / 60);
                            const minutes = totalMinutes % 60;
                            const percentage = params.percent.toFixed(2); // Get the percentage directly
                           // return `${params.name}: ${hours}h ${minutes}m (${percentage}%)`;
                           return `${params.name}: ${hours}h ${minutes}m`;
                        },
                    },
                    legend: {
                        orient: 'horizontal',
                        left: 'left',
                        top: '2%', // Center the legend vertically
                        padding: [0, 20], // Add padding on the right side
                        formatter: function (name) {
                            // Find the data item by name
                            const dataItem = pieChartData.find(item => item.name === name);
                            if (dataItem) {
                                const totalMinutes = dataItem.value;
                                const hours = Math.floor(totalMinutes / 60);
                                const minutes = totalMinutes % 60;
                                const percentage = (dataItem.value / pieChartData.reduce((acc, item) => acc + item.value, 0) * 100).toFixed(2);
                                return `${name}: ${hours}h ${minutes}m (${percentage}%)`;
                            }
                            return name;
                        },
                    },
                    series: [
                        {
                            name: 'Total Hours',
                            top: '15%',
                            type: 'pie',
                            radius: ['40%', '90%'], // Adjust inner and outer radius
                            avoidLabelOverlap: true,
                            data: pieChartData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            },
                            label: {
                                formatter: '{d}%',

                                position: 'inside', // Position label inside the sector
                                color: '#fff', // Text color inside the sector
                                fontSize: 14, // Font size for the label
                            },
                            labelLine: {
                                show: false, // Hide the line connecting the label to the sector
                            },
                        },
                    ],
                };

                // Set the options to state or directly render them in the component
                setBarChartOptions(barChartOptions);
                setPieChartOptions(pieChartOptions);
                setDoughnutChartOptions(doughnutChartOptions)
            } else {
                setBarChartOptions(null);
                setPieChartOptions(null);
                setDoughnutChartOptions(null)
                console.error('Invalid data structure:', result4);
            }

            //#######################################################

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setShowLoader(0);
        }



    };

    const caliculateWeekLimit = (date1, date2) => {
        //console.log(date1+' - '+date2);
        const todayOfWeek = todayDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        // Create a Date object from the given date string
        const todaynew = new Date(todayDate);

        // Get the year, month, and day
        const todayyear = todaynew.getFullYear();
        const todaymonth = String(todaynew.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const todayday = String(todaynew.getDate()).padStart(2, '0');

        // Format the date as YYYY-MM-DD
        const todayformattedDate = `${todayyear}-${todaymonth}-${todayday}`;

        //console.log(todayformattedDate);  // Output: 2024-08-03

        // Create Date objects for the given dates
        const startDate = new Date(date1);
        const endDate = new Date(date2);
        const checkDate = new Date(todayformattedDate);

        // Check if the checkDate is between startDate and endDate
        if (checkDate >= startDate && checkDate <= endDate) {
            setshowReturntoWeek(0);
        } else {
            setshowReturntoWeek(1);
        }




        var strtdate = new DateObject(date1);
        var dateweek1 = strtdate.format("DD MMM");

        var enddate = new DateObject(date2);
        var dateweek2 = enddate.format("DD MMM, YYYY");
        setWeekLimitDates(dateweek1 + ' - ' + dateweek2);
    }
    const handleDayWeekChange = () => {
        setDayWekkIsChecked(!dayWekkIsChecked);
        //alert(weekStartDate);
        //alert(weekEndDate);
        console.log(weekStartDate);
        setShowingResultsForDate(convertDateStringToGMT(weekStartDate));
        caliculateWeekLimit(weekStartDate, weekEndDate);
        setDayWeekToggle('week');

    };

    const returnToToday = () => {
        handleDateChange(todayDate);
        var todaydateobject = new DateObject(todayDate);
        var todaydateformatted = todaydateobject.format("YYYY-MM-DD");
        //getTimesheetEntries('', '', '', '', '', '', useridval, todaydateformatted, '', '');
    }
    const toggleCalendar = (event) => {
        event.preventDefault();
        setShowCalendar(!showCalendar);
    };
    const handleDateChange = (newDate) => {
        console.log(newDate);
        //alert(newDate)
        setDate(newDate);
        setShowCalendar(false);
        //alert(newDate)
        // if (selectedPeriod == "Week") {
        //     getWeekDates(newDate);
        // }

        let datechangedobject = new DateObject(newDate);
        let datechangeformattedforentries = datechangedobject.format("YYYY-MM-DD");
        //alert(datechangeformattedforentries)

        if (selectedPeriod === "Week") {
            //handleDayWeekChange();
            getCurrentWeekDates(datechangeformattedforentries);

        } else if (selectedPeriod === "Month") {
            getCurrentMonthDates(datechangeformattedforentries);
        } else if (selectedPeriod === "Day") {
            currentDay()
        }

        //handleFetchData();
        setTimeout(() => {
            //@/// handleFetchData();
        }, 1000); // 2000 milliseconds = 2 seconds
    };

    function getMonthNameAndYear(dateInput) {
        // Ensure the input is a Date object
        const date = new Date(dateInput);

        // Array of month names
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        // Get the month name and year from the date
        const monthName = monthNames[date.getMonth()];
        const year = date.getFullYear();

        // Return the formatted string
        return `${monthName} ${year}`;
    }


    const getWeekDates = (date) => {
        console.log(date);
        var datechangedobject = new DateObject(date);
        var datechangeformattedforentries = datechangedobject.format("YYYY-MM-DD");

        const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        const newstartOfWeek = new Date(date);
        const dayDifference = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Adjust to Monday
        newstartOfWeek.setDate(date.getDate() + dayDifference);

        const week = [];
        for (let i = 0; i < 7; i++) {
            const weekDate = new Date(newstartOfWeek);
            weekDate.setDate(newstartOfWeek.getDate() + i);
            week.push(weekDate);
        }

        let weekyear1 = week[0].getFullYear();
        let weekmonth1 = ('0' + (week[0].getMonth() + 1)).slice(-2);
        let weekday1 = ('0' + week[0].getDate()).slice(-2);

        let weekendyear1 = week[6].getFullYear();
        let weekendmonth1 = ('0' + (week[6].getMonth() + 1)).slice(-2);
        let weekendday1 = ('0' + week[6].getDate()).slice(-2);

        let weekStartDate1 = `${weekyear1}-${weekmonth1}-${weekday1}`;
        let weekEndDate1 = `${weekendyear1}-${weekendmonth1}-${weekendday1}`;

        console.log(weekStartDate1 + ' - ' + weekEndDate1);

        setWeekStartDate(weekStartDate1);
        setWeekEndDate(weekEndDate1);

        setWeekStartDatePost(weekStartDate1);
        setWeekEndDatePost(weekEndDate1);

        setDayWekkIsChecked(true);
        console.log(dayweektoggle);
        if (dayweektoggle === 'day') {
            setDayWekkIsChecked(false);
            //getTimesheetEntries('', '', '', '', '', '', useridval, datechangeformattedforentries, '', '');
        }
        if (dayweektoggle === 'week') {
            setDayWekkIsChecked(true);
            //getTimesheetEntriesForWeek('', '', '', '', '', '', useridval, '', weekStartDate1, weekEndDate1);
        }
        else {

        }
        //console.log('1');
        // getFullWeekTimesInfo( weekStartDate1, weekEndDate1);
        caliculateWeekLimit(weekStartDate1, weekEndDate1);
    };



    const getFormatCalendarDate = (calendarDate) => {

        if (calendarDate === "") {
            return "";
        }

        var daten = new DateObject(calendarDate);
        var daten1 = daten.format("dddd, DD MMM");
        var selecteddate = daten.format('DD');

        var today = new DateObject(todayDate);
        var dd = today.format('DD');
        if (new Date(todayDate).setHours(0, 0, 0, 0) === new Date(calendarDate).setHours(0, 0, 0, 0)) {
            showReturntoToday = 0;
        } else {
            showReturntoToday = 1;
        }
        return daten1;
    }


    const caliculateNextDay = (mydate, adddays) => {

        // Input date string
        //const mydate = "2024-08-03";

        // Validate and parse the date string
        const dateParts = mydate.split('-');
        if (dateParts.length === 3) {
            const [year, month, day] = dateParts.map(Number);

            // Create a Date object in UTC
            const date = new Date(Date.UTC(year, month - 1, day));

            // Check if the Date object is valid
            if (!isNaN(date.getTime())) {
                // Add one day to the date
                date.setUTCDate(date.getUTCDate() + adddays);

                // Format the new date to YYYY-MM-DD
                const nextDay = date.toISOString().split('T')[0];
                return nextDay; // Outputs "2024-08-04"
            } else {
                //console.error("Invalid Date object created");
            }
        } else {
            //console.error("Invalid date string format");
        }
    }
    const nextDay = () => {
        //console.log(showingResultsForDate);
        // Format the date as YYYY-MM-DD
        let year = showingResultsForDate.getFullYear();
        let month = ('0' + (showingResultsForDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let day = ('0' + showingResultsForDate.getDate()).slice(-2);

        let showingResultsForDateformatted = `${year}-${month}-${day}`;
        //console.log(showingResultsForDateformatted);
        var nextdayvalnew = caliculateNextDay(showingResultsForDateformatted, 1);
        //console.log(showingResultsForDate+ '-' +nextdayvalnew);
        // Create a Date object for the given date
        let date = new Date(showingResultsForDate);

        // Increment the date by one day
        date.setDate(date.getDate() + 1);

        // Format the date as a string in YYYY-MM-DD format
        let nextDay = date.toISOString().split('T')[0];

        //console.log(nextDay);  // Output: 2024-08-02
        var myenddate = new Date(todayDate);

        let todayDateyear = todayDate.getFullYear();
        let todayDatemonth = ('0' + (todayDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let todayDateday = ('0' + todayDate.getDate()).slice(-2);

        let todatedateformattedval = `${todayDateyear}-${todayDatemonth}-${todayDateday}`;

        setWeekStartDatePost(todatedateformattedval);
        setWeekEndDatePost(todatedateformattedval);

        var mynextday = convertDateStringToEST(nextDay);
        //console.log(mynextday);
        //console.log(new Date(nextdayvalnew)+' <= '+new Date(todatedateformattedval));
        //console.log(new DateObject(nextdayvalnew));
        if (new Date(nextdayvalnew) <= new Date(todatedateformattedval)) {
            //console.log('less - '+convertDateStringToEST(new Date(nextdayvalnew)));
            //setShowingResultsForDate(new Date(nextDay));
            handleDateChange(convertDateStringToEST(new Date(nextdayvalnew)));


        }
        else {
            //console.log('not matched');
        }


    }

    function getCurrentWeekDates(sundayDate) {
        // Clone the input date to avoid modifying the original date
        let currentDate = new Date(sundayDate);

        // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        let currentDay = currentDate.getDay();

        // Adjust the start date to the current Monday
        let startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - ((currentDay + 6) % 7));

        // Adjust the end date to the current Sunday
        let endOfWeek = new Date(currentDate);
        endOfWeek.setDate(currentDate.getDate() + (6 - currentDay));

        // Format the dates as YYYY-MM-DD
        let startYear = startOfWeek.getFullYear();
        let startMonth = ('0' + (startOfWeek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let startDay = ('0' + startOfWeek.getDate()).slice(-2);

        let endYear = endOfWeek.getFullYear();
        let endMonth = ('0' + (endOfWeek.getMonth() + 1)).slice(-2);
        let endDay = ('0' + endOfWeek.getDate()).slice(-2);

        let weekStartDate = `${startYear}-${startMonth}-${startDay}`;
        let weekEndDate = `${endYear}-${endMonth}-${endDay}`;

        // Set state or perform any action with the formatted dates
        setWeekStartDate(weekStartDate);
        setWeekEndDate(weekEndDate);

        caliculateWeekLimit(weekStartDate, weekEndDate);

        setWeekStartDatePost(weekStartDate);
        setWeekEndDatePost(weekEndDate);

        // Optionally, call a function with the dates
        // getFullWeekTimesInfo(useridval, weekStartDate, weekEndDate);
    }

    function getCurrentMonthDates(currentDate) {
        // Clone the current date to avoid modifying the original date
        let currentMonthDate = new Date(currentDate);

        // Set the date to the 1st of the current month
        currentMonthDate.setDate(1);

        // Get the start date of the current month
        let startOfMonth = new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth(), 1);

        // Get the end date of the current month by setting the date to the 1st of the next month and subtracting one day
        let endOfMonth = new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth() + 1, 0);

        // Format the dates as YYYY-MM-DD
        let startYear = startOfMonth.getFullYear();
        let startMonth = ('0' + (startOfMonth.getMonth() + 1)).slice(-2);
        let startDay = ('0' + startOfMonth.getDate()).slice(-2);

        let endYear = endOfMonth.getFullYear();
        let endMonth = ('0' + (endOfMonth.getMonth() + 1)).slice(-2);
        let endDay = ('0' + endOfMonth.getDate()).slice(-2);

        let monthStartDate = `${startYear}-${startMonth}-${startDay}`;
        let monthEndDate = `${endYear}-${endMonth}-${endDay}`;

        // Set state or perform any action with the formatted dates
        setMonthStartDate(monthStartDate);
        setMonthEndDate(monthEndDate);
        setWeekStartDatePost(monthStartDate);
        let monthDis = getMonthNameAndYear(monthStartDate);
        setMonthDateDis(monthDis);
        setWeekEndDatePost(monthEndDate);

        // Optionally, call a function with the dates
        // getFullMonthTimesInfo(useridval, monthStartDate, monthEndDate);
    }

    function getPreviousMonthDates(currentDate) {
        // Clone the current date to avoid modifying the original date
        let previousMonthDate = new Date(currentDate);

        // Set the date to the 1st of the current month and then subtract one day to get the last day of the previous month
        previousMonthDate.setDate(1);
        previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
        previousMonthDate.setDate(1);

        let startOfMonth = new Date(previousMonthDate.getFullYear(), previousMonthDate.getMonth(), 1);
        let endOfMonth = new Date(previousMonthDate.getFullYear(), previousMonthDate.getMonth() + 1, 0);

        // Format the dates as YYYY-MM-DD
        let startYear = startOfMonth.getFullYear();
        let startMonth = ('0' + (startOfMonth.getMonth() + 1)).slice(-2);
        let startDay = ('0' + startOfMonth.getDate()).slice(-2);

        let endYear = endOfMonth.getFullYear();
        let endMonth = ('0' + (endOfMonth.getMonth() + 1)).slice(-2);
        let endDay = ('0' + endOfMonth.getDate()).slice(-2);

        let monthStartDate = `${startYear}-${startMonth}-${startDay}`;
        let monthEndDate = `${endYear}-${endMonth}-${endDay}`;

        setMonthStartDate(monthStartDate);
        setMonthEndDate(monthEndDate);
        //alert("monthStartDate--"+monthStartDate)
        //alert("monthEndDate--"+monthEndDate)

        setWeekStartDatePost(monthStartDate);
        let monthDis = getMonthNameAndYear(monthStartDate);
        setMonthDateDis(monthDis);
        //alert(monthDis);
        setWeekEndDatePost(monthEndDate);
        //setMonthStartDatePost(monthStartDate);
        //setMonthEndDatePost(monthEndDate);
        //getFullMonthTimesInfo(useridval, monthStartDate, monthEndDate);
    }

    function getNextMonthDates(currentDate) {
        // Get the first day of the next month
        let firstDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

        // Calculate the last day of the next month
        let lastDayOfNextMonth = new Date(firstDayOfNextMonth.getFullYear(), firstDayOfNextMonth.getMonth() + 1, 0);

        // Format the dates as YYYY-MM-DD
        let startYear = firstDayOfNextMonth.getFullYear();
        let startMonth = ('0' + (firstDayOfNextMonth.getMonth() + 1)).slice(-2);
        let startDay = ('0' + firstDayOfNextMonth.getDate()).slice(-2);

        let endYear = lastDayOfNextMonth.getFullYear();
        let endMonth = ('0' + (lastDayOfNextMonth.getMonth() + 1)).slice(-2);
        let endDay = ('0' + lastDayOfNextMonth.getDate()).slice(-2);

        let monthStartDate = `${startYear}-${startMonth}-${startDay}`;
        let monthEndDate = `${endYear}-${endMonth}-${endDay}`;

        setMonthStartDate(monthStartDate);
        setMonthEndDate(monthEndDate);


        setWeekStartDatePost(monthStartDate);
        let monthDis = getMonthNameAndYear(monthStartDate);
        //alert(monthDis);
        setMonthDateDis(monthDis);
        setWeekEndDatePost(monthEndDate);

        //setMonthStartDatePost(monthStartDate);
        //setMonthEndDatePost(monthEndDate);
        //getFullMonthTimesInfo(useridval, monthStartDate, monthEndDate);
    }

    function getPreviousWeekDates(sundayDate) {

        // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        let sunDay = sundayDate.getDay();

        // Adjust currentDay to use 0 for Monday, 1 for Tuesday, ..., 6 for Sunday
        let adjustedDay = (sunDay + 6) % 7;
        //console.log(sundayDate);
        // Calculate the start and end dates of the week
        let startOfWeek = new Date(sundayDate);
        let endOfWeek = new Date(sundayDate);

        // Adjust the start date to the previous Monday
        startOfWeek.setDate(sundayDate.getDate() - adjustedDay);

        // Adjust the end date to the next Sunday
        endOfWeek.setDate(sundayDate.getDate() + (6 - adjustedDay));

        // Format the dates as YYYY-MM-DD
        let startYear = startOfWeek.getFullYear();
        let startMonth = ('0' + (startOfWeek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let startDay = ('0' + startOfWeek.getDate()).slice(-2);

        let endYear = endOfWeek.getFullYear();
        let endMonth = ('0' + (endOfWeek.getMonth() + 1)).slice(-2);
        let endDay = ('0' + endOfWeek.getDate()).slice(-2);

        let weekStartDate = `${startYear}-${startMonth}-${startDay}`;
        let weekEndDate = `${endYear}-${endMonth}-${endDay}`;

        setWeekStartDate(weekStartDate);
        setWeekEndDate(weekEndDate);

        setWeekStartDatePost(weekStartDate);
        setWeekEndDatePost(weekEndDate);
        //getFullWeekTimesInfo(useridval, weekStartDate, weekEndDate);
    }
    const previousDay = () => {
        // Subtract one day from the current date
        //console.log(showingResultsForDate);
        let previousday = new Date(showingResultsForDate);
        //let previousday = getCurrentDateInEST(new Date(showingResultsForDate));
        previousday.setDate(showingResultsForDate.getDate() - 1);

        // Format the date as YYYY-MM-DD
        let year = previousday.getFullYear();
        let month = ('0' + (previousday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let day = ('0' + previousday.getDate()).slice(-2);

        let previousdayDate = `${year}-${month}-${day}`;

        setWeekStartDatePost(previousdayDate);
        setWeekEndDatePost(previousdayDate);

        //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
        previousdayDate = convertDateStringToEST(previousdayDate);

        if (previousdayDate.getDay() === 0) //sunday
        {
            getPreviousWeekDates(previousdayDate);
        }

        setShowingResultsForDate(previousdayDate);
        handleDateChange(previousdayDate);
    }

    const currentDay = () => {
        // Subtract one day from the current date
        //console.log(showingResultsForDate);
        let previousday = new Date(showingResultsForDate);
        //let previousday = getCurrentDateInEST(new Date(showingResultsForDate));
        previousday.setDate(showingResultsForDate.getDate());

        // Format the date as YYYY-MM-DD
        let year = previousday.getFullYear();
        let month = ('0' + (previousday.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let day = ('0' + previousday.getDate()).slice(-2);

        let previousdayDate = `${year}-${month}-${day}`;

        setWeekStartDatePost(previousdayDate);
        setWeekEndDatePost(previousdayDate);

        //previousdayDate = new Date(previousdayDate); // Outputs: YYYY-MM-DD
        previousdayDate = convertDateStringToEST(previousdayDate);



        //setShowingResultsForDate(previousdayDate);
        //handleDateChange(previousdayDate);
    }


    const previousWeek = () => {
        // Subtract one day from the current date
        //alert(showingResultsForDate);
        let previousweek = new Date(showingResultsForDate);
        //let previousweek = getCurrentDateInEST(new Date(showingResultsForDate));
        previousweek.setDate(showingResultsForDate.getDate() - 7);

        // Format the date as YYYY-MM-DD
        let year = previousweek.getFullYear();
        let month = ('0' + (previousweek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let day = ('0' + previousweek.getDate()).slice(-2);

        let previousweekDate = `${year}-${month}-${day}`;

        //previousweekDate = new Date(previousweekDate); // Outputs: YYYY-MM-DD
        previousweekDate = convertDateStringToEST(previousweekDate);
        if (previousweekDate.getDay() === 0) //sunday
        {
            getPreviousWeekDates(previousweekDate);
        }

        setShowingResultsForDate(previousweekDate);
        handleDateChange(previousweekDate);
        // alert(previousweekDate)
        //handleDayWeekChange();

    }

    function getNextWeekDates(mondayDate) {

        // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        let monday = mondayDate.getDay();

        // Adjust currentDay to use 0 for Monday, 1 for Tuesday, ..., 6 for Sunday
        let adjustedDay = (monday - 1) % 7;

        // Calculate the start and end dates of the week
        let startOfWeek = new Date(mondayDate);
        let endOfWeek = new Date(mondayDate);

        // Adjust the start date to the previous Monday
        startOfWeek.setDate(mondayDate.getDate() - adjustedDay);

        // Adjust the end date to the next Sunday
        endOfWeek.setDate(mondayDate.getDate() + (6 - adjustedDay));

        // Format the dates as YYYY-MM-DD
        let startYear = startOfWeek.getFullYear();
        let startMonth = ('0' + (startOfWeek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let startDay = ('0' + startOfWeek.getDate()).slice(-2);

        let endYear = endOfWeek.getFullYear();
        let endMonth = ('0' + (endOfWeek.getMonth() + 1)).slice(-2);
        let endDay = ('0' + endOfWeek.getDate()).slice(-2);

        let weekStartDate = `${startYear}-${startMonth}-${startDay}`;
        let weekEndDate = `${endYear}-${endMonth}-${endDay}`;

        setWeekStartDate(weekStartDate);
        setWeekEndDate(weekEndDate);

        setWeekStartDatePost(weekStartDate);
        setWeekEndDatePost(weekEndDate);
        //getFullWeekTimesInfo(useridval, weekStartDate, weekEndDate);
    }

    const nextWeek = () => {
        // Subtract one day from the current date
        let nextweek = new Date(showingResultsForDate);
        //let nextweek = getCurrentDateInEST(new Date(showingResultsForDate));
        nextweek.setDate(showingResultsForDate.getDate() + 7);

        // Format thjhhe date as YYYY-MM-DD
        let year = nextweek.getFullYear();
        let month = ('0' + (nextweek.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let day = ('0' + nextweek.getDate()).slice(-2);

        let nextweekDate = `${year}-${month}-${day}`;

        //var mynextweek = new Date(nextweekDate);
        var mynextweek = convertDateStringToEST(nextweekDate);
        if (mynextweek.getDay() == 1) //monday
        {
            getNextWeekDates(mynextweek);
        }
        var myenddate = new Date(todayDate); // Outputs: YYYY-MM-DD
        //var myenddate = convertDateStringToEST(todayDate);
        myenddate.setHours(0, 0, 0, 0);
        mynextweek.setHours(0, 0, 0, 0);

        if (mynextweek <= myenddate) {
            setShowingResultsForDate(new Date(nextweekDate));
            handleDateChange(new Date(nextweekDate));

            //setShowingResultsForDate(convertDateStringToEST(nextweekDate));
            //handleDateChange(convertDateStringToEST(nextweekDate));
        }
        else {

        }

    }



    const previousMonth = () => {
        let previousMonthDate = new Date(showingResultsForDate);
        previousMonthDate.setMonth(showingResultsForDate.getMonth() - 1);

        // Format the date as YYYY-MM-DD
        let year = previousMonthDate.getFullYear();
        let month = ('0' + (previousMonthDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let day = ('0' + previousMonthDate.getDate()).slice(-2);

        let formattedDate = `${year}-${month}-${day}`;

        let convertedDate = convertDateStringToEST(formattedDate);

        // Check if it's the first day of the month
        // if (convertedDate.getDate() === 1) {
        //     getPreviousMonthDates(convertedDate);
        // }

        //getPreviousMonthDates(convertedDate);
        getCurrentMonthDates(convertedDate)

        setShowingResultsForDate(convertedDate);
        handleDateChange(convertedDate);
        //alert(convertedDate)
    }

    const nextMonth = () => {
        let nextMonthDate = new Date(showingResultsForDate);
        nextMonthDate.setMonth(showingResultsForDate.getMonth() + 1);

        // Format the date as YYYY-MM-DD
        let year = nextMonthDate.getFullYear();
        let month = ('0' + (nextMonthDate.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
        let day = ('0' + nextMonthDate.getDate()).slice(-2);

        let formattedDate = `${year}-${month}-${day}`;

        let convertedDate = convertDateStringToEST(formattedDate);

        // Check if it's the first day of the next month
        // if (convertedDate.getDate() === 1) {
        //     // getNextMonthDates(convertedDate);
        // }

        //getNextMonthDates(convertedDate);
        getCurrentMonthDates(convertedDate)
        // Set the end date comparison if needed
        let endDate = new Date(todayDate); // Today’s date
        endDate.setHours(0, 0, 0, 0);
        convertedDate.setHours(0, 0, 0, 0);

        if (convertedDate <= endDate) {
            setShowingResultsForDate(convertedDate);
            handleDateChange(convertedDate);
        } else {
            // Handle if the date is beyond the range (if needed)
        }
    }

    useEffect(() => {
        var loggedinUserRole = sessionStorage.getItem('userRole');
        getCompanyId(sessionStorage.getItem("userIdval"));
        //getWeekDates(todayDate);
        //getCurrentMonthDates(todayDate);
        //returnToToday();
        setLoggedUser(loggedinUserRole);
        var statsreqCompanyVal = '';
        //console.log(sessionStorage.getItem('userRole'));
        if(sessionStorage.getItem('userRole') == 'Manager'){
            //managerid = sessionStorage.getItem('userIdval');
            statsreqCompanyVal = companyid;
        }

        if(sessionStorage.getItem('userRole') == 'Admin'){
            statsreqCompanyVal = adminCompanyid;
        }
        getProjects('', '1', statsreqCompanyVal);
        //getCurrentMonthDates(todayDate);
        setSelectedPeriod('Month');
        //alert(todayDate)
        getCurrentMonthDates(todayDate);
        //handleFetchData();

        setTimeout(() => {
            //handleFetchData();
        }, 1000); // 2000 milliseconds = 2 seconds
        //currentDay();
    }, []);

    const getCompanyId = (logid) => {
        services.getCompanyList(1).then((res) => {
            if(res.data.type === "success"){
                var fullcompanyList = res.data.data;
                setCompaniesFilterData(res.data.data);

                services.getUsersInfoById(logid).then((res) => {
                    if(res.data.type === 'success'){
                        for(var i=0; i < fullcompanyList.length; i++){
                            if(fullcompanyList[i].id === res.data.data.company_id){
                                //setUserCompanyFilterData(fullcompanyList[i].company_name);
                                sessionStorage.setItem("userCompanyName", fullcompanyList[i].company_name);
                                sessionStorage.setItem("userCompanyId", fullcompanyList[i].id);
                                if(sessionStorage.getItem('userRole') === 'Admin'){
                                    sessionStorage.setItem("adminCompany", '');
                                }
                            }
                        }
                    }
                });

            }
        });
    }
    ///////////////////////

    const columns = React.useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, i) => i + 1,
                disableSortBy: true,
                width: '10%',
            },
            {
                Header: 'Project',
                accessor: 'projectName',
                width: '30%',
            },
            {
                Header: 'Client',
                accessor: 'clientName',
                width: '25%',
            },
            {
                Header: 'No of Tasks',
                accessor: 'noOfTasks',
                width: '15%',
            },
            {
                Header: 'Users',
                accessor: 'noOfUsers',
                width: '10%',
            },
            {
                Header: 'Hours',
                accessor: 'totalHours',
                width: '10%',
            },
        ],
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
           // data: projectWiseStatsData,
            data: projectWiseStatsTableData
        },
        useSortBy
    );

    //////////////////////////////
    // User Wise Stats Columns
    const userColumns = React.useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, i) => i + 1,
                disableSortBy: true,
                width: '10%',
            },
            {
                Header: 'Employee Name',
                accessor: row => `${row.firstName} ${row.lastName}`,
                width: '25%',
            },
            {
                Header: 'Project',
                accessor: 'projects',
                Cell: ({ value }) => (
                    value.map(project => (
                        <span
                            key={project.projectId}
                            style={{ cursor: 'pointer', display: 'block', marginBottom: '5px' }}
                            onClick={() => handleClickProjectId(project.projectId)}
                            className="badge rounded-pill project-badge text-dark"
                        >
                            {project.projectName}
                        </span>
                    ))
                ),
                width: '35%',
            },
            {
                Header: 'No of Tasks',
                accessor: 'noOfTasks',
                width: '15%',
            },
            {
                Header: 'Total Hours',
                accessor: 'totalHours',
                width: '15%',
            },
        ],
        []
    );

    const { getTableProps: getUserTableProps, getTableBodyProps: getUserTableBodyProps, headerGroups: userHeaderGroups, rows: userRows, prepareRow: prepareUserRow } = useTable(
        {
            columns: userColumns,
            //data: userWiseStatsData,
            data: userWiseStatsTableData
        },
        useSortBy
    );


    ////////////


    // Pagination Handlers for Projects
    const handleProjectPrevPage = () => {
        if (projectCurrentPage > 1) {
            setProjectCurrentPage(projectCurrentPage - 1);
        }
    };

    const handleProjectNextPage = () => {
        if (projectCurrentPage < projectTotalPages) {
            setProjectCurrentPage(projectCurrentPage + 1);
        }
    };

    // Pagination Handlers for Tasks
    const handleTaskPrevPage = () => {
        if (taskCurrentPage > 1) {
            setTaskCurrentPage(taskCurrentPage - 1);
        }
    };

    const handleTaskNextPage = () => {
        if (taskCurrentPage < taskTotalPages) {
            setTaskCurrentPage(taskCurrentPage + 1);
        }
    };




    const renderProjectPagination = () => {
        const pageNumbers = generatePageNumbers(projectCurrentPage, projectTotalPages);

        return (
            <div className="pagination-controls justify-content-end">
                <button onClick={handleProjectPrevPage} disabled={projectCurrentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setProjectCurrentPage(page)}
                        className={page === projectCurrentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleProjectNextPage} disabled={projectCurrentPage === projectTotalPages}>
                    Next »
                </button>
            </div>
        );
    };

    const renderTaskPagination = () => {
        console.log("taskTotalPages:" + taskTotalPages)
        const pageNumbers = generatePageNumbers(taskCurrentPage, taskTotalPages);

        return (
            <div className="pagination-controls justify-content-end">
                <button onClick={handleTaskPrevPage} disabled={taskCurrentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setTaskCurrentPage(page)}
                        className={page === taskCurrentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleTaskNextPage} disabled={taskCurrentPage === taskTotalPages}>
                    Next »
                </button>
            </div>
        );
    };

    // Helper function to generate page numbers
    const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrent = Math.floor(maxPageButtons / 2);
            const maxPagesAfterCurrent = Math.ceil(maxPageButtons / 2) - 1;

            if (currentPage <= maxPagesBeforeCurrent) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrent;
                endPage = currentPage + maxPagesAfterCurrent;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };


    ////////////////////////
    return (
        <>
            <main>




                <section>
                    <div class="container">


                        <div className="notification-list ">
                            {/* {notifications.map(notification => (
                                <Notification
                                    key={notification.notification_id}
                                    //   message={`Timesheet: ${notification.action_item} | ${notification.action_description_show}`}
                                    message={`${notification.action_description_show}`}
                                    onClose={() => handleNotificationClose(notification.notification_id)}
                                />
                            ))} */}

                            {notifications.length > 0 && (
                                <Notification
                                    key={notifications[0].notification_id}
                                    message={`${notifications[0].action_description_show}`}
                                    onClose={() => handleNotificationClose(notifications[0].notification_id)}
                                />
                            )}
                        </div>


                        <h2 class="mainHeadline">
                            Dashboard
                        </h2>
                        {(loggedUser === 'Manager' || loggedUser === 'manager') ?
                            <div class="db-wrap d-flex justify-content-center w-100 my-4 gap-2 p-4" style={{ "min-height": "50px" }}>
                                <button class="btn btn-primary " onClick={() => handleClickAddDayEntry()}>Add Day Entry</button>
                                <button class="btn btn-primary " onClick={() => handleClickAddWeekEntry()}>Add Week Entry</button>
                            </div>
                            : ''}

                        <div class="db-wrap d-flex justify-content-between w-100 mt-4 gap-2 p-4 position-relative" style={{ "min-height": "50px", top:"50px" }}>

                            <div class="d-flex align-items-center">
                                {/* <div class="prev-after-wrap">
                    <a href="#" onClick={previousDay}> <span> <img src="images/left.png" alt=""/></span></a>
                    <a href="#" onClick={nextDay}><span> <img src="images/right.png" alt=""/></span></a>
                </div> */}


                                {/* Conditional rendering based on selectedPeriod */}
                                {selectedPeriod === "Day" && (
                                    <div className="prev-after-wrap">
                                        <a href="#" onClick={previousDay}>
                                            <span><img src="images/left.png" alt="" /></span>
                                        </a>
                                        <a href="#" onClick={nextDay}>
                                            <span><img src="images/right.png" alt="" /></span>
                                        </a>
                                    </div>
                                )}

                                {selectedPeriod === "Week" && (
                                    <div className="prev-after-wrap">
                                        <a href="#" onClick={previousWeek}>
                                            <span><img src="images/left.png" alt="" /></span>
                                        </a>
                                        <a href="#" onClick={nextWeek}>
                                            <span><img src="images/right.png" alt="" /></span>
                                        </a>
                                    </div>
                                )}

                                {selectedPeriod === "Month" && (
                                    <div className="prev-after-wrap">
                                        <a href="#" onClick={previousMonth}>
                                            <span><img src="images/left.png" alt="" /></span>
                                        </a>
                                        <a href="#" onClick={nextMonth}>
                                            <span><img src="images/right.png" alt="" /></span>
                                        </a>
                                    </div>
                                )}

                                <div class="daytitlewrap ">
                                    <span class="d-flex">
                                        {/* <img class="icon" src="images/cal-icon.png" alt=""/> */}

                                        <button onClick={toggleCalendar} style={{ background: 'none', margin: "0px", border: 'none', cursor: 'pointer' }}><img class="icon" src="images/cal-icon.png" alt="" /></button>

                                        {showCalendar && (

                                            <div><Calendar onChange={handleDateChange} value={date} maxDate={getCurrentDateInEST()} /></div>

                                        )}


                                        <div class="d-grid">
                                            {/* <h2>{getFormatCalendarDate(date)}</h2> */}
                                            {/* <a href="#"><img src="images/returnArrow.png" style={{"margin-right": "4px"}} alt=""/>Return to
                                Today</a> */}

                                            {selectedPeriod === 'Day' && <h2>{getFormatCalendarDate(date)}</h2>}
                                            {selectedPeriod === 'Week' && <h2>Week: {weekLimitDates}</h2>}
                                            {selectedPeriod === 'Month' && <h2>Month: {monthDateDis}</h2>}

                                            {showReturntoToday === 1 && (
                                                <a href="#" onClick={returnToToday}><img src="images/returnArrow.png" style={{ "margin-right": "4px" }} alt="" />Return to Today</a>
                                            )}

                                        </div>

                                    </span></div>
                            </div>
                            <select class="form-select" id="inputGroupSelect01" style={{ "max-width": "200px" }}
                                value={selectedPeriod}  // Bind state to the select value
                                onChange={handlePeriodChange}  // Update state on change
                            >
                                <option value="Day">Day</option>
                                <option value="Week">Week</option>
                                <option value="Month">Month</option>

                            </select>

                        </div>

                        <div class="db-wrap d-grid my-4 gap-2 p-4" style={{ "min-height": "50px" }}>

                            <div class=" d-flex justify-content-between w-100 ">
                                <div class="d-flex justify-content-between gap-2">
                                    <div class="d-flex gap-2 align-items-center"> <span class="legend-db bb"></span> Billable</div>
                                    <div class="d-flex gap-2 align-items-center"> <span class="legend-db nonbb"></span> Non Billable
                                    </div>
                                </div>
{/*
                                <div class="d-flex justify-content-end align-items-center">
                                    <span style={{ "min-width": "69px" }}>Filter:</span>
                                    <select
                                        name="projectbillingtype"
                                        id="projectBillingType"
                                        className="form-select"
                                        style={{ maxWidth: '200px' }}
                                        value={projectBillingType}
                                        onChange={handleProjectBillingTypeChange}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Billable</option>
                                        <option value="0">Non Billable</option>
                                    </select>
                                </div> */}

                            </div>
                            <div class="w-100 d-flex justify-content-between gap-2">
                                <span class="d-grid w-100">
                                    <div class="mb-2">Total Hours: <b>{timeEntryStatsData.totalHours} </b>
                                    {/*<span>Hrs</span>*/}</div>


                                    <div class="bbnonbb">
                                        <span class="bb px-2" style={{ width: `${billableWidth}%` }}>{timeEntryStatsData.billableHours} Hrs</span>
                                        <span class="nonbb px-2" style={{ width: `${nonBillableWidth}%` }}>{timeEntryStatsData.nonBillableHours} Hrs</span>
                                    </div>

                                </span>
                                <span class="d-grid w-100">
                                    <div class="mb-2">Total Tasks: <b>{timeEntryStatsData.totalTasks} </b>
                                    {/*<span>{timeEntryStatsData.totalTasks === 1 ? "Task" : "Tasks"}</span>*/}</div>

                                    <div class="bbnonbb">
                                        <span class="bb px-2"  style={{ width: `${billableTasksWidth}%` }}>{timeEntryStatsData.billableTasks}{" "} {timeEntryStatsData.billableTasks === 1 ? "Task" : "Tasks"}</span>
                                        <span class="nonbb px-2" style={{ width: `${nonBillableTasksWidth}%` }}>{timeEntryStatsData.nonBillableTasks}{" "} {timeEntryStatsData.nonBillableTasks === 1 ? "Task" : "Tasks"}</span>
                                    </div>

                                </span>
                                {/* <span class="d-grid w-100">
                                    <div class="mb-2">Total Projects</div>
                                    {/* <div class="bbnonbb">
                                        <span class="defaultprj px-2" style={{ "width": "100%" }}>value</span>
                                    </div> *
                                    <div class="db-value">

                                    </div>
                                </span> */}
                            </div>
                        </div>


                        {/* <div class="dashbmainuser ">
                            <div class="db-wrap">
                                {barChartOptions && <ReactECharts option={barChartOptions} style={{ height: 400, width: '100%' }} />}
                            </div>
                        </div>

                        <div class="dashbmainuser">
                            <div class="db-wrap">
                                {doughnutChartOptions && <ReactECharts option={doughnutChartOptions} style={{ height: 400, width: '100%' }} />}
                            </div>
                        </div> */}


                        <div class="dashbmainuser mb-2">
                            <div class="db-wrap">
                                {barChartOptions && <ReactECharts option={barChartOptions} style={{ height: 400, width: '100%' }} />}
                            </div>

                            <div class="db-wrap">
                            <b>{timeEntryStatsData.totalProjects} </b>
                            <span>{timeEntryStatsData.totalProjects === 1 ? "Project" : "Projects"}</span>
                                {doughnutChartOptions && <ReactECharts option={doughnutChartOptions} style={{ height: 400, width: '100%' }} />}
                            </div>
                        </div>




                        <div class="db-wrap mb-4 " style={{ "min-height": "50px" }}>
                            <div class="d-flex align-items-center justify-content-between gap-2">
                                <h3 class="mb-0">Time Sheet Stats</h3>
                                <div class="statsvalwrap">
                                    <div class="d-flex">
                                        <button class="statsitem" onClick={handleClickPending}>
                                            <span class="statsvalue" style={{ "background-color": "#2F4074" }} >{timeSheetStatsReport.submitted}</span>
                                            <span class="ms-2 me-4 statxt">Pending for approval</span>
                                            <img src="images/arrow-sm-right.svg" alt="" />
                                        </button>
                                    </div>
                                    <div class="d-flex">
                                        <button class="statsitem" onClick={handleClickUnsubmitted}>
                                            <span class="statsvalue" style={{ "background-color": "#CC4748" }}  >{timeSheetStatsReport.unSubmitted}</span>
                                            <span class="ms-2 me-4 statxt">Unsubmitted</span>
                                            <img src="images/arrow-sm-right.svg" alt="" />
                                        </button>
                                    </div>
                                    <div class="d-flex">
                                        <button class="statsitem" onClick={handleClickApproved}>
                                            <span class="statsvalue" style={{ "background-color": "#7EBF3F" }} >{timeSheetStatsReport.approved}</span>
                                            <span class="ms-2 me-4 statxt">Approved</span>
                                            <img src="images/arrow-sm-right.svg" alt="" />
                                        </button>
                                    </div>
                                    {/* <div class="d-flex">
                    <div class="statsitem">
                        <span class="statsvalue" style="background-color: black;">23</span>
                        <span class="ms-2 me-4">Pending for approval</span>
                        <a href="#"> <img src="images/arrow-sm-right.svg" alt=""></a>
                    </div>
                </div> */}
                                    {/* <div class="d-flex">
                    <div class="statsitem">
                        <span class="statsvalue" style="background-color: black;">23</span>
                        <span class="ms-2 me-4">Pending for approval</span>
                        <a href="#"> <img src="images/arrow-sm-right.svg" alt=""></a>
                    </div>
                </div> */}
                                </div>

                            </div>
                        </div>

                        <div class="db-table mb-4">
                            {/*
                            <table class="table mb-0">
                                <thead class="bg-lightblue">
                                    <tr>

                                        <th scope="col" style={{ "width": "50px" }}>No</th>
                                        <th scope="col" style={{ "width": "150px" }}>Project</th>
                                        <th scope="col" style={{ "width": "160px" }}>Client</th>
                                        <th scope="col" style={{ "width": "160px" }}>No of Tasks</th>
                                        <th scope="col" style={{ "width": "160px" }}>Users</th>
                                        <th scope="col" style={{ "width": "160px" }}>Hours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectWiseStatsData.length > 0 ? (
                                        projectWiseStatsData.map((project, index) => (
                                            <tr key={project.projectId}>
                                                <td style={{ width: "50px" }}>{index + 1}</td>
                                                <td style={{ width: "150px" }}>{project.projectName}</td>
                                                <td style={{ width: "160px" }}>{project.clientName}</td>
                                                <td style={{ width: "160px" }}>{project.noOfTasks}</td>
                                                <td style={{ width: "160px" }}>{project.noOfUsers}</td>
                                                <td style={{ width: "160px" }}>{project.totalHours}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            */}

                            <table {...getTableProps()} className="table mb-0">
                                <thead className="bg-lightblue">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    style={{
                                                        width: column.width,
                                                        position: 'relative',

                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {column.render('Header')}
                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            display: 'inline-block',
                                                            fontSize: '0.8em',
                                                            color: '#888',
                                                        }}
                                                    >
                                                        {column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : '↕️'}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.length > 0 ? (
                                        rows.map(row => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    ))}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6">
                                            <div className="d-grid text-center py-3">
<img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
<span> No Projects Available</span>
</div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>


                            {renderProjectPagination()}




                        </div>

                        <div class="d-flex justify-content-between w-100 mt-4 mb-2 gap-2 px-2 pt-2" style={{ "min-height": "50px" }}>

                            <div class="d-flex align-items-center">

                                <div class="daytitlewrap ">
                                    <span class="d-flex">
                                        <strong>Resources</strong>
                                    </span></div>
                            </div>
                            <select
                                name="resourceProject"
                                className="form-select"
                                id="inputGroupSelect01"
                                style={{ maxWidth: "200px" }}
                                value={resourceProject} // Bind the select value to the state
                                onChange={handleProjectChange}
                            >
                                <option value="" >All</option>
                                {showProjectList.map((data, index) => (
                                    <option key={index} value={data.id}>{data.name}</option>
                                ))}
                            </select>

                        </div>

                        <div class="db-table mb-4">

                            {/* <table class="table mb-0">
                                <thead class="bg-lightblue">
                                    <tr>

                                        <th scope="col" style={{ "width": "50px" }}>No</th>
                                        <th scope="col" style={{ "width": "150px" }}>Employee Name</th>
                                        <th scope="col" style={{ "width": "auto", "min-width": "200px" }}>Project</th>
                                        <th scope="col" style={{ "width": "90px" }}>No of Tasks</th>
                                        <th scope="col" style={{ "width": "50px" }}>Total Hours</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {userWiseStatsData.map((user, index) => (
                                        <tr key={user.userId}>
                                            <td style={{ width: 'auto' }}>{index + 1}</td>
                                            <td style={{ width: 'auto' }}>{`${user.firstName} ${user.lastName}`}</td>
                                            <td style={{ width: '150px' }}>
                                                {user.projects.map(project => (
                                                    <span  style={{  cursor: 'pointer' }} onClick={() => handleClickProjectId(project.projectId)} key={project.projectId} className="badge rounded-pill project-badge text-dark my-2 me-1">
                                                        {project.projectName}
                                                    </span>
                                                ))}
                                            </td>
                                            <td style={{ width: '150px' }}>{user.noOfTasks}</td>
                                            <td style={{ width: 'auto' }}>{user.totalHours}</td>
                                        </tr>
                                    ))}




                                </tbody>
                            </table> */}


                            {/* User Wise Stats Table */}
                            <table {...getUserTableProps()} className="table mb-0">
                                <thead className="bg-lightblue">
                                    {userHeaderGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    style={{
                                                        width: column.width,
                                                        position: 'relative',

                                                        cursor: column.canSort ? 'pointer' : 'default',
                                                    }}
                                                >
                                                    {column.render('Header')}
                                                    {column.canSort && (
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                display: 'inline-block',
                                                                fontSize: '0.8em',
                                                                color: '#888',
                                                            }}
                                                        >
                                                            {column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : '↕️'}
                                                        </span>
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getUserTableBodyProps()}>
                                    {userRows.length > 0 ? (
                                        userRows.map(row => {
                                            prepareUserRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    ))}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="5">
                                            <div className="d-grid text-center py-3">
<img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
<span> No Resources Available</span>
</div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>


                            {renderTaskPagination()}



                        </div>
                        {showLoader === 1 ?
                        <div class="loadwrapper">
                            <div class="clock"><div class="circle"></div></div>
                        </div>
                        : '' }
                    </div>

                </section>

            </main>
        </>
    )
};

AdminManagerDashboard.propTypes = {};

AdminManagerDashboard.defaultProps = {};

export default AdminManagerDashboard;