import React,{useEffect, useState} from "react";
import services from "../../../services/services";
import DateObject from "react-date-object";

const AdminTimesheetHeader = () => {
   //const useridval = 4;
   const [loggedUser, setLoggedUser] = useState('');
   const [useridval, setuseridval] = useState(0);

   useEffect(() =>
   {
    var loggedinUserRole = sessionStorage.getItem('userRole');
    setLoggedUser(loggedinUserRole);

    var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));
   },[1]);

  return(
  <>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname == '/Admin-Pending-For-Approval' || window.location.pathname == '/admin-pending-for-approval' || window.location.pathname == '/submitted-weekview' || window.location.pathname == '/timesheet-management') ? 'active' : ''}`} href="/admin-pending-for-approval">Pending Approval</a>
                </li>
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname == '/Unsubmitted-List' || window.location.pathname == '/unsubmitted-list' || window.location.pathname == '/admin-unsubmitted') ? 'active' : ''}`} href="/admin-unsubmitted">Unsubmitted </a>
                </li>
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname == '/Approved-List' || window.location.pathname == '/approved-list' || window.location.pathname == '/admin-approved' || window.location.pathname == '/approved-weekview') ? 'active' : ''}`} href="/admin-approved">Approved  </a>
                </li>
            </ul>
  </>
)};

AdminTimesheetHeader.propTypes = {};

AdminTimesheetHeader.defaultProps = {};

export default AdminTimesheetHeader;
