//import React, { useEffect, useState } from 'react';
import React, { history, useEffect, useState, useMemo } from "react";
import { createPopper } from '@popperjs/core';
import services from "../../services/services";
import jwtDecode from 'jwt-decode';

const PublicHeader = () => {
const [loggedUserRole, setLoggedUserRole] = useState('');
const [userFullName, setUserFullName] = useState(sessionStorage.getItem('userFullName') || '');
const [loggedUserId, setLoggedUserId] = useState(sessionStorage.getItem('userIdval'));
const [loggedUserName, setLoggedUserName] = useState('');
const [loggedUserAvatar, setLoggedUserAvatar] = useState('');
const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());
const [notifications, setNotifications] = useState([]);
const [notificationLength, setNotificationLength] = useState([]);
//const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());


    useEffect(() => {
        // Listen for sessionStorage changes (triggered by other parts of your app)
        const handleStorageChange = () => {
            const updatedName = sessionStorage.getItem('userFullName');
            setLoggedUserAvatar(getProfileImageUrl(sessionStorage.getItem('userProfileAvatar')))
            setUserFullName(updatedName);
        };

        window.addEventListener('storage', handleStorageChange);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    const handleNotificationClose = (id) => {
        // Update the notification flag when a notification is closed
        var accessToken2 = sessionStorage.getItem("accessToken");
        fetch(`${apiEndPoint}/notifications/update-flag`, {
          method: 'POST',
          headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken2}`
             },
          body: JSON.stringify({ user_id: loggedUserId, notification_id: id })
        })
          .then(response => response.json())
          .then(result => {
            if (result.type === 'success') {
              // Remove the closed notification from the list
              setNotifications(notifications.filter(notification => notification.notification_id !== id));
              fetchNotifications();
            } else {
              console.error('Failed to update notification flag');
            }
          })
          .catch(error => console.error('Error updating notification flag:', error));
      };


      const fetchNotifications = () => {

        const requestJson = { user_id: loggedUserId };

        // Fetching notifications from the service
        services.getAllNotifications(requestJson).then((res) => {
            console.log("#####################");
           // setNotificationLength(res.data.length)


            if (res.data && Array.isArray(res.data)) {
                setNotificationLength(res.data.length); // Ensure res.data is an array before accessing length
            } else {
                setNotificationLength(0); // Handle the case where res.data is undefined or not an array
            }

            console.log(JSON.stringify(res));
            if (res.type === 'success') {
                setNotifications(res.data); // Set fetched notifications
            }
        });
    }

    useEffect(() => {
        var userRole = sessionStorage.getItem('userRole');
        setLoggedUserRole(userRole);

        var userId = sessionStorage.getItem('userIdval');
        setLoggedUserId(userId);

        fetchNotifications();

        var loggedusername = sessionStorage.getItem('userFullName');
        var loggeduserAvatar = sessionStorage.getItem('userProfileAvatar');
        if(!isEmpty(loggedusername)) {
            setLoggedUserName(loggedusername);
            setLoggedUserAvatar(getProfileImageUrl(loggeduserAvatar));
        } else {
            services.getUsersInfoById(userId).then((res) => {
                if(res.data.type === 'success'){
                    console.log(res.data.data);
                    var userFullName = res.data.data.first_name+' '+res.data.data.last_name;
                    sessionStorage.setItem('userFullName', userFullName);
                    setLoggedUserName(userFullName);

                    var userProfileImage = res.data.data.profile_image_path;
                    sessionStorage.setItem('userProfileAvatar', userProfileImage);
                    setLoggedUserAvatar(getProfileImageUrl(userProfileImage))
                }
            });
        }
        //setLoggedUserName(sessionStorage.getItem('userFullName'));
        //setLoggedUserAvatar(sessionStorage.getItem('userProfileAvatar'));
    }, []);

    const isEmpty = (value) => {
        return value === null || String(value).trim() === '';
    }

    const handleClickLogout = () => {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.clear();
        window.location.href = "/login";
    };

    // Function to get the full URL of the profile image
    const getProfileImageUrl = (path) => {
        console.log(path);
        console.log(apiEndPoint)
        const baseUrl = apiEndPoint; // Access environment variable
        const staticFolder = "";//process.env.REACT_APP_STATIC_FOLDER || ''; // Optional: If you have a static folder
        const fallbackImage = "images/profile-picture.jpg"; // Fallback image path

        // Return the full image URL if path is available, else return fallback
        return path ? `${baseUrl}${staticFolder}/${path}` : fallbackImage;
    };

    //###########################
    //const loggedUserId = sessionStorage.getItem('userIdval');
    const accessToken = sessionStorage.getItem('accessToken');

     if (!loggedUserId || !accessToken) {
        return null;
     }

     // Decode the access token
     const decodedToken = jwtDecode(accessToken);

     // Check if the token is expired
     const currentTime = Math.floor(Date.now() / 1000);
     if (decodedToken.exp < currentTime) {
        return null;
     }
      //########################################
    return (
        <>

           {/* <header>*/}
                <div class="main-nav-container">
                    <div class="container">
                        <nav class="navbar navbar-expand-lg ms-auto">
                            <div class="container-fluid px-0">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                {loggedUserRole === 'Employee' ?
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav topnav">
                                        <li class={`nav-item ${window.location.pathname == '/dashboard' ? 'active' : '' }`}>
                                            <a class="nav-link" aria-current="page" href="/dashboard">Dashboard</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/timesheet' ? 'active' : '' }`}>
                                            <a class="nav-link " href="/timesheet">Time Sheet</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/projects' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/projects">Projects</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/reports' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/reports">Reports</a>
                                        </li>
                                        {/* <li class={`nav-item dropdown ${window.location.pathname == '/reports' ? 'active' : '' }`}>
                                            <a class="nav-link dropdown-toggle" href="/reports" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Reports
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="/reports">Weekly Reports</a></li>
                                                <li><a class="dropdown-item" href="/reports">Monthly Reports</a></li>
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>

                                : (loggedUserRole === 'Manager') ?
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav topnav">
                                        <li class={`nav-item ${window.location.pathname == '/manager-dashboard' ? 'active' : '' }`}>
                                            <a class="nav-link " aria-current="page" href="/manager-dashboard">Dashboard</a>
                                        </li>
                                        <li class={`nav-item dropdown ${(window.location.pathname == '/timesheet' || window.location.pathname == '/timesheet-management') ? 'active' : '' }`}>
                                            <a class="nav-link dropdown-toggle" href="/timesheet" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Time Sheet
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="/timesheet">My Time Sheet</a></li>
                                                <li><a class="dropdown-item" href="/timesheet-management">Time Sheet management</a></li>

                                            </ul>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/project-management' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/project-management">Project Management </a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/user-management' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/user-management">User Management </a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/detailed-reports' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/detailed-reports">Reports</a>
                                        </li>
                                    </ul>
                                </div>
                                :(loggedUserRole === 'Admin') ?
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav topnav">
                                        <li class={`nav-item ${window.location.pathname == '/admin-dashboard' ? 'active' : '' }`}>
                                            <a class="nav-link" aria-current="page" href="/admin-dashboard">Dashboard</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/timesheet-management' ? 'active' : '' }`}>
                                            <a class="nav-link" aria-current="page" href="/timesheet-management">Time Sheet</a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/project-management' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/project-management">Project Management </a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/user-management' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/user-management">User Management </a>
                                        </li>
                                        <li class={`nav-item ${window.location.pathname == '/detailed-reports' ? 'active' : '' }`}>
                                            <a class="nav-link" href="/detailed-reports">Reports</a>
                                        </li>
                                    </ul>
                                </div>

                                : ''
                                }

                                <div class="ms-auto">
                                    <ul class="navbar-nav topnav">
                                        <li class="nav-item position-relative d-flex align-items-center" style={{minWidth:"45px"}}>
                                            <div class="count-notofication">{notificationLength}</div>
                                            <a href="/notifications" >
                                                <img src="images/bell.png" alt="User Picture" />
                                            </a>
                                            {/* <a class="nav-link " aria-current="page" href="#" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="images/bell.png" alt="User Picture" />
                                            </a> */}
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            {/* <div className="notofication-wrap">
                                                <li> <a href="#">
                                                    <div className="notify-item">

                                                        <div className="notify-infotitle">
                                                            <b>your time sheet has been approved for the period from 2024-09-09 to 2024-09-15. </b>

                                                        </div>
                                                        <a href="#"> <div className="img-notify"> <img src="images/close.svg" width={18} height={18} alt="" /> </div></a>
                                                    </div>
                                                </a></li>
                                                <li> <a href="#">
                                                    <div className="notify-item">

                                                        <div className="notify-infotitle">
                                                            <b>your time sheet has been approved for the period from 2024-09-09 to 2024-09-15. </b>

                                                        </div>
                                                        <a href="#"> <div className="img-notify"> <img src="images/close.svg" width={18} height={18} alt="" /> </div></a>
                                                    </div>
                                                </a></li>
                                            </div> */}

<div className="notofication-wrap">
                                                {notifications.length > 0 ? (
                                                    notifications.map(notification => (
                                                        <li key={notification.notification_id}>
                                                            <a href="#">
                                                                <div className="notify-item">
                                                                    <div className="notify-infotitle">
                                                                        <b>{notification.action_description_show}</b>
                                                                    </div>
                                                                    <a href="#" onClick={() => handleNotificationClose(notification.notification_id)}>
                                                                        <div className="img-notify">
                                                                            <img src="images/close.svg" width={18} height={18} alt="Close" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No new notifications</li>
                                                )}
                                            </div>


                                        </ul>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {/*  */}
                                                <div class="user"><img src={loggedUserAvatar ? loggedUserAvatar : 'images/profile-picture.jpg'} alt="" /></div>
                                                <span>{userFullName}</span>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="/profile">My Profile</a></li>
                                                <li><a class="dropdown-item" onClick={handleClickLogout}>Logout</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>




                            </div>
                        </nav>
                    </div>
                </div>
           {/* </header> */}

        </>

    );
};

export default PublicHeader;