import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";

const previouNextDisableStyle = {
    marginTop: '0px',
    marginLeft: '10px',
    marginRight: '10px',
    pointerEevents: 'none',
    cursor: 'not-allowed'
}

const firstLastDisableStyle = {
    marginTop: '0px',
    pointerEevents: 'none',
    cursor: 'not-pointer'
}

const prevNextEnableStyle = { marginTop: '0px', marginLeft: '10px', marginRight: '10px', cursor: 'pointer' }
const firstLastStyle = { marginTop: '0px', cursor: 'pointer' }

const PaginationComponent = ({
    total,
    itemsPerPage,
    currentPage,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);

    const paginationItems = useMemo(() => {
        const pages = [];

        currentPage = currentPage;
        itemsPerPage = itemsPerPage;
        var startPage, endPage;
//console.log('currentpage  '+(currentPage+1));
//console.log('totalPages  '+totalPages);
//console.log('total '+total);
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (Number(currentPage) + Number(2) >= totalPages) {
                startPage = Number(totalPages) - Number(4);
                endPage = totalPages;
            } else {
                startPage = Number(currentPage) - Number(1);
                endPage = Number(currentPage) + Number(3);
            }

        }

       var startIndex = ((currentPage - 1) * itemsPerPage)==0 ? 1 : (currentPage - 1) * itemsPerPage;
        var endIndex = Math.min(startIndex + itemsPerPage - 1, total - 1);
//console.log(Number(currentPage)+Number(2)+'........'+totalPages+'.......'+startIndex+'.......'+endPage);
       // var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

           // console.log('startIndex '+startIndex);
          //  console.log('endIndex '+endIndex);
          //  console.log('startPage '+startPage);
          //  console.log('endPage '+endPage);
            for (let i = startPage; i <= endPage; i++) {
                pages.push(
                    <Pagination.Item
                        key={i}
                        active={i == currentPage}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </Pagination.Item>
                );
            }


        return pages;
    }, [totalPages, currentPage]);

    if (totalPages === 0) return null;

    return (



        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
                <Pagination className="align-items-center gap-1">
                {currentPage <= 1 ?
                    <a style={firstLastDisableStyle}>{'«'}</a> :
                    <a style={firstLastStyle} onClick={() => onPageChange(1)}>{'«'}</a>
                }

                    {/* <Pagination.Prev
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    /> */}


                    {currentPage <= 1 ?
                        <a style={previouNextDisableStyle}>{'Prev'}</a> :
                        <a style={prevNextEnableStyle} onClick={() => onPageChange(parseInt(currentPage) - parseInt(1))}>{'Prev'}</a>}


                    {paginationItems}

                    {/* <Pagination.Next
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    /> */}


                    {currentPage >= totalPages ?
                        <a style={previouNextDisableStyle}>{'Next'}</a> :
                        <a style={prevNextEnableStyle} onClick={() => onPageChange(parseInt(currentPage) + parseInt(1))}
                        >{'Next'}</a>
                    }
                    {currentPage >= totalPages ?
                        <a style={firstLastDisableStyle}>{'»'}</a> :
                        <a style={firstLastStyle} onClick={() => onPageChange(totalPages)}>{'»'}</a>
                    }


                </Pagination>


            </ul>
        </nav>

    );
};

export default PaginationComponent;