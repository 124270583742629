
const router = (function () {
  // reference to the singleton
  var instance;

  function init() {
    // private
    const routes = {
      home: {
        path: '/home'
      },
      
      timesheet: {
        path: '/timesheet'
      },

      dashboard: {
        path: '/dashboard'
      }
      

      
    };

    function getRouteInfo(routeName) {
      //alert(routeName);
      return routes[routeName];
    }

    function getRoutesInfo(routeNames) {
      const routesInfo = {};

      routeNames.forEach(route => {
        routesInfo[route] = routes[route];
      });


      return routesInfo;
    }

    function getLinksInfo(routeNames) {
      const links = {};

      routeNames.forEach(route => {
        links[route] = routes[route];
        links[route].pathName = links[route].path;
      });

      return links;
    }



    return {
      getRouteInfo,
      getRoutesInfo,
      getLinksInfo
    };
  }

  return {
    // get instance if one exists
    // or create one it it doesn't

    getInstance: function () {
      if (!instance) {
        instance = init();
      }

      return instance;
    },
  };
})();

export default router;
