import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
//import { format, addDays, subDays } from 'date-fns';
import { format, addDays, subDays, startOfWeek, endOfWeek } from 'date-fns';
import services from '../../../services/services';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactECharts from 'echarts-for-react';
const UserDashBoardDateWeekNavigator = () => {
  // Initialize with the current date in UTC
  const [currentDate, setCurrentDate] = useState(new Date(new Date().toISOString().split('T')[0]));
  const [barChartOptions, setBarChartOptions] = useState(null);
  const [weekStartDatePost, setWeekStartDatePost] = useState(null);
  const [weekEndDatePost, setWeekEndDatePost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resourceProject, setResourceProject] = useState("");
  const [projectWiseStatsData, setProjectWiseStatsData] = useState([]);
  const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));

  const handleClickNavigate = () => {
    //window.location.href = '/timesheet';
    window.location.href = '/timesheet?req='+format(weekStart, 'yyyy-MM-dd')+'&endreq='+format(weekEnd, 'yyyy-MM-dd')+'&view=week';
  }

  var weekStart = startOfWeek(currentDate, { weekStartsOn: 1 }); // Assuming Monday as the first day of the week
  var weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 });

  const handlePreviousWeek = () => {
    setCurrentDate((prevDate) => new Date(subDays(prevDate, 7).toISOString().split('T')[0]));
    //handleFetchData();
  };

  const handleNextWeek = () => {
    setCurrentDate((prevDate) => new Date(addDays(prevDate, 7).toISOString().split('T')[0]));
    //handleFetchData();
  };

  // Calculate the start and end of the week based on the current date
  //const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 }); // Assuming Monday as the first day of the week
  //const weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 }); // Assuming Sunday as the last day of the week


  // Utility function to convert "HH:MM" to minutes
  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };
  // Helper function to convert "HH:MM" format to minutes
  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  //setWeekStartDatePost(weekStart);
  //setWeekEndDatePost(weekEnd);
/*
  useEffect(() => {
    handleFetchData();
  }, []);
  */

  /*
  useEffect(() => {
    // Calculate the start and end of the week based on the current date
   weekStart = startOfWeek(currentDate, { weekStartsOn: 1 }); // Assuming Monday as the first day of the week
   weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 }); // Assuming Sunday as the last day of the week

    //setWeekStartDatePost(weekStart);
    //setWeekEndDatePost(weekEnd);

    setWeekStartDatePost(format(weekStart, 'yyyy-MM-dd'));
    setWeekEndDatePost(format(weekEnd, 'yyyy-MM-dd'));
    if (weekStart  && weekEnd ) {
      //handleFetchData();
    }

    //handleFetchData();
  }, [weekStart, weekEnd]);

  */


  useEffect(() => {
    const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 });
    setWeekStartDatePost(format(weekStart, 'yyyy-MM-dd'));
    setWeekEndDatePost(format(weekEnd, 'yyyy-MM-dd'));
  }, [currentDate]);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const requestJson4 = {
        weekStartDate: weekStartDatePost,
        weekEndDate: weekEndDatePost,
        isBillable: "",
        projectIds: resourceProject ? [resourceProject] : [],
        userId: useridval,
        //companyId: "2"
      };

      try {
       
        //const result4 = await services.fetchProjectWiseStatsUser(requestJson4);
        const result5 =   await services.fetchProjectWiseStatsUser(requestJson4);
        const result4 = result5.projects;

        if (result4 && Array.isArray(result4) && result4.length > 0) {
         
          const projectNames = result4.map(item => item.projectName);
          const billableHours = result4.map(item => convertTimeToMinutes(item.projectWiseBillableHours));
          const nonBillableHours = result4.map(item => convertTimeToMinutes(item.projectWiseNonBillableHours));

          const barChartOptions = {
            tooltip: {
              trigger: 'axis',
              axisPointer: { type: 'shadow' },
              formatter: (params) => {
                let content = `<strong>${params[0].axisValue}</strong><br/>`;
                params.forEach(item => {
                  const hours = Math.floor(item.value / 60);
                  const minutes = item.value % 60;
                  content += `${item.marker} ${item.seriesName}: ${hours}h ${minutes}m<br/>`;
                });
                return content;
              }
            },
            legend: { data: ['Billable Hours', 'Non-Billable Hours'] },
            xAxis: { type: 'category', data: projectNames },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: value => {
                  const hours = Math.floor(value / 60);
                  const minutes = value % 60;
                  return `${hours}h ${minutes}m`;
                }
              },
              splitLine: {
                lineStyle: {
                  width: 1,
                  color: '#ccc'
                }
              }
            },
            series: [
              { name: 'Billable Hours', type: 'bar', stack: 'total', data: billableHours, itemStyle: { color: '#00449d' } },
              { name: 'Non-Billable Hours', type: 'bar', stack: 'total', data: nonBillableHours, itemStyle: { color: '#3dacc8' } }
            ],
            grid: { left: '4%', right: '4%', bottom: '2%', containLabel: true }
          };

          setBarChartOptions(barChartOptions);
        } else {
          setBarChartOptions(null);
          console.error('Invalid data structure:', result4);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (weekStartDatePost && weekEndDatePost) {
      fetchData();
    }
  }, [weekStartDatePost, weekEndDatePost, resourceProject]);


  const handleFetchData2 = async () => {
    setLoading(true);
    //alert(resourceProject);
    // Create the request JSON object

    if (resourceProject != "") {
      var projectids_val = [resourceProject];
    } else {
      var projectids_val = [];
    }



    const requestJson4 = {
      weekStartDate: weekStartDatePost,
      weekEndDate: weekEndDatePost,
      //weekEndDate: "2024-08-31",
      //weekStartDate: "2024-08-01",
      isBillable: "",
      projectIds: [],
      userId: "3",
      companyId: "2"
    };

    try {
      var result4 = await services.fetchProjectWiseStatsUser(requestJson4); // Calling the static method
      //setProjectWiseStatsData(result4);
      //var result4 = result3.projects;


      console.log("@@@@@@@@@@@@@214");
      console.log(JSON.stringify(result4));
      //alert(result4.length);
      //################################################################
      // Log the full response to verify its structure
      console.log('Full response:', result4);

      // Check if result4 is already an array

      if (result4 && Array.isArray(result4) && result4.length > 0) {
        //alert('Data is an array');

        // Processing the data for charts
        const xAxisData = result4.map((item) => item.projectName);
        const yAxisData = result4.map((item) => convertToMinutes(item.totalHours));

        // const xAxisData = result4.map((item) => item.projectName);
        const billableHoursData = result4.map((item) => {
          const [hours, minutes] = item.billableHours.split(':').map(Number);
          return hours * 60 + minutes; // Convert billable hours to minutes
        });
        const nonBillableHoursData = result4.map((item) => {
          const [hours, minutes] = item.nonBillableHours.split(':').map(Number);
          return hours * 60 + minutes; // Convert non-billable hours to minutes
        });


        const projectNames = result4.map(item => item.projectName);
        const billableHours = result4.map(item => convertTimeToMinutes(item.billableHours));
        const nonBillableHours = result4.map(item => convertTimeToMinutes(item.nonBillableHours));


        console.log("billableHoursData")
        console.log(billableHoursData);
        console.log("nonBillableHoursData")
        console.log(nonBillableHoursData)
        console.log("yAxisData")
        console.log(yAxisData)
        const pieChartData = result4.map((item) => ({
          name: item.projectName,
          value: convertToMinutes(item.totalHours),
          //value: item.totalHours,
        }));

        // Bar Chart Options



        const barChartOptions = {
          title: {
            text: '',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: (params) => {
              let content = `<strong>${params[0].axisValue}</strong><br/>`;
              params.forEach(item => {
                const hours = Math.floor(item.value / 60);
                const minutes = item.value % 60;
                content += `${item.marker} ${item.seriesName}: ${hours}h ${minutes}m<br/>`;
              });
              return content;
            }
          },
          legend: {
            data: ['Billable Hours', 'Non-Billable Hours']
          },
          xAxis: {
            type: 'category',
            data: projectNames,
          },
          yAxis: {
            type: 'value',
            //name: 'Total Hours (in minutes)',
            name: '',
            axisLabel: {
              formatter: (value) => {
                const hours = Math.floor(value / 60);
                const minutes = value % 60;
                return `${hours}h ${minutes}m`;
              }
            },
            splitLine: {
              lineStyle: {
                width: 1, // Adjust the thickness of the horizontal lines
                color: '#ccc' // Optionally change the color of the lines
              }
            }
          },
          series: [
            {
              name: 'Billable Hours',
              type: 'bar',
              stack: 'total',
              data: billableHours,
              itemStyle: {
                color: '#00449d' // Color for billable hours
              }
            },
            {
              name: 'Non-Billable Hours',
              type: 'bar',
              stack: 'total',
              data: nonBillableHours,
              itemStyle: {
                color: '#3dacc8' // Color for non-billable hours
              }
            }
          ],
          // Optional: adjust grid layout if necessary
          grid: {
            left: '4%',
            right: '4%',
            bottom: '2%',
            containLabel: true
          }
        };


        setBarChartOptions(barChartOptions);
      } else {
        setBarChartOptions(null);
        console.error('Invalid data structure:', result4);
      }

      //#######################################################

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }



  };

  return (

    <div class="db-wrap">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex justify-content-start">
          <div class="prev-after-wrap">
            <a onClick={handlePreviousWeek}> <span> <img src="images/left.png" alt="" /></span></a>
            <a onClick={handleNextWeek}><span> <img src="images/right.png" alt="" /></span></a>
          </div>
          <div class="d-grid">
            <h2>{`${format(weekStart, 'd MMM')} - ${format(weekEnd, 'd MMM, yyyy')}`}</h2>

          </div>
        </div>
        <strong>Week</strong>
      </div>


      {/* <img src="images/addition/addweekimg.png" width="100%" alt="" /> */}

      <div class="db-wrap">
        {barChartOptions && <ReactECharts option={barChartOptions} style={{ height: 400, width: '100%' }} />}
      </div>

      <div class="btn-group mt-3 ">
        <a  onClick={() => handleClickNavigate()} class="btn btn-primary ">Add Week Entry</a>
      </div>

    </div>





  );
};

export default UserDashBoardDateWeekNavigator;
